<template>
  <section id="loginForm" class="page-user-login container is-fluid">
      <h1 class="subtitle is-3">Login
        <span v-if="errorMessage" class="notification error is-light">
          {{ errorMessage }}
        </span>
      </h1>
     <p>Do you have an account? If not then please <router-link to="/register"><strong>Register</strong></router-link></p>

      <div v-if="!loginFields || 'email' in loginFields" class="field column">
        <label class="label" :class="classes('email')">
          Email
        </label>
        <div class="control">
          <input class="input is-info" type="text"
                 placeholder="Email"
                 name="email"
                 :class="errors && errors['email']?'error':undefined"
                 :value="loginFields.email"
                 @input="$emit('field-update', 'email', $event.target.value)"
                 @focus="clearError"
          />
        </div>
      </div>

      <div v-if="!loginFields || 'phone' in loginFields" class="field column">
        <label class="label" :class="classes('phone')">
          Phone
        </label>
        <div class="control">
          <input class="input is-info" type="text"
                 placeholder="Phone"
                 name="phone"
                 :class="errors && errors['phone']?'error':undefined"
                 :value="loginFields.phone"
                 @input="$emit('field-update', 'phone', $event.target.value)"
                 @focus="clearError"
          />
        </div>
      </div>

      <div v-if="!loginFields || 'password' in loginFields" class="field column">
        <label class="label" :class="classes('password')">
          Password
        </label>
        <div class="control">
          <input class="input is-info" type="password" required="required"
                 placeholder="Password"
                 name="password"
                 :class="errors && errors['password']?'error':undefined"
                 :value="loginFields.password"
                 @input="$emit('field-update', 'password', $event.target.value)"
                 @focus="clearError"
          />
        </div>
      </div>

     <nav-footer
        :nav-routing="this.navRouting"
        @handle-form-submission="handleFormSubmission"
     >
     </nav-footer>
    </section>
</template>

<script>
import LoginService from '../services/loginService';
import NavFooter from "@/components/NavFooter";

export default {
  name: 'UserLogin',
  components: { NavFooter },
  data: function() {
    return {
      errors: { type: Array, default: null },
      errorMessage: "",
    }
  },
  props: {
    loginFields: {
      type: Object,
      default: () => ({email:null, password:null} )
    },
    navRouting: Object,
    from: { type: String, default: "/menu/list" }
  },
  mounted() {
    console.info('loginFields: ' + JSON.stringify(this.loginFields));
  },
  methods: {
    handleFormSubmission: function() {
      const self = this;
      let svc = new LoginService();
      let data = new FormData();
      if (self.loginFields.email)
        data.append('login', self.loginFields.email);
      else
        data.append('login', self.loginFields.phone);
      data.append('password', self.loginFields.password);
      const func = function() {
        svc.loginVerify(this, data, function(responseObj) {
          const response = JSON.parse(responseObj);
          if (response.ok) {
            // from this event router pushes new path (/order)
            self.$emit('user-login', response.data, svc.header('authorization'), self.from);
          }
          else { // Error
            // console.error('error:' + JSON.stringify(response));
            switch (response.errorCode)
            {
              case 'INVALID_CREDENTIALS':
              default:
                self.errorMessage = 'login or password incorrect';
            }
            //self.$emit('form-error', self.email);
          }
        });
      };
      func.call(this);
    },
    classes(fld) {
      let classes = "";
      if (this.errors && this.errors[fld])
        classes = this.errors[fld];
      if ('required' in this.loginFields && this.loginFields.required[fld])
        classes += classes?' required':'required';
      return classes;
    },

    clearError() {
      this.errorMessage = null;
    }
  }
}
</script>