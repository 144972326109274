// Service: LoginService
import BaseService from "./baseService";
import AjaxUtils from "../utils/ajaxUtils";

export default class LoginService extends BaseService {

	constructor(headers) {
		super(headers);
	}

	loginVerify(that, formData, callback) {
		this.submitForm('POST', '/login', callback, formData);
	}

	// logout(auth, callback) {
	// 	this.submitAuth('POST', '/logout', auth, callback);
	// }

	logout(that, callback) {
		const filterFunc = (xhrResponseText, xhrStatus, xhrStatusText) => AjaxUtils.ajaxResponseToObj(that, xhrResponseText, xhrStatus, xhrStatusText, callback);
		this.server.request('POST', '/logout', filterFunc);
		this.server.withCredentials();
		this.server.send();
	}
}