// Utils: FormUtils

export default class FormUtils {

	static isEmpty(obj) {
		return Object.keys(obj).length === 0;
	}

	static propertiesWithValues(obj) {
		let valuedProperties = {};
		for (let key in Object.keys(obj)) {
			if (!!obj[key]) valuedProperties[key] = obj[key];
		}
		return valuedProperties;
	}

	// Convert form fields object to FormData
	// Sub-objects (including arrays) will be converted to JSON
	// Additional arguments are an array of keys not to stringify and an object mapping of renames
	// Booleans are always converted to JSON
	static objToFormFields(obj, doNotStringifyList = [], renameList = {}) {
		let data = new FormData();
		FormUtils.objToFormFields_Process_Inner(data, obj, doNotStringifyList, renameList) ;
		return data ;
	}

	static objToFormFields_Process_Inner(data, obj, doNotStringifyList, renameList) {
		for (let key in obj) {
			if(Object.prototype.hasOwnProperty.call(obj, key)) {
				if ((typeof obj[key]) === 'string') {
					if (renameList[key] === undefined) data.append(key, obj[key]);
					else data.append(renameList[key], obj[key])
				}
				else if ((typeof obj[key]) === 'object' || typeof obj[key] == 'boolean') {
					if (!doNotStringifyList.includes(key) || typeof obj[key] == 'boolean') {
						if (renameList[key] === undefined) data.append(key, JSON.stringify(obj[key]));
						else data.append(renameList[key], JSON.stringify(obj[key]))
					}
					else FormUtils.objToFormFields(data, obj[key], doNotStringifyList, renameList) ;
				}
				else
					// handle integer case
					data.append(key, obj[key]);
			}
		}
	}

	// Rename a FormData field if present
	static renameFormDataField(formData, current, renamed) {
		if (formData.get(current) !== null) {
			formData.set(renamed, formData.get(current)) ;
			formData.delete(current) ;
		}
	}
}