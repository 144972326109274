<script>

const selectionAttrMixin = {
  methods: {
    prepareAttributes(meta, ga, item) {
      // check if this item has already processed attributes
      // if so, just return, don't want to double up!
      if ('ap' in item && item.ap)
        return false;

      let null_attr = {};
      // process item attributes, and harvest any "veto"
      // attributes to cull group attributes with
      if (item.hasOwnProperty('ia') && Object(item.ia).length > 0) {
          console.info('Item.ia:' + JSON.stringify(item.ia));

          for (let attr_id in item.ia) {
            // attr_id_list is an array
            const attr_id_list = item.ia[attr_id];
            for (let n = 0; n < attr_id_list.length; n++) {
                const IA = attr_id_list[n];
                console.log('Item attr:' + IA.id + ' attr:' + IA.a + ' desc:' + IA.d);
                // check for "veto" type - nullifies any group attr of the same attr_id
                if (IA.t === 'v')
                    null_attr[IA.a] = true;
            }
        }
      }

       // merge IA and GA (if there is any IA)
       // IA takes precedence over GA.
      if ('ga' in item && item.ga) {
        for (let n = 0; n < item.ga.length; n++) {
          const gattr = item.ga[n];
          let comment = "";
          console.info('GATTR:' + JSON.stringify(gattr));

          // attr not nullified, and has not been added
          if (gattr.a in null_attr)
            comment = ' NULLIFIED!';
          else
          {
            if ('ia' in item && item.ia && Object.keys(item.ia).length > 0) {
               console.info('Copy the GA into IA here!');
               if (gattr.a in item.ia)
                 item.ia[gattr.a].push(gattr);
               else {
                 item.ia = {};
                 item.ia[gattr.a] = [ gattr ];
               }
            }
            else {
              item.ia = {};
              item.ia[gattr.a] = [ gattr ];
            }

          }
          console.info('GA:' + gattr.id + ' group:' + gattr.g + ' attr:' +
              gattr.a + ' desc:' + gattr.d + comment);
          }
      }

      // handle "multiple" attribute (add a small object per attribute)
      // ia is an array of objects
      let m = {};
      let e = {};
      if ('ia' in item && !'ea' in item) {
          for (let attr_id in item.ia) {
              const attrArray = item.ia[attr_id]; // is an array
              console.log('AT:' + JSON.stringify(attrArray));
              for (let j = 0; j < attrArray.length; j++)
              {
                const attr = attrArray[j];
                console.log('ATD:' + attr.d);
                if (attr.t === 'm') {
                  if (attr.a in m)
                    m[attr.a].n += 1;
                  else {
                    m[attr.a] = [];
                    m[attr.a].n = 1;
                  }
                  m[attr.a].push({'i': attr.id, 'c': attr.c, 'd': attr.d});
                }
                else if (attr.t === 'e')
                {
                  if (! (attr.a in e))
                    e[attr.a] = [];
                  e[attr.a].push(attr);
                }
              }
          }
          item.ma = Object.assign({}, m);
          item.ea = Object.assign({}, e);
      }
      // neater data using integer rather than bool
      item.ap = 1;   // processed attributes flag
      item.na = 1;   // no attributes used flag
      if (typeof(item.ia) !== "undefined") {
        console.log('item MA:' + JSON.stringify(item.ma));
        console.log('item EA:' + JSON.stringify(item.ea));
        console.log('item IA:' + JSON.stringify(item.ia));
        item.na = 0;
      }
      else {
        console.info('item has no attributes!');
        // neater data using integer rather than bool
        item.ia = 0;
      }
    },
  }
}

export default selectionAttrMixin;
</script>