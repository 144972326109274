<template>
   <form class="form profile" :id="formId" autocomplete="on" @submit.prevent="submit">
      <section class="columns">
         <div v-if="!userProfile && (fieldsUsed && 'forename' in fieldsUsed)" class="field column">
            <label class="label" :class="classes('forename')">
               Forename
               <span v-if="errors && errors.hasOwnProperty('forename')" class="msg error">
                    {{ this.errors['forename'] }}
          </span>
            </label>
            <div class="control">
               <input v-if="fieldsRequired && 'forename' in fieldsRequired" class="input" type="text"
                      placeholder="Forename"
                      :value="user.forename"
                      :class="errors && errors['forename']?'error':undefined"
                      :required="fieldsRequired.forename"
                      @input="$emit('update:user', 'forename', $event.target.value)"
               />
               <input v-else class="input" type="text"
                      placeholder="Forename"
                      :value="user.forename"
                      :class="errors && errors['forename']?'error':undefined"
                      @input="$emit('update:user', 'forename', $event.target.value)"
               />

            </div>
         </div>

         <div v-if="!userProfile && (fieldsUsed && 'surname' in fieldsUsed)" class="field column">
            <label class="label" :class="classes('surname')">
               Surname
               <span v-if="errors && errors.hasOwnProperty('surname')" class="msg error">
                    {{ this.errors['surname'] }}
          </span>
            </label>
            <div class="control">
               <input v-if="fieldsRequired && 'surname' in fieldsRequired" class="input" type="text"
                      placeholder="Surname"
                      :value="user.surname"
                      :class="errors && errors['surname']?'error':''"
                      :required="fieldsRequired.surname"
                      @input="$emit('update:user','surname', $event.target.value)"
               />
               <input v-else class="input" type="text"
                      placeholder="Surname"
                      :value="user.surname"
                      :class="errors && errors['surname']?'error':''"
                      @input="$emit('update:user','surname', $event.target.value)"
               />
            </div>
         </div>

         <div v-if="!userProfile && (fieldsUsed && 'custname' in fieldsUsed)" class="field column">
            <label class="label" :class="classes('custname')">
               Name
               <span v-if="errors && errors.hasOwnProperty('custname')" class="msg error">
                    {{ this.errors['custname'] }}
          </span>
            </label>
            <div class="control">
               <input v-if="fieldsRequired && 'custname' in fieldsRequired" class="input" type="text"
                      placeholder="Name"
                      :value="user.custname"
                      name="custname"
                      autocomplete="shipping name"
                      :class="errors && errors['custname']?'error':undefined"
                      :required="fieldsRequired.name"
                      @input="$emit('update:user', 'custname', $event.target.value)"
               />
               <input v-else class="input" type="text"
                      placeholder="Name"
                      :value="user.custname"
                      name="custname"
                      autocomplete="shipping name"
                      :class="errors && errors['custname']?'error':undefined"
                      @input="$emit('update:user', 'custname', $event.target.value)"
               />

            </div>
         </div>
      </section>

      <section class="columns">
         <div v-if="!userProfile && (fieldsUsed && 'email' in fieldsUsed)" class="field column">
            <label class="label" :class="classes('email')">
               Email
               <span v-if="errors && errors.hasOwnProperty('email')" class="msg error">
                    {{ this.errors['email'] }}
            </span>
            </label>
            <div class="control">
               <input class="input" type="email"
                      placeholder="Email"
                      name="custemail"
                      autocomplete="shipping email"
                      :class="errors && errors['email']?'error':undefined"
                      :value="user.email"
                      @input="$emit('update:user','email', $event.target.value)"
                      @blur="$emit('check:email', $event.target.value)"
               />
            </div>
         </div>

         <div v-if="fieldsUsed && 'phone' in fieldsUsed" class="field column">
            <label class="label" :class="classes('phone')">
               Phone
               <span v-if="errors && errors.hasOwnProperty('phone')" class="msg error">
                    {{ this.errors['phone'] }}
            </span>
            </label>
            <div class="control">
               <input class="input" type="text"
                      placeholder="Password"
                      name="custtel"
                      autocomplete="shipping tel"
                      :class="errors && errors['phone']?'error':undefined"
                      :value="user.phone"
                      @input="$emit('update:user','phone', $event.target.value)"
               />
            </div>
         </div>
      </section>

      <section class="columns">
         <div v-if="fieldsUsed && 'password' in fieldsUsed" class="field column">
            <label class="label" :class="classes('password')">
               Password
               <span v-if="errors && errors.hasOwnProperty('password')" class="msg error">
                    {{ this.errors['password'] }}
            </span>
            </label>
            <div class="control">
               <input class="input" type="password"
                      placeholder="Password"
                      autocomplete="new-password"
                      :class="errors && errors['password']?'error':undefined"
                      :value="user.password"
                      @input="$emit('update:user','password', $event.target.value)"
                      @keydown="$emit('clear-error', 'password')"
               />
            </div>
         </div>

         <div v-if="fieldsUsed && 'notes' in fieldsUsed" class="field column">
            <label class="label" :class="classes('notes')">
               Delivery instructions
               <span v-if="errors && errors.hasOwnProperty('notes')" class="msg error">
                    {{ this.errors['notes'] }}
            </span>
            </label>
            <div class="control">
               <input class="input" type="text"
                      placeholder="Instructions"
                      name="notes"
                      :class="errors && errors['notes']?'error':undefined"
                      :value="user.notes"
                      @input="$emit('update:user','notes', $event.target.value)"
               />
            </div>
         </div>
         <div v-else class="field column is-half">
         </div>
      </section>

      <div v-if="!fieldsUsed || 'save' in fieldsUsed" class="field my-5">
         <label class="checkbox">Save</label>
         <div class="control">
            <input class="checkbox" type="checkbox"
                   :value="user.save" v-model="checked"
                   @change="$emit('update:user','save', checked)"
            />
         </div>
      </div>

      <div v-if="fieldsUsed && 'address' in fieldsUsed"></div>
      <section v-if="userProfile && this.addressList && addressList.length" class="">
         <h1 class="subtitle">Addresses
            <span v-if="isAddressMissing" class="msg error">
            {{ this.errors['address'] }}
          </span>
         </h1>
         <div>
            <address-list :addressList=addressList
                          :resetSelection=addAddress
                          :selectedId=parseInt(user.address.adi)
                          @update:list="addressListUpdate"
            >
            </address-list>
            <section class="pr-section is-15" @click="anotherAddress">
               <button
                  class="mt-3 mb-6 button is-info centre-block"
                  :class="addAddressClass"
               >
                  {{ addAddressLabel }}
               </button>
            </section>
            <div class="is-1 is-mobile is-centered">
               <address-form v-if=another
                             :label="dropdownLabel"
                             :address="user.address"
                             :addrMeta="addrMeta"
                             :addressInputMode="addressInputMode"
                             @update:address="addressFormUpdate"
                             @update:postcode="addressLookup"
                             @update:addr1Changed="addr1Changed"
                             @reset="reset"
                             @manual="manual"
               >
               </address-form>
            </div>


         </div>
      </section>

      <section v-else class="address">
         <div class="field">
            <h1 class="subtitle is-4 mt-5 mb-0">Address
               <span v-if="isAddressMissing" class="msg error">
            {{ this.errors['address'] }}
          </span>
            </h1>

            <section>
               <address-list v-if=!addAddress
                             :addressList=addressList
                             :selectedId="user.address.adi"
                             :resetSelection=addAddress
                             @update:list="addressListUpdate"
               >
               </address-list>

               <section class="pr-section is-15" v-if=!addAddress @click="anotherAddress">
                  <button
                     class="mt-3 mb-6 button is-info pr-section"
                     :class="addAddressClass"
                  >
                     {{ addAddressLabel }}
                  </button>
               </section>

               <div class="is-1 is-mobile is-centered">
                  <address-form v-if=addAddress
                                :label="dropdownLabel"
                                :address="user.address"
                                :addrMeta="addrMeta"
                                :addressInputMode="addressInputMode"
                                @update:address="addressFormUpdate"
                                @update:postcode="addressLookup"
                                @update:addr1Changed="addr1Changed"
                                @reset="reset"
                                @manual="manual"
                  >
                  </address-form>
               </div>
            </section>
         </div>
      </section>
   </form>
</template>

<script>

import AddressList from "@/components/AddressList";
import AddressForm from "@/components/AddressForm";
import PostalAddress from "@/utils/postalAddress";
import AddressFinderService from "@/services/addressFinderService";

export default {
   name: "CustomerInfoForm",
   components: { 'address-list': AddressList, 'address-form': AddressForm },
   data: function() {
      return {
         checked: false,

         // address data
         addressLookupService: new AddressFinderService(),
         addressInputMode: 'F',
         another: false,  // another address
         dropdownLabel: "Select premises",
         addrMeta: {
            errors: {},
            idx: null,
            pd: null    // Premises Data - the postcode lookup dataset
         }
      }
   },
   props: {
      user: { type:Object, default: null },
      fieldsUsed: { type:Object, default: null },
      formId: { type: String, default: "" },
      fieldsRequired: { type:Object, default: null }, // mandatory fields
      addressList: { type:Array, default: null },
      errors: { type:Object, default: null },

      addAddress: { type:Boolean, default: false },
      userProfile:{ type:Boolean, default: false },
      infoComplete:{ type:Boolean, default: false }
   },

   emits : [
      'update:addressList',
      'update:user',
      'update:address-selected',
      'anotherAddress',
      'reset',
      'clear-error'
   ],
   created() {
   },
   beforeMount() {
   },
   computed: {
      addAddressLabel() {
         let name = 'Another address';
         if (!this.addressList || this.addressList.length < 1)
            name = 'Add an address';
         return name;
      },
      addAddressClass() {
         if (!this.addressList || this.addressList.length < 1)
            return null;
         return 'is-outlined';
      },
      isAddressMissing() {
         return this.errors && 'address' in this.errors && this.errors.address;
      }
   },
   methods: {
      addr1Changed(e) {
         if (e)
            this.addrMeta.idx = e.target.options.selectedIndex - 1;
         this.addrMeta.errors.premises = null;
         this.addressAddr1Selected(this.addrMeta);
      },
      // address form drop-down selection
      addressAddr1Selected(meta, addrRecord) {
         // either if a match has been found from house no. (aka 'n')
         // OR there is only one premises for this postcode.
         let n = meta.idx;
         let premises;
         this.addrMeta = meta;

         if (meta.pd.length === 1 || n < 0) {
            n = 0;
         }

         if (meta.hasOwnProperty('pd') && meta.pd.length > 0) {
            premises = meta.pd[n];
            if (addrRecord) {
               if (meta.idx >= 0)
                  addrRecord.house = premises.pd.n;

               addrRecord.ad1 = premises.ad1;
               addrRecord.pd = premises.pd;
            }
         }
         this.$emit('update:address-selected', addrRecord);
      },

      // addressForm, new address to search for
      addressFormUpdate(field, val) {
         this.$emit('update:address', field, val);
         if (field === 'postcode') {
            this.addrMeta.errors.postcode = false;
            if (this.addressInputMode !== 'M' && PostalAddress.validPostcode(val))
               this.addressLookup(val);
         }
      },
      // addressList radio has been clicked
      addressListUpdate(addr, idx)
      {
         console.log('ALS(1):' + idx + JSON.stringify(addr));
         // if premisesList member exists, extract address components from it
         if (addr && 'pd' in addr && addr.pd)
         {
            const pl = this.addr.pd;
            if ('o' in pl && pl.o)
               addr.org = pl.o;
            if ('p' in pl && pl.p)
               addr.prem = pl.p;
            if ('n' in pl && pl.n)
               addr.house = pl.n.toString();
         }
         this.$emit('update:addressList', addr, idx);
      },
      anotherAddress() {
         this.addressInputMode = 'F';
         this.another = true;
         this.$emit('anotherAddress');
      },
      submit() {
         if (!this.postcode || !this.house) {
            if (!this.postcode)
               this.addrMeta.errors.postcode = true;
            if (!this.house)
               this.addrMeta.errors.house = true;
            return false;
         }
         this.$emit('submit');
      },
      classes(fld) {
         let classes = "";
         if (this.errors && this.errors[fld])
            classes = this.errors[fld];
         if (this.requiredFields && this.requiredFields[fld])
            classes += classes?' required':'required';
         return classes;
      },

      // Address handling methods
      reset(keepHouseAndPostcode) {
         this.addressInputMode = 'F';
         this.another = false;
         if ('errors' in this.addrMeta) {
            this.addrMeta.errors.premises = this.addrMeta.errors.postcode = null ; // Clear find-address errors
         }
         this.$emit('reset', keepHouseAndPostcode);
      },
      manual(house, addr1) {
         this.addressInputMode = 'M';
         if ('errors' in this.addrMeta) {
            this.addrMeta.errors.premises = this.addrMeta.errors.postcode = null ; // Clear find-address errors
         }
         let addrLine1 = {};
         // here if there is a house specified, update house and addr1, as the
         // entered house should appear in addr1 for the manual address form.
         addrLine1.house = house;
         addrLine1.addr1 = house + ' ' + addr1;
         this.$emit('update:address-selected', addrLine1);
      },
      addressLookup(postcode) {
         let that = this;
         // Do address-lookup if not in manual mode and the postcode was changed to one that is valid
         if (this.user && this.addressInputMode !== 'M' && PostalAddress.validPostcode(postcode))
         {
            this.addressLookupService.request(postcode, this.user.address.house,
               function (addrRecord, meta) {
                  that.addrMeta = meta;

                  // if lookup fails, meta.pd will be undefined
                  if ('pd' in meta && meta.pd && meta.pd.length) {
                     // Set address values and switch to select
                     that.addressInputMode = 'S';
                     that.addressAddr1Selected(that.addrMeta, addrRecord);
                     console.warn('addrRecord:' + JSON.stringify(addrRecord));
                  }
                  else if (meta.idx < 0)
                     that.addressInputMode = 'S';
                  else
                     that.addressInputMode = 'F';

                  if (meta.errors.postcode) {
                     that.$emit('postcode-invalid');
                  }
               });
         }
      },

      remove(idx) {
         this.$emit('remove', idx);
      },
      setDefault(idx) {
         this.$emit('setDefault', idx);
      },

      report() {
         alert(
            'Address selected-:' + '\r\n\r\n' +
            'Org:' + this.org + '\r\n' +
            'Prem:' + this.prem + '\r\n' +
            'House:' + this.house + '\r\n' +
            'Addr1:' + this.addr1 + '\r\n' +
            'Addr2:' + this.addr2 + '\r\n' +
            'Town:' + this.town + '\r\n' +
            'Postcode:' + this.postcode
         );
      },
      custReport() {
         alert(
            '!Submit:' + '\r\n\r\n' +
            'Name:' + this.user.custname + '\r\n' +
            'Email:' + this.user.email + '\r\n' +
            'Phone:' + this.user.phone + '\r\n' +
            'Save:' + this.user.save + '\r\n' +
            'Org:' + this.user.address.org + '\r\n' +
            'Prem:' + this.user.address.prem + '\r\n' +
            'House:' + this.user.address.house + '\r\n' +
            'Addr1:' + this.user.address.addr1 + '\r\n' +
            'Addr2:' + this.user.address.addr2 + '\r\n' +
            'Town:' + this.user.address.town + '\r\n' +
            'Postcode:' + this.user.address.postcode
         );
      }
   }
}
</script>>