<template>
<div class="page-order-payment-result">

		<div class="page-container">
			<div v-if="confirmationData" class="card">
				<header class="card-header">
					<p class="card-header-title is-centered">
						Order Confirmed
					</p>
				</header>
				<div class="card-content">
					<div class="confirmation-text">Your order reference is #{{confirmationData.orderId}}</div>
					<div class="confirmation-text">A confirmation email has been sent to <strong>{{confirmationData.email}}</strong></div>
				</div>
			</div>

			<div v-if="paymentStatusMsg" class="card">
				<header class="card-header">
					<p class="card-header-title">
						Payment Status
					</p>
				</header>
				<div class="card-content">
					<div class="confirmation-text">{{paymentStatusMsg}}</div>
				</div>
			</div>
		</div>

	<div v-if="serverError !== null" class="has-text-danger is-size-4 has-text-weight-semibold">{{serverError}}</div>
</div>
</template>

<script>
/*jshint esversion: 9 */
import OrderingService from '@/services/orderingService' ;

export default {
	name: 'OrderPaymentResult',
	data: function() {
		return {
			mainStatus: undefined,
			svc: new OrderingService(), 
			confirmationData: undefined,
			serverError: null,
			paymentStatusMsg: null
		}
	},
	beforeMount() {
		const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret") ;
		this.checkStatus(clientSecret) ;
		if (!clientSecret) this.getConfirmationData() ; // Just display for the last successful order if no stripe client-secret (this is partly for testing purposes)
	},
	methods: {
		// Fetches the payment intent status after payment submission
		// Adapted from 'HTML' Frontend JavaScript @ https://stripe.com/docs/payments/quickstart
    // @TODO DS DS checkStatus was async but using async gives parsing error.
		async checkStatus(clientSecret) {
			if (!clientSecret) return ;
			const stripe = await this.$loadStripe(process.env.VUE_APP_STRIPE_KEY) ;
			const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

			switch (paymentIntent.status) {
				case "succeeded":
					this.paymentStatusMsg = null ;
					this.getConfirmationData() ;
					break;
				case "processing":
					this.paymentStatusMsg = "Your payment is processing..." ;
					break;
				case "requires_payment_method":
					this.paymentStatusMsg = "Your payment was not successful, please try again." ;
					break;
				default:
					this.paymentStatusMsg = "Something went wrong." ;
					break;
			}
		},
		getConfirmationData() {
			const func = function() {
				this.svc.getOrderConfirmationData(this, function(responseObj) {
					if (responseObj.ok) {
						this.confirmationData = responseObj.data ;
					}
					else { // Error
						if (responseObj.error_code === 'XHR_SEND_ERR' || responseObj.isServerError) {
							let errorType = (responseObj.error_code === 'XHR_SEND_ERR') ? 'NET_ERR' : 'SERVER_ERR' ;
							let error = (responseObj.error_code === 'XHR_SEND_ERR') ? '' : responseObj.error ;
							this.$emit('displayNetworkError', this, func, errorType, error) ;
						}
						else this.serverError = "Payment was successful but an error occurred: '" + responseObj.error + "'" ;
					}
				})
			}
			func.call(this) ;
		}
	}
}
</script>

<style scoped>
.card .card-header { background-color: #00d000; }
.card .card-header-title { color: white ; font-size:130% ; }
.confirmation-text { text-align: center; font-size: 120% ; }
.page-container { width: 1024px ; max-width: 100vw; margin: auto;}
</style>
