// Service: OpeningPageService
import BaseService from "./baseService";
import AjaxUtils from "../utils/ajaxUtils";

export default class OpeningPageService extends BaseService {

  constructor() {
		super();
    this.endpoint = '/vendor/offers';
  }

  getOfferData(that, vendorId, callback) {
        const filterFunc = (xhrResponseText, xhrStatus, xhrStatusText) => AjaxUtils.ajaxResponseToObj(that, xhrResponseText, xhrStatus, xhrStatusText, callback);
        this.server.request('GET', this.endpoint + '/' + vendorId, filterFunc);
        this.server.withCredentials();
        this.server.send();
  }
}
