<template>
   <div>
      <div class="control">
         <div v-for="(addr, index) in this.addressList" :key="index">

            <label v-if="addr.adi == selectedId" class="label radio" type="radio">
               <input @change="addressSelected(addr, index)" type="radio" name="user-address" checked>
               {{ addressString(addr) }}
            </label>
            <label v-else>
            <input @change="addressSelected(addr, index)" type="radio" name="user-address">
               {{ addressString(addr) }}
            </label>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "AddressList",
   props: {
      addressList:    { type: Array, default: null },
      selectedId:     { type: Number, default:0 },
      resetSelection: { type: Boolean, default: false }
   },
   watch: {
      resetSelection(reset) {
         if (reset) {
            console.info('address list reset');
            let buttons = document.getElementsByName('user-address');
            for (let n = 0; n < buttons.length; n++)
               buttons[n].checked = false;
         }
      }
   },
   created() {
      // if only one address, select it in case user does not bother
      // to select single address
      if (this.addressList && this.addressList.length === 1)
         this.$emit('update:list', 0);
   },
   methods: {
      addressSelected: function(addr, idx) {
         // console.log('ALS(0):' + idx + JSON.stringify(addr));
         this.$emit('update:list', addr, addr.adi);
      },

      addressString: function(addr) {
         let as = '';
         // console.info('addressString:' + JSON.stringify(addr));

         if ('premisesList' in addr && 'o' in addr.premisesList && addr.premisesList.o)
            as += addr.premisesList.o + ', ';
         else if ('org' in addr && addr.org)
            as += addr.org + ', ';
         if ('premisesList' in addr && 'p' in addr.premisesList && addr.premisesList.p)
            as += addr.premisesList.p + ', ';
         else if ('prem' in addr && addr.prem)
            as += addr.prem + ', ';
         if ('premisesList' in addr && 'n' in addr.premisesList && addr.premisesList.n)
            as += addr.premisesList.n + ' ' + addr.addr1;
         else if (addr.house)
            as += addr.house + ' ' + addr.addr1;
         else
            as += ' ' + addr.addr1;

         if (addr.addr2)
            as += ', ' + addr.addr2;
         if (addr.town)
            as += ', ' + addr.town;
         if (addr.postcode)
            as += ', ' + addr.postcode;
         return as;
      }
   }
}
</script>