 <template>
  <section class="order-basket">
    <h1 v-if="title" class="subtitle ml-2 is-3">{{ title }}</h1>

      <div class="list" v-if="this.selection.length && !this.item_grid">

        <section v-for="(item, idx) in this.selection"  :key="idx" class="basket-item">
          <article v-if="item.qty" class="ia">
            <h1 v-if=!from_summary class="subtitle">
              {{ item.name }}
            </h1>

            <div  v-for="(sic, index) in item.si" :key="index" class="si">
              <article v-if="parseInt(sic.q, 10) > 0">
                <img @click.stop="adjustItem(item, -1, sic)"
                     class="minus"
                     :src="require('@/assets/image/minus.svg')" alt="minus" />

                <div class="img-wrapper">
                  <img class="img" :src="image_base + item.image" :alt="sic.d" />
                </div>

                <div v-if="from_summary">
                   {{ item.name }}
                </div>

                <aside class="control">
                  <span class="info">{{ sic.q }}</span>
                  <span class="info qty">{{ formattedAmountPence(sic.c * sic.q) }}</span>
                </aside>

                <img  @click.stop="adjustItem(item, +1, sic)"
                      class="plus"
                      :src="require('@/assets/image/plus.svg')" alt="plus" />
              </article>
            </div>

          </article>

        </section>
<!--        <section>-->
<!--          {{ overallTotal() }}-->
<!--        </section>-->
      </div>

    <div v-else-if="this.selection.length && this.item_grid">
      <table class="is-striped">
        <tbody>
        <tr v-for="(item, idx) in this.selection" :key="idx" class="block">
          <td>
            <div class="control basket img-wrapper">
              <img :src="image_base + item.image" :alt="item.name" />
              <span class="info img-inset">{{ item.qty }}</span>
            </div>
          </td>
          <td>
            {{ item.name }}
          </td>
          <td>
            <button @click.stop="adjustItem(item, -1)" class="is-medium minus">
              <img :src="require('@/assets/image/minus.svg')" alt="minus" />
            </button>
          </td>
          <td>
            {{ itemTotal(item) }}
          </td>
          <td>
            <button @click.stop="adjustItem(item, +1)" class="is-medium plus">
              <img :src="require('@/assets/image/plus.svg')" alt="plus" />
            </button>
          </td>
        </tr>
        <tr class="totals">
          <td></td>
          <td>Total</td>
          <td></td>
          <td>{{ overallTotal() }}</td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>

    <div v-else-if="!this.total_qty" class="no-selection">
      <div class="block">
        <h1 class="subtitle is-2 centre-block">No items in basket</h1>
      </div>

      <div class="block">
        <router-link class="basket" :to="menuUrl()">
          <button class="button is-primary">
            <img :src="require('@/assets/image/menu.svg')" class="basket" alt="basket" />
            <span>Back to menu</span>
          </button>
        </router-link>
      </div>
    </div>

    <div v-if=!from_summary  class="flex stats">
<!--      <section v-if="this.total_qty" class="ml-2">-->
<!--        <div class="control core">-->
<!--          <router-link class="basket" :to="menuUrl()">-->
<!--            <button class="is-primary button">-->
<!--              <img :src="require('@/assets/image/menu.svg')" class="basket" alt="basket" />-->
<!--              Back to menu-->
<!--            </button>-->
<!--          </router-link>-->
<!--        </div>-->
<!--      </section>-->
    </div>

     <nav-footer
        :navRouting="navRouting"
     >
     </nav-footer>
  </section>
</template>
<script>

import currencyMixin from '@/mixins/Currency';
import selectionMixin from '@/mixins/Selection';
import NavFooter from "@/components/NavFooter";

export default {
   mixins: [currencyMixin, selectionMixin],
   components: {NavFooter},
   name: 'OrderBasket',
   data: function() {
      return {
         nextRoute: '/menu/list'
      }
   },
   props: {
       total_qty: Number,
       total_cost: Number,
       item_grid: Boolean,
       title: String,
       image_base: String,
       selection: { type: Array },
       items: [],
       navRouting: { type: Object},
       from_summary: Boolean
  },
  methods: {
    itemTotal(row) {
      return this.formattedAmount(this.costTotal(row.qty, row.cost) / 100);
    },
    overallTotal() {
      return this.formattedAmount(this.total_cost);
    },
    adjustItem(item, qty, si) {
      this.$bus.$emit('amend-item-select', item, qty, si);
    },
    menuUrl() {
      let url = '/menu/list';
      if (this.item_grid) {
         url = '/menu/grid';
      }
      return url;
    },
  },

  created: function() {
     if (this.item_grid) {
        this.nextRoute = '/menu/grid';
     }
    // console.info('BASKET selection:' + JSON.stringify(this.selection));
    // console.info('BASKET: ' + this.item_grid + ' ' + this.selection.length);
  },
}
</script>

<style scoped lang="scss">
//@import "../../node_modules/bulma/sass/elements/title.sass";
//@import "../../node_modules/bulma/sass/elements/table.sass";
section.basket {
  table {
    img {
      max-width:120px;
    }
  }
}

table {
  margin:0 auto;
  td {
    padding:0 7px;
    @media screen and (min-width:600px) {
      padding:0 15px;
    }
    img {
      max-width:100px;
    }
  }
  td.num {
    text-align: right;
  }
}
</style>
