<template>
   <div class="page-user-register">
      <h1 class="subtitle is-3">User Registration</h1>
      <CustomerInfoForm title="Register a new account" submitButtonName="Register"
                        :user="this.user"
                        :fieldsUsed=fieldsUsed
                        :fieldsRequired=fieldsRequired
                        :errors=errors
                        :saveConfig="saveConfig"
                        :initialSaveOptions="saveOptions"
                        :mainStatus="mainStatus"
                        :emailError="emailError"
                        :addAddress="true"
                        @update:user="userFormUpdate"
                        @update:addressList="addressListUpdate"
                        @update:address="addressFormUpdate"
                        @update:address-selected="addressSelected"
                        @update:postcode-invalid="postcodeInvalid"
                        @check:email="checkEmail"
                        @anotherAddress="anotherAddress"
                        @reset="reset"
                        @submit="submit" />

      <button class="button is-submit is-fullwidth" @click="submit">Submit</button>

      <!-- Registration complete modal -->
      <slot-modal
         :show="this.showModal"
         :classes="this.modalClasses"
         :closeButton="false"
      >
         <template v-slot:header>
            <h2 class="subtitle centre is-3">Registration complete</h2>
         </template>
         <template v-slot:body>
            <p class="pr-section subtitle centre is-5 block">
               Thank you for registering!
            </p>

         </template>
         <template v-slot:footer>
            <button class="button is-fullwidth is-primary is-modal centre"
                    @click="closeModal"
            >
               Close
            </button>
         </template>
      </slot-modal>

      <!-- Email exists modal -->
      <slot-modal
         :show="this.showExistEmailModal"
         :classes="this.modalClasses"
      >
         <template v-slot:header>
            <h2 class="subtitle centre is-3">Email registered</h2>
         </template>
         <template v-slot:body>
            <p class="pr-section centre">
               An account for <b>{{ email }}</b> already exists.</p>
            <p>Do you want to login? Or use a different email address to continue registration?
            </p>

         </template>
         <template v-slot:footer>

            <button class="button is-fullwidth is-primary is-modal centre"
                    @click="loginPage(email)"
            >
               Login
            </button>

            <button class="button is-fullwidth is-outlined is-primary is-modal centre"
                    @click="closeDuplicateModal"
            >
               Continue
            </button>
         </template>
      </slot-modal>

      <nav-footer
         :nav-routing="this.navRouting"
      >
      </nav-footer>
   </div>
</template>

<script>
import CustomerInfoForm from '@/views/CustomerInfoForm';
import ProfileService from '@/services/profileService';
import FormUtils from '@/utils/formUtils' ;
import SlotModal from '@/components/SlotModal';
import NavFooter from "@/components/NavFooter";

export default {
   name: 'UserRegister',
   components: {
      CustomerInfoForm, SlotModal, NavFooter
   },
   props: {
      user: { type:Object },
      settings: { type: Object, default: () => ({ phone: 'required'}) },
      navRouting: { type:Object }
   },
   created() {
      console.log('User:' + JSON.stringify(this.user));
   },
   data: function() {
      // full list of fields appearing on form
      // @TODO property values redundant - can be removed
      const fieldsUsed =  {
         forename: true,
         surname: true,
         email: true,
         phone: true,
         // notes: false,
         password: true,
         //  save: null,
         address: true,
         postcode: true
      };

      // @TODO property VALUES redundant - can be removed
      const fieldsRequired = {
         surname:true,
         email:true,
         phone:true,
         password:true,
         address:true,
         postcode:true
      };

      const ukPhoneLength = 11;
      const passwordLength = 8;
      const modalClasses="modal";

      return {
         mainStatus: undefined,
         emailError: undefined,
         saveConfig: 	{ showSaveDetailsToggle: false, showSaveAddressToggle: true, saveDetailsAndAddressLinked: false },
         saveOptions: 	{ saveAddress: true },

         // all these fields below are
         // required for CustomerInfoForm
         fieldsRequired,
         fieldsUsed,
         errors: {},
         ukPhoneLength,
         passwordLength,
         modalClasses,

         showModal: false,
         showExistEmailModal: false,

         emailRegex: new RegExp("^\\s*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+\\s*$"),
         csrfToken: "",

         formComplete: false, // is user form complete and validated?
         email: String
      }
   },

   beforeMount() {
      let service = new ProfileService();
      service.register_init(this, null, function(response) {
         console.warn('Register init response: ' + JSON.stringify(response));
         if (response.ok) {
            this.csrfToken = response.data.token;
         }
      });

      // set all required fields to non-null state3
      for (let fld in this.fieldsRequired) {
         this.errors[fld] = "";
      }
   },

   methods: {
      userFormUpdate(field, val) {
         switch (field) {
            case 'forename':
               if ('forename' in this.errors)
                  this.errors.forename = "";
               break;
            case 'surname':
               if ('surname' in this.errors)
                  this.errors.surname = "";
               break;
            case 'email':
               if ('email' in this.errors)
                  this.errors.email = "";
               break;
            case 'phone':
               if ('phone' in this.errors)
                  this.errors.phone = "";
               break;
            case 'password':
               if ('password' in this.errors)
                  this.errors.password = "";
               break;
            case 'postcode':
               if ('postcode' in this.errors)
                  this.errors.postcode = "";
               break;
            case 'notes':
               if ('notes' in this.errors)
                  this.errors.notes = "";
               break;
            case 'save':
               break;
            default:
               console.warn('UFU(1):case: ' + field + ' not found');
               break;
         }
         this.$emit('update:user-form', field, val);
      },

      // == address handling methods ==
      addressListUpdate(addr) {
         console.log('addressListUpdate:' + JSON.stringify(addr));
         this.$set(this.errors, 'address', '');
         this.$emit('update:address-list', addr);
      },

      // == address handling methods ==
      /**
       * Address handling code, required as child component is CustomerInfoForm
       * and address handling comes here.
       * @param field
       * @param val
       */
      addressFormUpdate(field, val) {
         if (this.hasOwnProperty('errors')) {
            switch (field) {
               case 'house':
                  if ('house' in this.errors)
                     this.errors['house'] = null;
                  if ('address' in this.errors)
                     this.errors.address = "";
                  break;
               case 'addr1':
                  if ('addr1' in this.errors)
                     this.errors['addr1'] = "";
                  break;
               case 'addr2':
                  if ('addr2' in this.errors)
                     this.errors['addr2'] = "";
                  break;
               case 'town':
                  if ('town' in this.errors)
                     this.errors['town'] = "";
                  break;
               case 'postcode':
                  if ('postcode' in this.errors)
                     this.errors.postcode = "";
                  if ('address' in this.errors)
                     this.errors.address = "";
                  break;
               case 'premises':
                  console.info('AFU:address:premises:' + val);
                  break;
               default:
                  console.warn('AFU:switch case: ' + field + ' not found');
                  break;
            }
         }
         this.$emit('update:address-form', field, val);
      },

      // == address handling methods ==
      addressSelected(addr) {
         this.errors.address = null;
         this.$emit('update:address-selected', addr);
      },

      // == address handling methods ==
      anotherAddress() {
         this.addAddress = true;
         this.$emit('another-address');
      },

      // if postcode lookup failed
      postcodeInvalid() {
         console.warn('postcode lookup failed');
         this.errors.postcode = true;
         this.errors.address.postcode = true;
      },

      // Address handling methods
      reset(keepHouseAndPostcode) {
         this.addAddress = true;
         if (this.errors.hasOwnProperty('postcode'))
            this.errors.postcode = false;
         if (this.errors.hasOwnProperty('address') && this.errors.address &&
            this.errors.address.hasOwnProperty('postcode'))
            this.errors.address.postcode = false;

         this.$emit('reset', keepHouseAndPostcode);
      },

      submit() {
         // field validation
         for (let prop in this.fieldsRequired) {
            // postcode is special exception, it's not in
            // user object but IS a required field.
            if (prop != 'postcode') {
               if (prop in this.user && !this.user[prop]) {
                  this.errors[prop] = 'missing';
               }
               else if (!prop in this.user || this.user[prop] === undefined)
                  console.error('Prop:', prop, ' is required but not in field list!');
            }
         }

         if (this.fieldsUsed['email']) {
            if (!this.emailRegex.test(this.user['email'])) { // Email check
               this.errors['email'] = 'invalid'
            }
         }

         if (this.fieldsUsed['phone']) {
            const re = / /g;
            let phoneVal = this.user['phone'];
            if (phoneVal) {
               const ph = phoneVal.replace(re, '');
               console.info('phone=',ph);
               if (ph.length !== this.ukPhoneLength || ph.substr(0,1) !== '0') { // Phone check
                  this.errors['phone'] = 'length invalid'
               }
            }
         }

         if (this.fieldsUsed['password']) {
            if (this.user.hasOwnProperty('password') && this.user.password)
            {
               if (this.user.password.length < this.passwordLength) {
                  this.errors.password = 'Must be at least ' + this.passwordLength + ' chars long';
               }
               else {
                  if (!this.user.password.match(/\d/)) {
                     this.errors.password = 'Must contain at least one number';
                  }
               }
            }
            else
               this.errors.password = 'missing';
         }

         if (this.fieldsUsed['postcode'] && this.errors.postcode || this.user.address.postcode) {
            this.errors.postcode = 'invalid';
         }

         if (!this.user.address || FormUtils.isEmpty(this.user.address) ||
            !(this.user.address.postcode || this.user.address.town))
            this.$set(this.errors, 'address', 'No address selected');

         let errors = FormUtils.propertiesWithValues(this.errors);
         if (!FormUtils.isEmpty(errors)) {
            let str = '';
            for (let prop in errors) {
               if (Object.prototype.hasOwnProperty.call(this.user, prop)) {
                  str += prop + ': ' + this.errors[prop] + '\r\n';
               }
            }
            console.warn('CUST form: not ready yet: ' + str);
         }
         // Form looks OK!
         else {
            // populate cust record.
            // for logged-in user copy the defaults, plus any form fields added.
            let cust = {};
            if (this.user.loggedIn) {
               cust = this.user;
               cust.phone = this.user.phone;
               cust.notes = this.user.notes;
               cust.address = this.user.address;
            }
            else {
               // just copy the whole form contents into cust record
               cust = Object.assign(cust, this.user, this.user.address);
               delete(cust.address);
               delete(cust.loggedIn);
               delete(cust.name);
            }
            if (!('save' in cust) || typeof cust.save === 'undefined') {
               cust.save = 0;
            }

            // in case fields have NOT been entered,
            // there will be NO error, check this here.
            let complete = true;
            for (let prop in this.fieldsRequired) {
               if (prop in cust && !cust[prop]) {
                  console.warn(prop + ' missing');
                  this.errors[prop] = 'missing';
                  complete = false;
               }
            }
            if (!this.user.address.postcode) {
               console.warn('postcode missing');
               this.errors['postcode'] = 'missing';
               complete = false;
            }

            if (!this.user.address.house) {
               console.warn('house missing');
               this.errors['house'] = 'missing';
               complete = false;
            }
            this.errors = Object.assign({}, this.errors);

            // cleanse cust of any null values which get JSON-ified to 'null' on server
            // @TODO should init user properties to empty string
            for (let key in cust) {
               if (cust.hasOwnProperty(key)) {
                  if (cust[key] == null) cust[key] = '';
               }
            }

            if (complete) {
               // required for delivery cost / charges calc
               cust.order_total = this.total_cost;

               // @TODO is this CSRF still used??
               cust.token = this.csrfToken;

               console.info('CUST:' + JSON.stringify(cust));
               this.$emit('info-submit', cust);
               this.formComplete = true;
               this.postForm(cust);
            }
         }
      },

      checkEmail(email) {
         let svc = new ProfileService();
         this.email = email;
         const func = function() {
            let data = new FormData();
            data.append('email', email);
            svc.uniqueEmailCheck(this, data, function(response) {
               if (response.ok) {
                  if (!response.data.unique) {
                     this.showExistEmailModal = true;
                  }
               }
               else {
                  if (response.error_code === 'XHR_SEND_ERR' || response.isServerError)
                     this.$emit('displayNetworkError', response, this, func);
                  else {
                    // this.mainStatus = { category: 'error', msg: response.error };
                     if (response.error_code === 'LOGGED_IN') this.emailError = {category: 'invalid', msg: 'account already exists'} ;
                  }
               }
            });
         }
         func.call(this) ;
      },

      openModal() {
         this.showModal = true;
      },

      closeModal() {
         this.$router.push('/menu/list');
      },

      closeDuplicateModal() {
         this.showExistEmailModal = false;
      },

      loginPage(email) {
         this.showExistEmailModal = false;
         this.$router.push({'name':'UserLogin','params':{'email':email}});
      },

      postForm(cust) {
         const data = FormUtils.objToFormFields(cust, 'address');

         let svc = new ProfileService();
         const func = function() {
            svc.register(this, data, function(responseObj) {
               if (responseObj.ok) {
                  // should have received JWT
                  this.$emit('userAuthReceived', svc.header('authorization'));
                  this.openModal();
               }
               else { // Error
                  if (responseObj.error_code === 'XHR_SEND_ERR' || responseObj.isServerError) {
                     this.$emit('displayNetworkError', responseObj, this, func) ;
                  }
                  else {
                     this.mainStatus = { category: 'error', msg: responseObj.error }
                     if (responseObj.error_code === 'LOGGED_IN') this.emailError = {category: 'invalid', msg: 'account already exists'} ;
                  }
               }
            });
         }
         func.call(this) ;
      }
   }
}
</script>