<template>
  <router-link v-if="isButtonActive" class="prime" :to="url">
    <button class="button prime">
      <img :src="require('@/assets/image/' + image)"
           :class="klass"
           :alt="alt"
      />
      {{ text }}
    </button>
  </router-link>
</template>

<script>
export default {
  name: "primeButton",
  props: {
    is_grid: Boolean,
    is_active: Boolean,
    option: String
  },
  data: function()  {
    let actions = {
      'menu': {
        'class':'menu',
        'alt':  'menu',
        'text': 'Menu',
        'img':  'menu.svg',
        'url': '/menu/list'
      },
      'basket': {
        'class':'basket',
        'alt':  'basket',
        'text': 'Basket',
        'img':  'basket.svg',
        'url':  '/basket'
      }
    };

    if (this.is_grid)
      actions['menu'].url = '/menu/grid';

    return {
      actions
    }
  },

  computed: {
    klass() {
      return this.actions.hasOwnProperty(this.option) ? this.actions[this.option].class : '';
    },
    image() {
      return this.actions.hasOwnProperty(this.option) ? this.actions[this.option].img : '';
    },
    alt() {
      return this.actions.hasOwnProperty(this.option) ? this.actions[this.option].alt : '';
    },
    text() {
      return this.actions.hasOwnProperty(this.option) ? this.actions[this.option].text : '';
    },
    url() {
      return this.actions.hasOwnProperty(this.option) ? this.actions[this.option].url : '';
    },
    isButtonActive() {
      if (this.is_active && this.actions.hasOwnProperty(this.option))
        return true;
      return false;
    }
  }
}
</script>