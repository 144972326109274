<template>

   <!-- Registration complete modal -->
   <slot-modal
      :show="this.show"
      :containerClass="settings.container_class"
   >
      <template v-slot:header>
         <h2 :class="settings.header_class" class="subtitle centre is-4">{{ settings.title }}</h2>
      </template>

      <template v-slot:body>
         <p class="pr-section centre prominent block">
            {{  settings.subtitle }}
         </p>
      </template>

      <template v-slot:footer>
         <button v-if="settings.button.close" class="button is-fullwidth is-warning is-primary is-modal centre"
                 @click="closeModal"
         >
            Close
         </button>
         <button v-if="settings.button.continue" class="button is-fullwidth is-primary is-modal centre"
                 :class="settings.button_class"
                 @click="closeModal"
         >
            Continue
         </button>
         <button v-if="settings.button.cancel" class="button is-fullwidth is-primary is-modal centre"
                 :class="settings.button_class"
                 @click="closeModal"
         >
            Cancel
         </button>
         <button v-if="settings.button.retry" class="button is-fullwidth is-primary is-modal centre"
                 :class="settings.button_class"
                 @click="retry"
         >
            Retry
         </button>
      </template>
   </slot-modal>
</template>

<script>
import SlotModal from "@/components/SlotModal";

export default {
   name: "Modal",
   components: { SlotModal },
   props: {
      show: Boolean,
      settings: Object,
      closeUrl: {type:String, default:""}
   },
   methods: {
      closeModal() {
         this.$emit('close-modal');
         if (this.closeUrl)
            this.$router.push(this.closeUrl);
      },
      retry() {
         console.info('Retry event');
         if (this.settings.hasOwnProperty('context') && this.settings.context) {
            if (this.settings.hasOwnProperty('retry') && this.settings.retry) {
               console.info('Retry method called');
               this.$emit('close-modal');
               this.settings.retry.call(this.settings.context);
            }
         }
      }
   }
}
</script>

<style scoped>

</style>