// Component: PaymentForm
// Adapted from 'HTML' Frontend (+ JS + CSS) @ https://stripe.com/docs/payments/quickstart

<template>
  <!-- Display a payment form -->
    <form id="payment-form" @submit.prevent="handleSubmit" style='margin:auto;'>
      <div id="payment-element">
        <!--Stripe.js injects the Payment Element-->
      </div>
      <button id="submit" :disabled="isLoading">
        <div v-if="isLoading" class="spinner hidden" id="spinner"></div>
        <span v-else id="button-text" class="button is-link">Pay now</span>
      </button>
      <div v-if="statusMsg" id="payment-message" class="hidden">{{statusMsg}}</div>
    </form>
</template>

<script>
import PaymentService from '@/services/paymentService' ;
export default {
	name: "PaymentForm",
	components: { },
	props: {
		selection: { type: Array, default: () => []}, // The items the customer wants to buy
		stripe: Object
	},
  data:function () {
    return {
			elements: null, 
			svc: new PaymentService(),
			statusMsg: "",
			isLoading: false
    };
  },
  async mounted() {
		await this.createStripe() ;
		await this.initialize() ;
	},
	methods: {
		async createStripe() {
			if (!this.stripe) this.$emit('stripeLoaded', await this.$loadStripe(process.env.VUE_APP_STRIPE_KEY)) ;
		},
		async initialize() {
			const func = function() {
				this.svc.createPaymentIntent(this, this.items, function(responseObj) {
					if (responseObj.ok) {
						const { clientSecret } = responseObj.data ;
						const appearance = { theme: 'stripe' };
						this.elements = this.stripe.elements({ appearance, clientSecret });
						this.paymentElement = this.elements.create("payment");
						this.paymentElement.mount("#payment-element");
					}
					else { // Error
							this.$emit('networkErrorModal', responseObj, this, func);
						}
				});
			}
			func.call(this) ;
		},
		async handleSubmit() {
			this.isLoading = true ;
			const elements = this.elements;
			const { error } = await this.stripe.confirmPayment({
				elements,
				confirmParams: {
					return_url: (window.location.origin + '/paymentResult')
				},
			});

			// Handle immediate errors (if none then user will have already been redirected)
			if (error.type === "card_error" || error.type === "validation_error") {
				this.showMessage(error.message);
			} else {
				this.showMessage("An unexpected error occured.");
			}
			// this.setLoading(false);
			this.isLoading = false ;
		},

		// ------- UI helpers -------
		showMessage(messageText) {
			this.statusMsg = messageText ;

			let that = this ;
			setTimeout(function () {
				that.statusMsg = "" ;
			}, 4000);
		},
	}
}
</script>

<style scoped>
/* Variables */
* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
</style>