// Service: PaymentService
import BaseService from "./baseService";
import AjaxUtils from "../utils/ajaxUtils";

export default class PaymentService extends BaseService {

	constructor() {
		super({ "Content-Type": "application/json" }, process.env.VUE_APP_STRIPE_SERVER);
		this.paymentEndpoint = '/payment';
	}

	createPaymentIntent(that, items) {
		this.submitDebug(that, 'POST', this.paymentEndpoint, JSON.stringify(items));
	}
}