<template>
  <transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :class="containerClass">

          <div class="modal-header">
            <img v-if="closeButton" class="modal-exit" :src="require('@/assets/image/close.svg')"
                 @click="$emit('close')"
            >
            <slot name="header">
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
            </slot>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "SlotModal",
  props: {
    show: Boolean,
    containerClass: String,
    closeButton: Boolean,
  }
}
</script>