/**
 * @notes Hard coded currently - contains vendor / application settings
 * @property vendorId
 * @property phone
 * @property orderEmailConfirm
 */
class Settings
{
    constructor(vid, name) {
        // @TODO load Vendor settings via mobile API
        if (vid) {
            this.vendorId = vid;
            this.vendorName = name;
        }
        else {
            // default vendor ID
            this.vendorId = 1;
            this.vendorName = 'CafeOnTheHill';
        }
        // merge options into this object
        Object.assign(this, this.vendorOptions());
    }

    vendorOptions() {
        return {
            phone:false,
            orderEmailConfirm:false,
            useLocalStorage: true,
            tonyTheme: false,

            demo:1,
            opening_page:1,
            item_grid:0,
            collection:0,
            email_verify: 1,
            phone_verify: 0,

            login_to_place_order:0,

            image_folder:null,
            offers_heading: null,
            banner_image_url: null,
            banner_caption: null
        }
    }

    static url() {
        return window.location.origin;
    }
}

export default Settings;