function delete_cookie( name, path, domain ) {
    if( get_cookie( name ) ) {
        document.cookie = name + "=" +
            ((path) ? ";path="+path:"")+
            ((domain)?";domain="+domain:"") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}

function get_cookie(name){
    return document.cookie.split(';').some(c => {
        return c.trim().startsWith(name + '=');
    });
}

function create_cookie(name,value,domain,days) {
    let expires = "";
    days |= 1;  // default is one day
    if (days) {
        let date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        expires = "; expires="+date.toUTCString();
    }
    // force FQDN
    // if (domain[0] !== '.')
    //     domain = '.' + domain;
    document.cookie = name+"="+value+expires+"; path=/; domain=" + domain;
}

export { delete_cookie, get_cookie, create_cookie }