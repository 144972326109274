<template>
   <body class="has-navbar-fixed-top">
   <div id="app">
      <!-- Header / Navbar -->
      <!-- Not guarded with v-if / v-show (on userdata load) so accessible when 'no connection' test-mode active -->
      <!-- @TODO Possibly add a guard for production release -->
      <navbar
         :qty=total_qty
         :cost=total_cost
         :isGrid="this.options.item_grid === 1"
         :isProduction=this.isProduction
         :categories="this.categories"
         :imageBase="imagebase"
         :settings=this.settings
         :profileName="profileName"
         :active=true
         :option="this.currentRoute"
         :primeButton="this.primeButton"

         @user-logout="userLogout"
         @category-selected="categorySelected"
      >
      </navbar>

      <!-- Nav-crumbs -->
      <nav-crumbs v-if="useBreadcrumbs"
                  :activeOption="this.currentRoute"
                  :qty=total_qty
                  :settings=this.settings
                  :infoComplete="infoComplete"
                  v-on:crumb-click="crumbClick"
      >
      </nav-crumbs>

      <div v-if="this.$route.path === '/install'">
         <router-view
            :error="this.installError"
            @install-submit="installSubmit"
         >
         </router-view>
      </div>

      <div v-if="this.$route.path === '/completed'">
         <router-view
            :navRouting="this.navRouting"
            @reload-menu-page="reloadMenuPage"
         >
         </router-view>
      </div>

      <div v-else-if="this.$route.path === '/login'">
         <router-view
            :loginFields="this.loginFields"
            :from="this.fromPath"
            :navRouting="this.navRouting"

            @field-update="fieldUpdate"
            @user-login="userLoggedIn"
         >
         </router-view>
      </div>


      <div v-else-if="this.$route.path === '/register'">
         <router-view
            :loginFields="this.loginFields"
            :user="this.user"
            :navRouting="this.navRouting"

            @update:user-form="userFormUpdate"
            @update:address-form="addressFormUpdate"
            @update:address-selected="addressSelected"
            @userAuthReceived="userAuthReceived"
         >
         </router-view>
      </div>

      <div v-else-if="this.$route.path === '/menu/grid' || this.$route.path === '/menu/list'">
         <router-view
            :settings="this.settings"
            :categories="this.categories"
            :category="this.category"
            :user=this.user
            :items="this.items"
            :selectedItem=selectedItem
            :selectedItemQty=selectedItemQty
            :selectedItemCost=selectedItemCost
            :navRouting="this.navRouting"
            :attributes=attributes
            :selection=selection
            :total_qty=this.total_qty
            :total_cost=this.total_cost
            :image_base="imagebase"
            :stripe=stripe

            @select-modal-open="selectModalOpen"
            @select-modal-close="selectModalClose"
            @category-select="categorySelected"
            @data-required="getInitialData"
         >
         </router-view>
      </div>

      <div v-else-if="this.$route.path === '/information'">
         <router-view
            :profileName="profileName"
            :settings="this.settings"
            :user=this.user
            :infoComplete="this.infoComplete"
            :total_cost=this.total_cost
            :navRouting="this.navRouting"

            @update:address-list="addressListUpdate"
            @update:user-form="userFormUpdate"
            @update:address-form="addressFormUpdate"
            @update:address-selected="addressSelected"
            @modal-network-error="networkErrorModal"
            @modal-invalid-data="invalidDataModal"
            @another-address="anotherAddress"
            @info-complete="userInfoComplete"
            @info-submit="infoSubmit"
            @reset="reset"
         >
         </router-view>
      </div>

      <div v-else-if="this.$route.path === '/basket'">
         <router-view
            :user=this.user
            :selection=selection
            :total_qty=this.total_qty
            :total_cost=this.total_cost
            :title="basketTitle"
            :image_base="imagebase"
            :item_grid="this.settings.item_grid==='1'"
            :navRouting="this.navRouting"
         >
         </router-view>
      </div>

      <!-- Main content container for pre-load hiding -->
      <!-- most important component is OrderSummary /summary
            OpeningPage
            OrderComplete
            PaymentForm
      -->
      <div v-else-if="initialDataLoaded === 1">

            <router-view
               :profileName="profileName"
               :options=this.options
               :settings=this.settings
               :choices="this.choices"

               :user=this.user
               :items=items
               :selection=selection
               :total_qty=this.total_qty
               :total_cost=this.total_cost
               :image_base="imagebase"
               :stripe=stripe
               :navRouting="this.navRouting"

               @stripeLoaded="stripeLoaded"
               @networkErrorModal="networkErrorModal"
               @order-invalid="orderInvalidModal"
               @pageLoadComplete="pageLoadEvent"
               @info-submit="infoSubmit"
               @form-error="formError"
               @get-profile="getProfileData"
               @login-clicked="loginClicked"
               @user-logout="userLogout"
               @setDefault="setDefault"
               @order-complete="orderComplete"
               @order-payment="orderPayment"
               @online-payment="onlinePayment"
            >
            </router-view>

      </div>
   </div>
   <!-- Session-expiry modal -->
   <!-- picks up session-expired events from this.$bus -->
   <modal
      :show="this.showModal"
      :settings="this.modalSettings"
      @close-modal="this.clearModalError"
   ></modal>

   </body>
</template>

<script>

// Client database wrapper (JP - 08/02/22 - @TODO Currently only used by tests/mocks/log-system,
// remove in production if no longer required)
// import ClientDB from '@/utils/clientDB' ;
// window.clientDB = new ClientDB('sessionStorage') ;

// Mixins
import currencyMixin from './mixins/Currency';
import selectionMixin from './mixins/Selection';
import selectionAttrMixin from './mixins/SelectionAttr';
import navRoutingMixin from './mixins/NavRouting';

// Components
import Navbar from './components/Navbar' ;
import NavCrumbs from "./components/NavCrumbs";
import Modal from './components/Modal';

// Services
import InitialDataService from "@/services/initialDataService";
import ProfileService from "@/services/profileService";
import User from "@/user";
import LoginService from "@/services/loginService";

// Misc
import router from "@/router/index";
import {delete_cookie, get_cookie} from "@/utils/cookie";

import Settings from "@/settings";
import LoadCSS from "@/utils/loadCSS";

// CSS
import '@/css/providr/themes/default/main.css';
import EventBus from "@/utils/eventBus";
import Sidebar from "@/components/Sidebar";
import Vue from "vue";

export default {
   name: 'App',
   components: {
      Navbar,
      NavCrumbs,
      Modal
   },

   mixins: [
      currencyMixin,
      selectionMixin,
      selectionAttrMixin,
      navRoutingMixin
   ],

   data: function() {
      return {
         //--------------------------
         // prime menu listing data
         //--------------------------
         items: [],
         categories: [],
         attributes: [],
         selection: [],  // selected item list

         // currently selected item
         selectedItem: {},

         // currently selected category
         category: 0,

         // selection count and cost stats
         selectedItemQty: 0,
         selectedItemCost: 0,
         total_qty: 0,
         total_cost: 0,

         navRouting: {
            nextRoute: null,
            prevRoute: null,

            prevEvent: null,
            nextEvent: null,
            prevLabel: null,
            nextLabel: null,

            onlinePayment: true,  // default
            nextDisabled: false,
            submitButton: false
         },

         //----------------------
         // user data
         //-----------------------
         profileName: null,  // if logged in, the profile user's name
         user: new User(),
         settings: new Settings(),  // vendor settings, title, logo, offers, opening times
         options: Object,               // vendor options, e.g. Opening Page, ItemGrid
         choices: Object,           // currently pay on delivery, or pay online
         loginFields: { email:null, password: null},

         fromPath: '/',
         orderStage: false,   // records stage of ordering process
         infoComplete: false, // true when all required info
                              // (contact and address) details received
         primeButton: "",  // String that denotes the prime button to display on navbar (or elsewhere)
         showModal: false, // modal display switch
         modalSettings: { 'container_class': '', 'button': {
               'retry':false,
               'cancel': false,
               'close': false,
               'continue': false
            }
         },
         currentComponent: null,
         currentRoute: null,   // the current page or component
         isProduction: (process.env.NODE_ENV === 'production'),
         stripe: null,
         basketTitle: 'Basket',

         initialDataLoaded: -1, // -1 = failed, 0 = pending, 1 = success
         itemsLoadState: 0,  // -1 = failed, 0 = pending, 1 = success

         networkError: null,
         sessionError: null,
         installError: null  // error if install fails
      }
   },

   computed: {
      imagebase() {
         return process.env.VUE_APP_SERVER + '/images/vendor/' +
         this.settings['image_folder'] + '/';
      },
      useBreadcrumbs() {
         if (this.total_qty &&
            this.$route.path !== '/' &&
            this.$route.path !== '/logout' &&
            this.$route.path !== '/install' &&
            this.$route.path !== '/completed')
            return true;
         return false;
      }
   },

   mounted() {
      this.doRoutes(this.$route.path, this.total_qty, this.user, this.options);
   },

   created() {
      let self = this;
      // TODO delivery is only choice currently, collection handling eventually
      this.choices = { 'onlinePayment':true, 'delivery':true };

      this.currentRoute = this.$route.path.substring(1);
      console.info('Created: OPTION <' + this.currentRoute + '>');

      if (this.currentRoute !== 'install') {
         /**-----------------------------------------
          * load all the initial data at startup,
          * unless data is currently being loaded.
          *-----------------------------------------*/
         this.loadData();

         if ('logout' === this.currentRoute) {
            this.profileName = null;
            if (this.user)
               this.user = null;
            delete_cookie('ca', '/', process.env.VUE_APP_SERVER);
         }

         // prime button settings
         switch (this.currentRoute) {
            case '':
               this.primeButton = 'menu';
               break;
            case '/':
               this.primeButton = 'menu';
               break;
            case 'basket':
               this.primeButton = 'menu';
               break;
            case 'menu':
               this.primeButton = 'basket';
               break;
            case 'information':
               this.primeButton = 'basket';
               break;
            case 'register':
               this.primeButton = 'menu';
               break;
            case 'completed':
               this.primeButton = 'menu';
               this.navRouting.nextEvent = 'reload-menu-page';
               break;
            // e.g. logout
            default:
               this.primeButton = '';
               break;
         }
      }

      // --------------------------------------------
      // entry point for list & grid selection modes
      // first prepareAttributes for this item
      // --------------------------------------------
      this.$bus.$on('amend-item-select', function(item, amendQty, si) {
         const aq = parseInt(amendQty, 10);
         console.info('AIS:' + amendQty + ' ' + aq + ' ' + JSON.stringify(item));

         if (aq !== 0)
         {
            let itemVerified = self.items.find(elem => elem.rid === item.rid);
            if (itemVerified && itemVerified.rid)
               self.amendItemQuantities(itemVerified, aq, si);
            else
               console.warn('item-select: item ' + item.rid + ' not found!');
         }
         else
            console.warn('item-select: qty:' + aq + ' not processed');
      });

      /**-----------------------------------------------------
       * Remove an item-attribute grouping, this is only used
       * for items with attributes, and could be more than
       * one item if attribute quantity grouping > 1.
       -----------------------------------------------------*/
      this.$bus.$on('item-attr-remove', function(si) {
         if (self.selectedItem.hasOwnProperty('si') && self.selectedItem.si && 1 <= self.selectedItem.si.length)
         {
            console.info('Search for <' + si.d + '>');
            let siIndex = self.selectedItem.si.findIndex(elem => elem.d === si.d);
            if (0 <= siIndex) {
               let removedItems = self.selectedItem.si.splice(siIndex, 1);
               let reductionAmount = 0;
               let q = 0;
               if (removedItems.length) {
                  // console.info('REMOVED ITEMS: ' + JSON.stringify(removedItems));
                  q = parseInt(removedItems[0].q, 10);
                  reductionAmount = q * parseInt(removedItems[0].c, 10);
                  self.selectedItem.qty -= q;
                  self.selectedItem.total.q -= q;

                  // amend IA counts (.q and .c properties) for m and e type attributes.
                  for (let n = 0; n < removedItems[0].ma.length; n++)
                     self.updateIaQty(self.selectedItem, removedItems[0].ma[n], -q);
                  for (let n = 0; n < removedItems[0].ea.length; n++)
                     self.updateIaQty(self.selectedItem, removedItems[0].ea[n], -q);
               }
               self.selectedItemQty -= q;
               self.total_qty -= q;
               self.selectedItemCost -= reductionAmount;
               self.total_cost -= reductionAmount / 100;
            }
            else
               console.warn('Did not find idx for ' + JSON.stringify(si));
            //-----------------------------------------------------
            // Update selection object - delete if qty now zero.
            //-----------------------------------------------------
            if (self.selected && !self.selectedItem.qty)
            {
               const idx = self.selected.findIndex(elem => elem.rid === self.selectedItem.rid);
               self.selection.splice(idx, 1);
            }

            if (self.selected)
               self.selection = self.clearUnusedSelection(self.selection);
            else
               console.warn('item-attr-remove: no items selected - no clean-up required');

            // store selection in cache
            localStorage.setItem('selection', JSON.stringify(self.selection));
         }
         else
            console.error('item-attr-remove: item NOT found!');
      });

      // --------------------------------------------
      // entry point for list & grid selection modes
      // first prepareAttributes for this item
      // --------------------------------------------
      this.$bus.$on('basket-change', function(item, si, qty) {

         let idx = self.selection.findIndex(i => i.rid === item.rid);
         console.info('BC: idx=' + idx);
         if (0 <= idx) {
            console.info('basket-change:found .... qty:' + qty + ' cost:' + item.cost + ' si:' + JSON.stringify(si));
            console.info('bask:' + JSON.stringify(self.selection[idx]));
            const newCost = parseInt(item.cost,10) * qty;
            if ((qty < 0 && self.selection[idx].qty > 0) || qty > 0) {
               self.selection[idx].qty += qty;
               self.total_qty += qty;
               self.total_cost += newCost / 100;

               self.selectedItem.qty -= qty;
               self.selectedItem.total.q -= qty;

               self.selectedItemQty = item.qty = self.selection[idx].qty;

               this.$set(self.selection);
               console.info('BASKET-FOUND:' + JSON.stringify(self.selection[idx]));
               if (si)
                  self.amendItemAttributeQty(self.selection[idx], si, qty);

               self.selection = self.clearUnusedSelection(self.selection);

               // store selection in cache
               console.info('BC:selection:' + JSON.stringify(self.selection));
               localStorage.setItem('selection', JSON.stringify(self.selection));
            }
         }
         else
            console.warn('basket-change:item not found!:' + item.description);
      });

      this.$bus.$on('attr-e-select', function(item, attr, idx, idx2, qty) {
         self.updateExtraAttributeSelected2(item, attr, idx, idx2, qty);
         this.$set(item);
      });

      this.$bus.$on('attr-m-select', function(item, attrSelected, idx, idx2, qty) {
         self.updateOneOfAttributeSelected(item, attrSelected, idx, idx2, qty);
         this.$set(item);
      });

      this.$bus.$on('attr-complete', function(item) {
         this.$set(item);
      });

      this.$root.$on('storeUser', function(response) {
         console.info('store-user event detected!');
         console.info('user:' + JSON.stringify(response));
         this.user = response.data;
      });

   }, // end created()

   // In the watch aka WATCH
   watch:{
      deep: true,
      $route(to, from) {
         this.fromPath = from.path;

         // prime button settings
         switch (to.name)
         {
            case 'OrderBasket': this.primeButton = 'menu'; break;
            case 'SelectionList': this.primeButton = 'basket'; break;
            case 'SelectionGrid': this.primeButton = 'basket'; break;
            case 'OrderInfoManager': this.primeButton = 'basket'; break;
            case 'UserRegister':
               if (!this.user.hasOwnProperty('address'))
                  this.user.address = {};
               this.primeButton = 'menu';
               break;
         }

         if (!this.settings)
            this.settings = this.$root.settings;

         this.currentRoute = to.path.substring(1).toLowerCase() ;

         if (this.currentRoute.length <= 1)
            this.currentRoute = '/';
         console.log('Watch option: ' + this.currentRoute);

         // remove 'order' from store as it's only used
         // from OrderSummary to OrderComplete transition
         if (to.path === '/menu/grid' || to.path === '/menu/list') {
            if (localStorage.getItem('order')) {
               localStorage.removeItem('order');
            }
         }

         // handle route buttons
         this.doRoutes(this.$route.path, this.total_qty, this.user, this.options);

         Vue.set(this.navRouting, 'options', this.options);
         //this.navRouting = Object.assign(this.navRouting);
      }
   },
   methods: {
      //--------------------
      // install the App!
      //--------------------
      installSubmit(setupId)
      {
         this.installApp(setupId);
      },

      readCookie(cname) {
         let name = cname + "=";
         let decodedCookie = decodeURIComponent(document.cookie);
         let carr = decodedCookie.split(';');
         for(let i=0; i<carr.length;i++){
            let c = carr[i];
            while(c.charAt(0)===' '){
               c=c.substring(1);
            }
            if(c.indexOf(name) === 0) {
               return c.substring(name.length, c.length);
            }
         }
         return "";
      },

      categorySelected(id) {
         this.category = parseInt(id, 10);
         console.info('category ' + this.category + ' selected');
         if (document.getElementById('cat' + id))
            window.scrollTo(0, document.getElementById('cat'+id).offsetTop)
      },

      /**
       * Item chosen from item list (and to display in modal)
       * @param item
       */
      selectModalOpen(item)
      {
         this.prepareAttributes(this.$root.ma, this.$root.ga, item);
         console.log('Modal open:' +  JSON.stringify(item));
         // put quantities in this.selectedItem if item has already been selected

         if (this.selection && this.selection.length) {
            const itemFound = this.selection.find(i => i.rid === item.rid);
            //------------------------
            //  selectItem ASSIGN!
            //------------------------
            if (itemFound)
               this.selectedItem = itemFound;
            else
               this.selectedItem = item;
         }
         else
            this.selectedItem = item;

         if (this.selectedItem.hasOwnProperty('total')) {
            this.selectedItemCost = parseInt(this.selectedItem.total.c, 10);
            this.selectedItemQty = parseInt(this.selectedItem.total.q, 10);
            console.info('SelectModalOpen:' + this.selectedItemQty + ':' + this.selectedItemCost);
         }
         else {
            this.selectedItemCost = this.selectedItemQty = 0;
         }
      },

      selectModalClose() {
         console.info('Modal close: searching:' + this.selectedItem.rid);

         // ensure footer nav shown if qty > 0, hide otherwise
         this.doRoutes(this.$route.path, this.total_qty, this.user, this.options);

         let foundIndex = this.selection.findIndex(x => x.rid === this.selectedItem.rid);
         if (-1 === foundIndex) {
            // delete this.selectedItem.image;
            // delete this.selectedItem.ap;
            this.selection.push(this.selectedItem);
         } else {
            if ('sic' in this.selectedItem)
               delete this.selectedItem.sic;
            this.selection[foundIndex] = this.selectedItem;
         }

         // remove any attribute - provisional settings
         this.selectedItem.pam = {};
         this.selectedItem.pae = {};

         if (foundIndex >= 0) {
            delete this.selection[foundIndex].desc;
            delete this.selection[foundIndex].diet;
            delete this.selection[foundIndex].ap;
         }

         console.info('close:selection:' + JSON.stringify(this.selection));
         // store selection in cache
         if (this.selection)
            localStorage.setItem('selection', JSON.stringify(this.selection));

         this.selectedItemQty = 0;
         this.selectedItemCost = 0;
      },

      /**
       * Search each attribute selection combo, if found, inc the quantity for that combo,
       * else create a new attribute selection meta combo (description, qty).
       * All the item's attributes for that product accumulated in item.si.total.
       * @param item
       * @param qty - adjust quantity - typically +1 or -1.
       * @param siKnown - si or null - depending on origin.
       * @returns an object if "attribute selection" match found, null otherwise
       */
      registerItemAmendment(item, qty, siKnown) {
         let found = false;
         let sif = siKnown; //  SI object Found

         let unitCost = this.itemUnitCost(item);
         let iai = this.collectItemAttributeInfo2(item);

         // case 1 - we know the SI object already
         if (siKnown) {
            siKnown.q += qty;
         }
         // case 2 - find the si object if it exists
         else if (item.hasOwnProperty('si') && item.si) {
            for (let n=0; !found && n < item.si.length; n++) {
               // iai.d is attribute description
               console.info('SI(n) <' + item.si[n].d + '> <' + iai.d + '>');
               if (item.si[n].d === iai.d) {
                  item.si[n].q += qty;
                  sif = item.si[n];
                  found = true;           // break OUT of loop!
               }
            }
         }

         // case 3 - it's a new si object
         if (!siKnown && !found)
         {
            if (!item.hasOwnProperty('si') || !item.si)
               item.si = [];

            sif = {
               d: iai.d,           // full Desc
               r: item.rid,
               c: unitCost,        // Cost
               q: (qty>0)?qty:0,   // Qty with -ve => 0
               ma: iai.ma,
               ea: iai.ea
            };
            item.si.push(sif);
         }
         return {'si':item.si,'sic':sif,'total':{'q':this.totalItemQty(item), 'c':this.totalItemCost(item)} };
      },

      totalItemQty(item) {
         let tq = 0;
         if (item.hasOwnProperty('si') && item.si)
         {
            for (let n=0; n < item.si.length; n++)
               tq += parseInt(item.si[n].q);
         }
         return tq;
      },

      totalItemCost(item) {
         let tc = 0;
         if (item.hasOwnProperty('si') && item.si)
         {
            for (let n=0; n < item.si.length; n++)
               tc += parseInt(item.si[n].q) * parseInt(item.si[n].c);
         }
         return tc;
      },

      /**
       * Principal amendment of item, selectItem, total_qty, total_cost.
       * Also, selectedItemQty, selectedItemCost (required to ensure reactivity)
       * @param item
       * @param qty - amendment qty - typically +1 or -1
       * @param siKnown - either null, or the si object for itemAttribute
       */
      amendItemQuantities(item, qty, siKnown) {
         this.selectedItem = item;
         this.selectedItem.ai = item.ai;

         //-------------------------------------------
         //  this is the definitive source of qty
         //-------------------------------------------
         Object.assign(this.selectedItem, this.registerItemAmendment(item, qty, siKnown));

         let si = this.selectedItem.sic;
         item.qty = item.total.q;

         console.info('amend NUM:' + item.rid + ' qty:' + qty + ' total.q:' + item.total.q);
         console.info('amend SI :' + JSON.stringify(this.selectedItem));
         console.info('amend SIC:' + JSON.stringify(si));

         this.total_qty += qty;
         if (this.total_qty < 0)
            this.total_qty = 0;
         else if (qty < 0)
            this.total_cost -= si.c / 100;
         else
            this.total_cost += si.c / 100;

         // these values used to maintain reactivity for modal's qty and cost
         this.selectedItemCost = parseInt(this.selectedItem.total.c, 10);
         this.selectedItemQty = parseInt(this.selectedItem.total.q, 10);
         console.info('itemCost:' + this.selectedItemCost + ' itemQty:' + this.selectedItemQty);

         //-----------------------------------------------------
         // Update selection object - store all items selected
         //-----------------------------------------------------
         if (this.selection && this.selectedItem.rid in this.selection && this.selection.hasOwnProperty('total'))
            this.selection.push(this.selectedItem);

         // housekeeping to catch any items with a qty
         this.selection = this.clearUnusedSelection(this.selection);

         // store selection in cache
         localStorage.setItem('selection', JSON.stringify(this.selection));
      },

      amendItemAttributeQty(item, attr, qty) {
         if (item.hasOwnProperty('total')) {
            item.total.q += qty;
         }
         this.updateSiStats(item, attr, qty);

         if (item.hasOwnProperty('si') && item.si) {
            console.info('AIAQ:si len:' + item.si.length);
            for (let n=0; n < item.si.length; n++) {
               let sie = item.si[n];
               console.info('AIAQ:<' + sie.d + '> ' + JSON.stringify(attr));
               if (attr.d === sie.d) {
                  sie.q += qty;
                  // const aid = parseInt(sie.ma[n], 10);
                  for (let n = 0; n < sie.ma.length; n++) {
                     const aid = parseInt(sie.ma[n], 10);
                     this.updateIaQty(item, aid, qty);
                  }
                  for (let n = 0; n < sie.ea.length; n++) {
                     const aid = parseInt(sie.ea[n], 10);
                     console.info('Matched EA:' + aid);
                     this.updateIaQty(item, aid, qty);
                  }
               }
            }
         }
         else
            console.info('AIAQ: no si elements!');
      },

      updateIaQty(item, aid, qty) {
         for (let [key, attr] of Object.entries(item.ia)) {
            for (let ag=0; ag < item.ia[key].length; ag++)
            {
               let ac = item.ia[key][ag];
               if (parseInt(ac.id, 10) === aid) {
                  if (ac.hasOwnProperty('q')) ac.q += qty;
                  if (ac.hasOwnProperty('s')) ac.s += qty;
                  console.info('Match! UIQ AC:' + JSON.stringify(ac));
               }
            }
         }
      },

      updateSiStats(item, si, qty) {
         if (item.hasOwnProperty('si') && si) {
            si.q += qty;
            // if not qty remains, clear attribute ids
            if (!si.q) {
               si.ma = [];
               si.ea = [];
            }
         }
      },

      /**
       * @notes checkboxesSet will contain the indexes of all checkboxes set.
       * e.g. checkboxesSet => [1] indicates that the 2nd checkbox only is set.
       * @TODO remove?
       */
      updateExtraAttributeSelected(item, attr, idx, checkboxLen, checkboxesSet) {
         // init all attributes to unset
         console.info('UE:' + idx + ':' + 'attr:' + JSON.stringify(attr));

         if (!item.ia.hasOwnProperty(idx))
            return;

         for (let n = 0; n < checkboxLen; ++n)
            item.ia[idx][n].q = 0;
         for (let n = 0; n < checkboxesSet.length; ++n)
            item.ia[idx][checkboxesSet[n]].q = 1;

         this.amendItemAttributeQty(item, attr, checkboxesSet.length ? 1 : -1);
         this.$set(item, 'si');
      },

      /**
       * @notes checkboxesSet will contain the indexes of all checkboxes set.
       * e.g. checkboxesSet => [1] indicates that the 2nd checkbox only is set.
       */
      updateExtraAttributeSelected2(item, attr, idx, checkboxLen, checkboxesSet) {
         // init all attributes to unset
         console.info('prep PAE:' + idx + ':' + 'attr:' + JSON.stringify(attr));

         if (!item.ia.hasOwnProperty(idx))
            return;

         if (!item.hasOwnProperty('pae'))
            item.pae = {};

         for (let n = 0; n < checkboxLen; ++n)
            item.ia[idx][n].q = 0;

         for (let n = 0; n < checkboxesSet.length; ++n) {
            const iao = item.ia[idx][checkboxesSet[n]];
            iao.q = 1;
            item.pae[iao.id] = iao;
         }
         console.info('PAE:' + JSON.stringify(item.pae));
      },

      /**
       * qty will be invariably one as selection is either on or off (1 / 0)
       * @param item
       * @param idx - group id
       * @param idx2 - attr id
       * @param qty
       */
      updateOneOfAttributeSelected(item, idx, idx2, qty) {
         let a = {};
         console.info('prep PAM:' + idx + ':' + idx2 + ':' + qty);
         // clear setting for this attribute (prior to setting new selection)
         for (let n = 0; n < item.ia[idx].length; n++) {
            a = item.ia[idx][idx2];
            if (a.t === 'm')
               a.s = 0;
         }
         item.ia[idx][idx2].s = qty;

         if (!item.hasOwnProperty('pam'))
            item.pam = {};

         item.pam[idx] = a;
         console.info('PAM:' + JSON.stringify(item.pam));
      },

      itemUnitCost(item) {
         return parseInt(item.cost, 10) + this.totalAttrCost(item);
      },

      //  item IA:{"3":[{"id":"21","g":"3","a":"3","t":"e","d":"Marshmallows","c":"40","q":0},
      //  {"id":"22","g":"3","a":"3","t":"e","d":"Whipped Cream","c":"30","q":0}]}
      totalAttrCost(item) {
         let total = 0;
         if (!(item.hasOwnProperty('ia') && item.ia))
            return 0;

         for (let [key, attr] of Object.entries(item.ia)) {
            for (let ag=0; ag < item.ia[key].length; ag++)
            {
               let ac = item.ia[key][ag];
               if ('m' === ac.t && ac.s)
                  total += parseInt(ac.c, 10);
               if ('e' === ac.t && 1 <= ac.q)
                  total += parseInt(ac.c, 10) * parseInt(ac.q, 10);
            }
         }
         console.info('TAC:cost:' + total);
         return total;
      },
      //@TODO remove?
      collectItemAttributeInfo(item) {
         let extras = [];
         let select = [];
         let info = {'ma':[],'ea':[]};
         if (item.hasOwnProperty('ia') && item.ia) {
            for (let [key, attr] of Object.entries(item.ia)) {
               for (let ag=0; ag < item.ia[key].length; ag++)
               {
                  let ac = item.ia[key][ag];
                  console.info('AC:' + JSON.stringify(ac));
                  if ('m' === ac.t && ac.s) {
                     select.push(ac.d);
                     info['ma'].push(parseInt(ac.id, 10));
                  }

                  if ('e' === ac.t && ac.q > 0) {
                     extras.push(ac.d);
                     info['ea'].push(parseInt(ac.id, 10));
                  }
               }
            }
         }

         let description = "";
         if (!select.length && !extras.length)
            description = item.name;
         else
         {
            let first = true;
            let selects = false;

            if (extras.length)
               console.info('Extras:' + JSON.stringify(extras));
            if (select.length)
               console.info('Select:' + JSON.stringify(select));

            select.forEach(function(item)
            {
               selects = true;
               if (first) {
                  first = false;
                  description = item;
               }
               else
                  description += ' ' + item;
            });
            description += ' ' + item.name;
            first = true;
            extras.forEach(function (item)
            {
               if (first) {
                  first = false;
                  description += ' with ' + item;
               }
               else
                  description += ' and ' + item;
            });
         }
         return {'d':description.trim(), ea: info.ea, ma: info.ma }
      },

      collectItemAttributeInfo2(item) {
         let extras = [];
         let select = [];
         let info = {'ma':[],'ea':[]};

         if (item.pam) {
            for (const [key,si] of Object.entries(item.pam)) {
               select.push(si.d);
               info['ma'].push(parseInt(key, 10));
            }
         }

         if (item.pae) {
            for (const [key,si] of Object.entries(item.pae)) {
               extras.push(si.d);
               info['ea'].push(parseInt(key, 10));
            }
         }

         let description = "";
         if (!select.length && !extras.length)
            description = item.name;
         else
         {
            let first = true;
            let selects = false;

            if (extras.length)
               console.info('Extras:' + JSON.stringify(extras));
            if (select.length)
               console.info('Select:' + JSON.stringify(select));

            select.forEach(function(item, idx)
            {
               selects = true;
               if (first) {
                  first = false;
                  description = item;
               }
               else
                  description += ' ' + item;
            });
            description += ' ' + item.name;
            first = true;
            extras.forEach(function (item, idx)
            {
               if (first) {
                  first = false;
                  description += ' with ' + item;
               }
               else
                  description += ' and ' + item;
            });
         }
         return {'d':description.trim(), ea: info.ea, ma: info.ma }
      },

      clearUnusedSelection(selection)
      {
         // keep only selection items with a quantity
         return selection.filter(item => item.qty);
      },

      loadData() {
         // if loading in progress, initialDataLoaded is 0
         if (this.initialDataLoaded === -1) {
            this.getInitialData();
         }
      },

      /**
       * load vendor options array into options object
       * @param options
       */
      loadVendorOptions(options) {
         let obj = {};
         // NOTE - if options increases then increase maxOptions
         const maxOptions = 11;

         if (options.length < maxOptions)
            options.length = maxOptions;

         obj.opening_page = Number(options[0]);
         obj.item_grid = Number(options[1]);
         obj.collection = Number(options[2]);
         obj.use_menu_code = Number(options[3]);
         obj.email_verify = Number(options[4]);
         obj.phone_verify = Number(options[5]);
         obj.mandatory_login = Number(options[6]);
         obj.online_payment = Number(options[7]);
         obj.cash_on_delivery = Number(options[8]);
         obj.card_on_delivery = Number(options[9]);
         obj.default_pay_online = Number(options[10]);

         return obj;
      },

      async installApp(setupID) {
         const service = new InitialDataService(0);
         console.warn('setup id:' + setupID);
         localStorage.removeItem('selection');
         localStorage.removeItem('providr');
         localStorage.removeItem('key');

         service.installApp(this, setupID,
            function (responseObj) {
               if (responseObj.ok)
               {
                  this.loadInitialResponse(service, responseObj);
                  // @TODO if grid resurrected will need if statement here as per getInitialData()
                  this.$router.push({path: '/menu/list'});
               }
               else  {
                  console.warn("installApp: could not load data");
                  this.installError = true;
               }
            });
      },

      async getInitialData() {
         const key = localStorage.getItem('key');
         const service = new InitialDataService(key);
         let auth = localStorage.getItem('auth');
         const store = localStorage.getItem('providr');

         this.initialDataLoaded = 0;
         this.clearModalError();

         //--------------------------------------------------------------------
         // check if any data missing e.g. someone deleting localStore?
         // if so, force full download by removing key before call to /init
         //---------------------------------------------------------------------
         if (!store)
            service.key = 0;

         service.getInitialData(this, this.settings.vendorName, service.key, auth,
            function (responseObj) {
               if (responseObj.ok) {
                  this.loadInitialResponse(service, responseObj);
               }
               else
               {
                  console.warn("Could not retrieve vendor-data");
                  this.networkErrorModal(responseObj, this, this.getInitialData);
                  this.initialDataLoaded = -1;
               }
            });
      },

      loadInitialResponse(service, response) {
         // if key is stale, or key is absent - load from Server
         if ('key' in response.data && service.key !== response.data.key) {
            // load C1 data aka vendor settings, options
            if ('settings' in response.data)
               this.settings = response.data['settings'];

            if ('options' in response.data)
               this.options = this.loadVendorOptions(response.data['options']);

            // load C2 aka menu data from AJAX response
            console.info('Load (menu, attributes, categories) data from server');
            this.items = response.data.menu.item;
            this.categories = response.data.menu.cat;
            this.$root.ma = response.data.menu.ma;
            this.$root.ga = response.data.menu.ga;
            this.$root.ia = response.data.menu.ia;

            localStorage.setItem('key', response.data.key);
            delete response.data.key;
            localStorage.setItem('providr', JSON.stringify(response.data));
         } else {
            const cached = JSON.parse(localStorage.getItem('providr'));
            if (cached) {
               console.info('Load (settings, options, offers, profile) data from CACHE');
               if ('settings' in cached && cached.settings)
                  this.settings = cached.settings;

               if ('options' in cached && cached.options)
                  this.options = this.loadVendorOptions(cached.options);

               if ('profile' in cached && cached.profile) {
                  if (!('guest' in cached.profile) || !cached.profile.guest)
                     this.profileName = cached.profile.custname;
               }
               else
                  this.profileName = "";

               console.info('Load (menu, attributes, categories) from C2 CACHE');
               this.items = cached.menu.item;
               this.categories = cached.menu.cat;
               this.$root.ma = cached.menu.ma;
               this.$root.ga = cached.menu.ga;
               this.$root.ia = cached.menu.ia;
            }
         }

         //-----------------------------------------------
         // User data - currently just profile name
         // this data ALWAYS loaded from Server
         //-----------------------------------------------
         if ('profile' in response.data && response.data.profile) {
            this.loadProfileData(response.data.profile);

            //-----------------------------------------------
            // Check auth
            //-----------------------------------------------
            const auth = localStorage.getItem('auth');
            if (auth) {
               let d = new Date();
               let seconds = Math.round(d.getTime() / 1000);
               let jsonAuth = auth;

               if (jsonAuth && jsonAuth.expires_in >= seconds) {
                  console.info('JSONauth=' + jsonAuth.expires_in);
                  localStorage.removeItem('auth');
                  this.profileName = null;
               }
            }
         }
         else {
            localStorage.removeItem('auth');
            localStorage.removeItem('state');
            this.profileName = null;
         }

         //--------------------------
         // load selected items
         //--------------------------
         let cachedSelection = localStorage.getItem('selection');
         let sel = [];
         if (cachedSelection) {
            sel = JSON.parse(cachedSelection);
         }
         if (sel && sel.length) {
            this.selection = sel;

            let totals = {'q':0,'c':0};
            for (let n=0; n < sel.length; n++) {
               delete sel[n].sic;
               // create an array of RIDs, and search the array for the selection RID
               const idx = this.items.map(item => item.rid).indexOf(sel[n].rid);
               console.info('Found index:' + idx);
               if (-1 !== idx) {
                  if (sel[n].hasOwnProperty('sic'))
                     delete sel[n].sic;
                  Object.assign(this.items[idx], sel[n]);

                  if (sel[n].hasOwnProperty('total')) {
                     totals.q += parseInt(sel[n].total.q, 10);
                     totals.c += parseInt(sel[n].total.c, 10);
                  }
                  console.info('Merged item:' + JSON.stringify(this.items[idx]));
               }
            }
            this.total_qty = totals.q;
            // @TODO - DIV 100
            this.total_cost = totals.c / 100;
         }
         else
            this.selection = [];

         this.selection = this.clearUnusedSelection(this.selection);
         // optional theme, apply theme styles from theme name
         if (this.settings.theme) {
            if ('tony' === this.settings.theme.substring(0, 4))
               this.$root.settings.tonyTheme = true;
            //@TODO temp comment out as not currently using themes
            //LoadCSS.load(this.settings.theme, 'main.css');
         }

         // setup navigation routes
         this.doRoutes(this.$route.path, this.total_qty, this.user, this.options);

         this.$set(this, 'settings', this.settings);
         this.initialDataLoaded = 1;
      },

      /**
       * typically profile is member of response.data (data from server)
       * @param profile
       */
      loadProfileData(profile) {
         this.user = profile;
         if (!('guest' in profile && profile.guest)) {
            // get first word only of custname
            this.profileName = profile.custname.replace(/ .*/, '');
            // adi == id of default address
            this.user.address = this.findDefaultAddress(profile);
            // ADI not found - so just take the first address' ADI
            if (!(('address' in this. user) && this.user.address)) {
               if ('addressList' in profile)
                  this.user.address = profile.addressList[0];
            }
            else
               this.user.adi = this.user.address.adi;
            this.user.loggedIn = true;
            this.user.guest = false;
         }
         else {
            console.warn('Guest:' + JSON.stringify(profile));

            this.user.adi = 0;
            this.user.loggedIn = false;
            this.user.guest = true;
            this.user.address = profile.address;
            this.user.addressList = [];
            this.user.addressList.push(profile.address);
         }

         this.infoComplete = true;
      },

      findDefaultAddress(addressList) {
         // search addressList for default address
         for (let n = 0; n < addressList.length; n++)
         {
            const addr = addressList[n];
            if (addr.def) {
               return addr;
            }
         }
         return null;
      },

      logoutUser() {
         let service = new LoginService();
         const auth = localStorage.getItem('auth');
         service.logout(auth, function (response) {
            console.info('ESP:' + JSON.stringify(response));
            if (response.ok) {
               console.info('User logged out');
            } else { // Error
               console.warn('User logout error');
            }
         });
         this.user = { };
         this.profileName = null;
         this.selection = [];
         this.total_qty = 0;
         this.total_cost = 0;
      },

      setUserData(loggedIn, name) {
         this.user.loggedIn = loggedIn;
         this.user.custname = name;
      },

      getProfileData() {
         const service = new ProfileService();
         service.getProfile(this, function (response) {
            if (response.ok) {
               this.user = Object.assign({}, response.data);
            }
            else { // Error
               if (response.error_code === 'XHR_SEND_ERR' || response.isServerError) {
                  this.$emit('displayNetworkError', response, this, func);
               }
               else if (response.error_code === 'DUPLICATE_EMAIL')
               {
                  EventBus.$emit('duplicate-email', true);
               }
               else
                  this.mainStatus = { category: 'error', msg: response.error }
            }
         }) ;

         if (!this.user) { // Error (everything except connection failure treated as server error)
            console.warn('User is not logged in!');
         }
      },
      userLoggedIn(user, token, from) {
         console.info('userLogin:' + JSON.stringify(user));
         this.user = Object.assign(user,{'loggedIn':true});
         this.loadProfileData(user);
         this.userAuthReceived(token);
         this.infoComplete = true;
         this.$router.push(from);
      },

      onlinePayment(isOnlinePayment) {
        this.$set(this.choices, 'onlinePayment', isOnlinePayment);
        this.$set(this.navRouting, 'onlinePayment', isOnlinePayment);
        this.doRoutes('/summary', this.total_qty, this.user, this.options);
      },

      userLogout() {
         const auth = localStorage.getItem('auth');
         let service = new LoginService({'Authorization': auth});

         service.logout(this, function (response) {
            const resp = response;

            localStorage.removeItem('auth');
            if (resp.ok) {
               console.info('User logged out');
            } else { // Error
               console.warn('User logout error');
            }
         });

         this.user = {};
         this.profileName = null;
         this.selection = [];
         this.selectedItem = {};
         // clear all quantities from items array
         for (let n = 0; n < this.items.length; n++)
         {
            this.items[n].qty = 0;
         }
         this.total_qty = 0;
         this.total_cost = 0;
         this.selectedItemQty = 0;
         this.selectedItemCost = 0;
         localStorage.removeItem('state');
         router.push({path: '/logout'});
      },

   // JWT received
      userAuthReceived(token) {
         console.warn('User auth received:' + token);
         localStorage.setItem('auth', token);
         this.profileName = this.user.profileName;
      },

      infoSubmit(customer) {
         console.info('info-submit event!');
         // merge customer with this.user
         this.user = {...this.user, ...customer};
         console.info('infoSubmit USER:' + JSON.stringify(customer));
      },

      //-------------------------------------------
      // Clear state data
      // e.g. when order posted without error
      //-------------------------------------------
      clearSelection() {
         this.selection = [];
         this.total_qty = 0;
         this.total_cost = 0;
         this.selectedItemQty = 0;
         this.selectedItemCost = 0;
         localStorage.setItem('selection', JSON.stringify([]));
         localStorage.removeItem('state');
      },

      /** ---------------------------
      //  Order completed!
      //----------------------------*/
      orderComplete(orderInf) {
         this.clearSelection();
         console.info('order complete event received');
         localStorage.setItem('order', JSON.stringify(orderInf));
         Vue.set(this.navRouting, 'nextDisabled', false);
         Vue.set(this.navRouting, 'nextEvent', 'reload-menu-page');
       //  this.doRoutes('/completed', this.total_qty, this.user, this.options);
         window.location.replace('/completed');
      },

      orderPayment(response) {
         console.warn('Redirect to : ' + response.url);
         localStorage.setItem('order', JSON.stringify(response.order));
         window.location.replace(response.url);
      },

      /** ----------------------------------
       // Order completed - reload
       // to clear quantities stored in HTML
       //-----------------------------------*/
      reloadMenuPage() {
         console.warn('reload page event');
         let route = '/menu/list';
         if (this.options.grid)
            route = '/menu/grid';
         document.location.href = route;
      },

      formError(email) {
         this.loginFields.email = email;
      },
      fieldUpdate(field, value) {
         this.loginFields[field] = value;
      },
      loginClicked() {
      },

      crumbClick(opt) // JP - 03/02/22 - This may no longer be required
      {
         console.info('Crumb click: ' + opt);
      },
      pageLoadEvent() {
      },

      stripeLoaded(stripe) {
         this.stripe = stripe;
      },

      //-----------------------------------
      // Modal collection
      // @modal Modal MODAL
      //------------------------------------
      networkErrorModal(response, that = null, retryMethod = null) {
         if (response.error_code === 'XHR_SEND_ERR') {
            this.modalSettings.title = 'Network Error';
            this.modalSettings.subtitle = 'Please check your internet connection and try again';
            this.modalSettings.text = response.error;
         }
         else {
            this.modalSettings.title = 'Server Error';
            this.modalSettings.subtitle = 'Please try again in a few moments';
         }
         this.modalSettings.context = that;
         this.modalSettings.retry = retryMethod;
         this.modalSettings.button.retry = true;
         this.modalSettings.button.cancel = true;
         this.modalSettings.header_class = 'error';
         this.modalSettings.container_class = 'error';
         this.modalSettings.button_class = 'error';
         this.showModal = true;
      },

      invalidDataModal() {
         this.modalSettings.title = 'Invalid data';
         this.modalSettings.subtitle = 'Please check the form for errors';
         this.modalSettings.button.cancel = true;
         this.modalSettings.header_class = 'error';
         this.modalSettings.container_class = 'error';
         this.modalSettings.button_class = 'error';
         this.showModal = true;
      },

      orderInvalidModal() {
         this.modalSettings.title = 'Order error';
         this.modalSettings.container_class = 'error';
         this.modalSettings.header_class = 'error';
         this.modalSettings.subtitle = 'Something went wrong, we are looking into the problem';
         this.modalSettings.button.continue = true;
         this.modalSettings.button_class = 'error';
         this.showModal = true;
      },

      clearModalError() {
         this.showModal = false;
      },

      //------------------------------------------
      // Customer information / address handlers
      //------------------------------------------
      setDefault(idx) {
         this.user.adi = idx;
      },

// From AddressList component
      addressListUpdate(addr, idx) {
         // console.log('ALS(3):' + idx + JSON.stringify(addr));
         this.user.adi = idx;
         this.$set(this.user, 'address', addr);
         this.user.new_address = false;   // it's been selected from radio button, so it's existing
      },

      userFormUpdate(field, val) {
         switch (field) {
            case 'custname':
               this.user.custname = val;
               break;
            case 'forename':
               this.user.forename = val;
               break;
            case 'surname':
               this.user.surname = val;
               break;
            case 'email':
               this.user.email = val;
               break;
            case 'phone':
               this.user.phone = val;
               break;
            case 'password':
               this.user.password = val;
               break;
            case 'notes':
               this.user.notes = val;
               break;
            case 'save':
               this.user.save = !!val;
               break;
            default:
               console.warn('UFU(2):case: ' + field + ' not found');
               break;
         }
      },

      addressFormUpdate(field, val) {
         if (!this.user.hasOwnProperty('address'))
            this.user.address =
               {
                  house: null,
                  addr1: null,
                  addr2: null,
                  town: null,
                  postcode: null,
                  org: null,
                  premises: null,
                  pd: null // aka PLM
               };

         switch (field) {
            case 'house':
               this.user.address.house = val;
               break;
            case 'addr1':
               this.user.address.addr1 = val;
               break;
            case 'addr2':
               this.user.address.addr2 = val;
               break;
            case 'town':
               this.user.address.town = val;
               break;
            case 'postcode':
               this.user.address.postcode = val;
               break;
            case 'org':
               this.user.address.org = val;
               break;
            case 'premises':
               this.user.address.premises = val;
               break;
            case 'pd':
               this.user.address.pd = val;
               break;
            default:
               console.warn('AFU:switch case: ' + field + ' not found');
               break;
         }
      },

      addressSelected(addr) {
         this.user.address = Object.assign(this.user.address, addr);
      },
      anotherAddress() {
         for (const prop in this.user.address) {
            if (this.user.address[prop]) {
               this.user.address[prop] = "";
            }
         }
      },

      // store server generated AIT (Addr Info) Token in user object
      userInfoComplete(response) {
         if (response && 'cst' in response) {
            this.user.cst = 1;
            localStorage.setItem('state', JSON.stringify({'cst':1}));
         }
         this.navRouting.nextDisabled = false;
         Vue.set(this.navRouting, 'nextDisabled', false);
         this.infoComplete = true;
      },

      // Address handling methods
      reset(keepHouseAndPostcode) {
         for (const prop in this.user.address) {
            if (!keepHouseAndPostcode || (prop !== 'house' && prop !== 'postcode'))
               this.user.address[prop] = "";
         }
      },
   }
}
</script>

