<script>
const navRoutingMixin = {
   methods: {
      doRoutes(route, qty, user, options)
      {
         console.info('doRoutes for ' + route);
         this.prevRouting(route, options);
         this.nextRouting(route, qty, user, options);
      },
      prevRouting(route, options) {
         this.navRouting.prevRoute = '';
         this.navRouting.prevLabel = '';
         this.navRouting.prevEvent = '';

         if (route === '/information' || route === '/summary' || route === '/login') {
            this.navRouting.prevRoute = '/menu/list';
            if (options.item_grid)
               this.navRouting.prevRoute = '/menu/grid';
            this.navRouting.prevLabel = 'Back to menu';
         }
      },

      nextRouting(route, qty, user, options) {
         this.navRouting.nextRoute = '';
         this.navRouting.nextEvent = '';
         this.navRouting.nextLabel = '';
         this.navRouting.nextDisabled = false;
         this.navRouting.submitButton = false;

         switch (route)
         {
            case '':
            case '/':
            case '/register':
            case '/profile':
            case '/basket':
               this.navRouting.nextLabel = "Choose from menu";
               this.navRouting.nextRoute = '/menu/list';
               if (options.item_grid)
                  this.navRouting.nextRoute = '/menu/grid';
               break;

            case '/completed':
               this.navRouting.nextLabel = "Choose from menu";
               this.navRouting.nextEvent = 'reload-menu-page';
               this.navRouting.submitButton = true;
               break;

            case '/summary':
               if (!(user && 'email' in user && user.email)) {
                  this.navRouting.nextRoute = '/information';
                  this.navRouting.nextLabel = 'Information';
               }
               else if (qty > 0) {
                  this.navRouting.nextEvent = 'process-order';
                  this.navRouting.nextRoute = null;

                  if (this.navRouting.onlinePayment) {
                     this.navRouting.nextLabel = 'Payment';
                     this.navRouting.submitButton = true;
                  }
                  else
                     this.navRouting.nextLabel = 'Place order';
               }
               break;

            case '/information':
               this.navRouting.nextEvent = 'complete-info';
               this.navRouting.nextRoute = null;
               this.navRouting.nextLabel = 'Checkout';
               this.navRouting.submitButton = true;
            // deliberate fall-through

            case '/menu/list':
            case '/menu/grid':
               this.navRouting.nextRoute = '';
               if (qty > 0) {
                  this.navRouting.nextRoute = '/summary';
                  this.navRouting.nextLabel ='Checkout';
               }
               break;

            case '/login':
               this.navRouting.nextEvent = 'handle-form-submission';
               this.navRouting.nextLabel = 'Submit';
               this.navRouting.nextRoute = null;
               this.navRouting.submitButton = true;
               break;

         }
      }
   }
};
export default navRoutingMixin;
</script>