// Service: OrderService

import AjaxUtils from "@/utils/ajaxUtils";
import BaseService from "@/services/baseService";

export default class OrderService extends BaseService {
    constructor(headers) {
        super(headers);
    }

    order(that, formData, callback) {
        let filterFunc = (xhrResponseText, xhrStatus, xhrStatusText) => AjaxUtils.ajaxResponseToObj(that, xhrResponseText, xhrStatus, xhrStatusText, callback);
        this.server.request('POST', '/order', filterFunc, formData);
        this.server.withCredentials();
        this.server.send();
    }
}