import Vue from 'vue'
import App from './App.vue'

import { loadStripe } from '@stripe/stripe-js/pure'; // Pure version (no side effects) - call loadStripe() later to get Stripe instance
Vue.prototype.$loadStripe = loadStripe ;

import customDirectives from './customDirectives' ;
import router from './router';
import User from './user.js';

customDirectives() ;

Vue.config.productionTip = false ;
Vue.prototype.$bus = new Vue();

new Vue({
	router,
	render: h => h(App),
	data: {
		profileName: null,
		user: new User(),
		ia: null,
		ga: null,
		ma: null,
		destination: null,
		baseImage: null
	}
}).$mount('#app');
