// Note: The 'no connection' test mode for XHR Mocks has no effect here due to use of fetch API

<script>

import MenuService from "@/services/menuService";

const selectionMixin = {
  methods: {
    //--------------------------------
    // currently the only AJAX call
    // that does not use ajax.js
    //--------------------------------
    async selectItem(item, qty, callback) {
      let service = new MenuService();
      let selectUrl = service.selectUrl(qty);

      console.info('1 URL:' + selectUrl);
      console.info('1 Item: ' + JSON.stringify(item));
      console.info('1 Item:' + item.rid + ' qty:' + qty + ' token:' + window.csrfToken);

      const func = async function () {
        let status = 0;
        const headers = {};
        await fetch(selectUrl + '/' +
          item.rid + '/' + Math.abs(qty),
          {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: headers
          }).then(response => {
          status = response.status;
          if (!response.ok) {
            // 401 is session expired
            console.info('Response', response.status);
            if (response.status === 401) {
              console.info('Selection expired response');
              this.expired = true;
            }
            throw new Error('HTTP response: ' + response.status);
          }
          window.csrfToken = response.headers.get(process.env.VUE_APP_CSRF_HEADER);
          return response.json();
        })
          .then(data => {
            if (data) {
              console.log('OK fetch response:', JSON.stringify(data));
              callback.apply(this, [item, data.qty, data.cost]);
            } else
              console.warn('BAD itemSelect: no data!');
          })
          .catch((error) => {
            console.error('selectItem: ' + error);
            if (this.expired) {
              console.info('emit: session-expired');
              this.$bus.$emit('modal-close');
              this.$bus.$emit('session-expired');
            } else // Other error (everything except connection failure treated as server error here)
            {
              let response = { 'error': 'NET_ERR' };
              if (status !== 0)
                 response.error = 'SERVER_ERR';
              this.$emit('displayNetworkError', response, this, func);
            }
          });
      }
      if (!this.expired)
        func.call(this);
    },

    selector(item, qty) {
      if (this.$options.grid - item) {
        return this.selectItem(item, qty, function (item, qty, cost) {
          if (!this.expired)
            this.$bus.$emit('items-change', item.rid, qty, cost);
          else
            return 401;
          return 0;
        });
      }
      // else {
      //   // merge group and item attributes for the selected item
      //   console.info('select ' + item.rid + ' from list');
      //   this.$bus.$emit('item-select', item, item.rid, qty);
      // }
    },

    selectedQuantity(qty) {
      if (isNaN(qty)) return null;
      return parseInt(qty, 10);
    },

    imgPlaceholder(e) {
      e.target.className = "error";
      e.target.src = "https://via.placeholder.com/120";
    },

    getDietaryAdvice(item) {
      let diet = [];
      if ('diet' in item && item.diet) {
        const dc = item.diet;
        if (dc & 1)
          diet.push('V'); // Vegetarian
        if (dc & 2)
          diet.push('VV');  // Vegan
        if (dc & 4)
          diet.push('Chilli');
        if (dc & 8)
          diet.push('Milk');
        if (dc & 16)
          diet.push('Gluten');
        if (dc & 32)
          diet.push('Nuts');
        if (dc & 64)
          diet.push('Eggs');
        if (dc & 128)
          diet.push('Sulphites');
        if (dc & 256)
          diet.push('Mustard');
        if (dc & 512)
          diet.push('Alcohol');
      }
      return diet.join(" ");
    }
  }
};
export default selectionMixin;
</script>