<template>
  <div class="form address">
    <div v-if="addressInputMode === 'S'" class="address-select">
        <label v-if="label">{{label}}
          <span v-if="postcodeErrorClass" class="error message">
            {{ premisesErrorMsg }}
          </span>
        </label>

        <div class="select premises is-fullwidth mb-3">
          <select
              :class="premisesErrorClass()"
              @change="$emit('update:addr1Changed', $event)"
          >
            <option v-if="defaultOptionDisplayName !== null" disabled value="" key="default">
              {{defaultOptionDisplayName}}
            </option>
            <option v-for="item in addrMeta.pd"
                    :selected="item.pd.ord === addrMeta.idx"
                    :key=item.pd.ord
                    :value=item.pd.ord>
              {{ item.ad1 }}
            </option>
          </select>
        </div>
    </div>

    <div v-show="addressInputMode === 'F'" class="field">
      <label class="">House number / name</label>
      <div class="control">
        <input class="input" type="text" name="house"
               :class="addrMeta.errors.house?'error':''"
               :value="this.fieldValue('house')"
               @input="$emit('update:address','house', $event.target.value)"
        />
      </div>
    </div>

    <div class="field" v-show="this.showAddressLine1()" >
      <label class="">Address line 1</label>
      <div class="control">
        <input class="input" type="text" name="addr1"
               :value="this.fieldValue('addr1')"
               autocomplete="address-line1"
               @input="$emit('update:address','addr1', $event.target.value)"
        />
      </div>
    </div>

    <div class="field" v-show="this.showAddressLine2()" >
      <label class="">Address line 2</label>
      <div class="control">
        <input class="input" type="text" name="addr2"
               :value="this.fieldValue('addr2')"
               autocomplete="address-line2"
               @input="$emit('update:address','addr2', $event.target.value)"
        />
      </div>
    </div>

    <div class="field" v-show="addressInputMode !== 'F'">
      <label class="">Town</label>
      <div class="control">
        <input class="input" type="text" name="town"
               :value="this.fieldValue('town')"
               autocomplete="address-level1"
               @input="$emit('update:address','town', $event.target.value)"
        />
      </div>
    </div>

    <div class="field">
      <label class=""
             :class="addrMeta.errors.postcode?'error':''"

      >Postcode
         <span v-if="postcodeErrorClass()" class="error message">
            not found
         </span>
      </label>

      <div class="control">
        <input class="input postcode" type="text" name="postcode"
               :value="this.fieldValue('postcode')"
               :class="postcodeErrorClass()"
               autocomplete="postcode"
               @input="$emit('update:address','postcode', $event.target.value)"
               pattern="postCodeRegExp"
        />
      </div>
    </div>

    <section class="pr-section is-2 is-fullwidth">
      <div class="columns is-mobile">
        <div class="column is-2">
          <button v-if="addressInputMode !== 'M'" class="button is-info is-outlined"
                  @click.stop.prevent="$emit('manual', fieldValue('house'),
                    fieldValue('addr1'))"
                  :disabled="addressInputMode === 'M'">
            Enter address manually
          </button>
        </div>
        <div class="column is-2 is-offset-7">
          <button class="button is-info is-outlined"
                  @click="$emit('reset',false)">
            Reset
          </button>
        </div>
      </div>
    </section>

  </div>
</template>
<script>

export default {
  name: "AddressForm",
  props: {
    addrMeta: { idx:-1, pd: null, CpaUsed: null, mode: '', errors: { postcode: null, premises: null} },
    address:  { type: Object, default: null },
    addressInputMode:   { type: String, default:'F' },
    label:              { type: String, default: null },
    defaultOptionDisplayName: { String, default: "-- Please choose an address --"}
  },
  emits : [
    'update:house',
    'update:addr1',
    'update:addr2',
    'update:addr1Changed',
    'update:postcode',
    'reset',
    'manual'
  ],
  computed: {
    premisesErrorMsg: function() {
      if ('errors' in this.addrMeta)
        return this.addrMeta.errors.premises;
      return null;
    }
  },
  methods: {
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    fieldValue(fld) {
      if (this.address && fld in this.address && this.address[fld]) return this.address[fld].toString();
      return "";
    },
    premisesErrorClass() {
      console.info('premises:error:' + JSON.stringify(this.addrMeta));
      if ('errors' in this.addrMeta && this.addrMeta.errors.premises) return 'error'; return null; },

    postcodeErrorClass() { if ('errors' in this.addrMeta && this.addrMeta.errors.postcode) return 'error'; return ''; },

    // Assemble address line1 from address data
    showAddressLine1() {
      if (this.addressInputMode === 'M') return true;

      if (this.addrMeta.idx >= 0)
      {
        return this.addressInputMode === 'S' && this.addrMeta.pd.length &&
            'noCPA' in this.addrMeta.pd[this.addrMeta.idx];
      }
      return false;
    },
    showAddressLine2() {
      if (this.addressInputMode === 'M') return true;
      return !!(this.addressInputMode === 'S' && this.address && this.address['addr2']);
    }
  }
}
</script>