import Settings from "@/settings.js";
import BaseService from "./baseService";
import AjaxUtils from "../utils/ajaxUtils";

export default class MenuService extends BaseService {
    // #selectEndpoint;
    // #deselectEndpoint;
    // #productEndpoint;
    // #basketEndpoint;

    constructor(vid)
    {
        const settings = new Settings();
		super();
				
        this.request = null; // JP - 07/02/22 - Unused?
        this.xhr = null;

        this.selectEndpoint = '/select/item/' + settings.vendorId;
        this.deselectEndpoint = '/deselect/item/' + settings.vendorId;
        this.menuEndpoint = '/menu/list/' + settings.vendorId;
    }

    selectUrl(qty)
    {
        if (qty >= 0)
            return this.baseUrl + this.selectEndpoint;
        else if (qty < 0)
            return this.baseUrl + this.deselectEndpoint;
    }

    menuListRequest(that, callback) {
        const filterFunc = (xhrResponseText, xhrStatus, xhrStatusText) => AjaxUtils.ajaxResponseToObj(that, xhrResponseText, xhrStatus, xhrStatusText, callback);
        this.xhr = this.server.request('GET', this.menuEndpoint, filterFunc);
        this.server.withCredentials();
        this.server.send();
    }
}