// Service: OrderService
import BaseService from "./baseService";
import AjaxUtils from "../utils/ajaxUtils";

export default class InfoService extends BaseService {

    constructor() {
        super();
        // @TODO is this the correct endpoint?
        this.confirmationDataEndpoint = '/customer/confirmation_data';
    }

    customerUpdate(that, formData, auth, callback) {
        // formData.append('action', 'order_info') ;
        this.submitAuthDebug(that, 'POST', '/customer/update', formData, auth, callback);
    }

    guestUpdate(that, formData, callback) {
        this.submitDebug(that, 'POST', '/customer/update', formData, callback);
    }

    //
    // Info form
    // this is for known users (who have a profile and are logged in)
    // updated data: phone, notes, new address
    //
    profileUpdate(that, formData, auth, callback) {
        this.submitAuthDebug(that, 'POST', '/profile/InfoUpdate', formData, auth, callback);
    }

    getOrderConfirmationData(that, callback) {
        this.submitDebug('GET', that, this.confirmationDataEndpoint, callback);
    }
}
