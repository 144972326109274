// Service: ProfileService
import BaseService from "./baseService";
import AjaxUtils from "../utils/ajaxUtils";

export default class ProfileService extends BaseService {

	constructor() {
		super();
		this.registerEndpoint = '/register';
		this.profileEndpoint = '/login';
		this.profileEditEndpoint = '/profile';
		this.profileUniqueEndpoint = '/profile/unique';
		this.registerInitEndpoint = '/register/initialise';
	}

	register(that, formData, callback) {
		let filterFunc = (xhrResponseText, xhrStatus, xhrStatusText) => AjaxUtils.ajaxResponseToObj(that, xhrResponseText, xhrStatus, xhrStatusText, callback);
		this.server.request('POST', this.registerEndpoint, filterFunc, formData);
		this.server.withCredentials();
		this.server.send();
	}

	register_init(that, formData, callback) {
		let filterFunc = (xhrResponseText, xhrStatus, xhrStatusText) => AjaxUtils.ajaxResponseToObj(that, xhrResponseText, xhrStatus, xhrStatusText, callback);
		this.server.request('GET', this.registerInitEndpoint, filterFunc, formData);
		this.server.withCredentials();
		this.server.send();
	}

	/**
	 * Strictly this should be GET request, using POST to hide the email sent in request.
	 * @param that
	 * @param formData
	 * @param callback
	 */
	uniqueEmailCheck(that, formData, callback) {
		let filterFunc = (xhrResponseText, xhrStatus, xhrStatusText) => AjaxUtils.ajaxResponseToObj(that, xhrResponseText, xhrStatus, xhrStatusText, callback);
		this.server.request('POST', this.profileUniqueEndpoint, filterFunc, formData);
		this.server.withCredentials();
		this.server.send();
	}

	editProfileUpdate(that, formData, callback) {
		let filterFunc = (xhrResponseText, xhrStatus, xhrStatusText) => AjaxUtils.ajaxResponseToObj(that, xhrResponseText, xhrStatus, xhrStatusText, callback);
		this.server.request('POST', this.profileEditEndpoint, filterFunc, formData);
		this.server.withCredentials();
		this.server.send();
	}

	getProfile(that, callback) {
		let filterFunc = (xhrResponseText, xhrStatus, xhrStatusText) => AjaxUtils.ajaxResponseToObj(that, xhrResponseText, xhrStatus, xhrStatusText, callback);
		this.server.request('GET', this.profileEndpoint, filterFunc);
		this.server.withCredentials();
		this.server.send() ;
	}
}