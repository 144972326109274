// Service: BaseService
import Ajax from "@/ajax.js";
import AjaxUtils from "@/utils/ajaxUtils";

export default class BaseService {

    constructor(headers, baseUrl) {
        this.baseUrl = baseUrl || process.env.VUE_APP_SERVER;
        this.server = new Ajax(this.baseUrl, headers)
    }

    submit(method, endpoint, callback) {
        this.server.request(method, endpoint, callback, null);
        this.server.withCredentials();
        this.server.send();
    }

    submitAuth(method, endpoint, auth, callback) {
        this.server.request(method, endpoint, callback, null);
        this.server.withCredentials();
        this.server.header('Authorization', auth);
        this.server.send();
    }

    submitForm(method, endpoint, callback, formData) {
        this.server.request(method, endpoint, callback, formData);
        this.server.withCredentials();
        this.server.send();
    }

    submitDebug(that, method, endpoint, formData, callback) {
        const filterFunc = (xhrResponseText, xhrStatus, xhrStatusText) => AjaxUtils.ajaxResponseToObj(that, xhrResponseText, xhrStatus, xhrStatusText, callback);
        this.server.request(method, endpoint, filterFunc, formData);
        this.server.withCredentials();
        this.server.send();
    }

    submitAuthDebug(that, method, endpoint, formData, auth, callback) {
        const filterFunc = (xhrResponseText, xhrStatus, xhrStatusText) => AjaxUtils.ajaxResponseToObj(that, xhrResponseText, xhrStatus, xhrStatusText, callback);
        this.server.request(method, endpoint, filterFunc, formData);
        this.server.withCredentials();
        this.server.header('Authorization', auth);
        this.server.send();
    }

    header(name) {
        return this.server.readHeader(name);
    }

    baseUrl() {
        return this.baseUrl;
    }
}