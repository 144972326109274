<template>
<div class="page-vendor-opening">
	<section class="container is-fluid" > <!-- Pre-load image hiding, will have to be removed if unreliable -->

		<!-- Banner and caption text (both optional) -->
		<h1 class="subtitle is-3">{{this.settings['name']}}</h1>
		<div class="columns">

			<div v-if="option('banner_caption')" class="column is-two-thirds vendor-banner-text" >
				<p>{{option('banner_caption')}}</p>

        <div class="op-info">
          <p><span class="op-info">Phone:</span>{{ this.settings['phone'] }}</p>
          <p><span class="op-info">{{ openingDays1 }}</span>{{ openingHours1 }}</p>
          <p><span class="op-info">{{ openingDays2 }}</span>{{ openingHours2 }}</p>
        </div>
			</div>

			<div v-if="settings.banner_image_url" class="column is-one-third vendor-banner">
				<figure>
					<img  class="vendor-banner-img"
								:src="imageURL + option('banner_image_url')"
								:alt="option('banner_caption')"
								@load="onImgLoad" @error="onImgErr" data-testid="bannerImage">
				</figure>
			</div>
		</div>
	</section>

	<!-- Offers / Categories -->
		<section v-show="offerData" class="offers">
			<h1 class="subtitle is-4 mt-2 centre-block">{{option('offers_heading') || "Special offers"}}</h1>
			<div class="grid">
				<article :class="offerClass(idx)" v-for="(item,idx) in offerData" :key="idx">
					<div v-if="item.offer_url" class="offer">
						<a :href="item.offer_url">
							<figure :class="layoutClass(item)">
								<img	:src="imageURL + item.offer_image" :alt="item.offer_text"
											@load="onImgLoad" @error="onImgErr" :data-testid="'offerImage' + idx">
								<figcaption class="is-info">{{item.offer_text}}</figcaption>
							</figure>
						</a>
					</div>
					<div class="offer" v-else>
						<figure :class="layoutClass(item)">
							<img :src="imageURL + item.offer_image" :alt="item.offer_text"
									 @load="onImgLoad" @error="onImgErr" :data-testid="'offerImage' + idx">
							<figcaption class="is-info">{{item.offer_text}}</figcaption>
						</figure>
					</div>
				</article>
			</div>
		</section>

	<div v-if="serverError !== null" class="has-text-danger is-size-4 has-text-weight-semibold">
		{{serverError}}
	</div>

   <nav-footer
      :navRouting="navRouting"
      ></nav-footer>

	</div>
</template>
<script>

import OpeningPageService from '../services/openingPageService.js' ;
import NavFooter from "@/components/NavFooter";
import Settings from "../settings.js";

export default {
	name: 'OpeningPage',
   components: {
      NavFooter
   },
	props: {
		settings: { type: Settings, default: () => ( new Settings() ) }, // For vendor id, various vendor options
	   navRouting: Object
   },
	data: function() {
		return {
			svc: new OpeningPageService(),
			bannerData: undefined,
			offerData: undefined,
			serverError: null,
			numImagesRemain: 0,
         nextRoute: '/menu/list',

      phone: null,
      openingDays1: null,
      openingDays2: null,
      openingHours1: null,
      openingHours2: null
		}
	},
	beforeMount() {
		//this.getPageData() ;
    //console.info('Offer1:' + JSON.stringify(this.settings));
    this.offerData = this.settings['offers'];
    if ('grid' in this.settings && this.settings.grid)
       this.nextRoute = '/menu/grid';

  // @TODO Opening Times - should be from DB!
    // this.phone = '01574 110330'
    this.openingDays1 = 'Mon to Fri:'
    this.openingDays2 = 'Sat:';
    this.openingHours1 = '7am to 5pm';
    this.openingHours2 = '8am to 4pm';
	},
  created() {
  },
	computed: {
		imageURL: function() {
			return process.env.VUE_APP_SERVER + '/images/vendor/' + this.option('image_folder') + '/';
		},
	},
	methods: {
    option: function(name) {
      return this.settings[name];
    },
		offerClass: function(idx) {
			return 'offer' + (idx + 1);
		},
		layoutClass: function(item) {
      if (typeof item.image_layout === 'undefined')
        return 'image';
			return parseInt(item.image_layout, 10) > 0 ? 'image': 'icon';
		},
		onImgLoad() {
      this.onImgComplete();
		},
		onImgErr() {
      this.onImgComplete();
		},
    onImgComplete() {
      this.numImagesRemain-- ;
      if (!this.numImagesRemain)
        this.$emit('pageLoadComplete');
      // console.log("Image error - remains: " + this.numImagesRemain) ;
    }
	}
}
</script>
