<template>
  <div class="breadcrumb">
  <section v-if="!this.settings.tonyTheme">
    <nav class="breadcrumb has-arrow-separator my-4"
         aria-label="breadcrumbs"
         :class="showCrumbsClass"
    >
      <ul>
        <!-- bulma is-active only disables inner links, not the li itself -->
        <li @click="crumbClick('menu')" :class="optionClass('menu')" >
          <router-link to="/menu/list">
            Menu
          </router-link>
        </li>
<!--        <li v-show=this.qty @click="crumbClick('basket')" :class="optionClass('basket')">-->
<!--          <router-link to="/basket">-->
<!--            Basket-->
<!--          </router-link>-->
<!--        </li>-->
        <li v-show=this.qty @click="crumbClick('information')" :class="optionClass('information')">
          <router-link to="/information">
            Info
          </router-link>
        </li>

        <li v-show="this.qty && this.infoComplete" @click="crumbClick('summary')" :class="optionClass('summary')">
          <router-link to="/summary">
            Summary
          </router-link>
        </li>
        <li v-show="this.qty && this.infoComplete" @click="crumbClick('payment')" :class="optionClass('payment')">
          <router-link to="/payment">
            Payment
          </router-link>
        </li>
      </ul>
    </nav>
  </section>
  <section v-else-if="this.qty">
    <nav class="crumb my-1" aria-label="breadcrumbs">
      <ul>
        <li @click="crumbClick('menu')" :class="optionClass('menu')" >
          <router-link to="/menu/list"></router-link>
        </li>
        <li v-show=this.qty @click="crumbClick('basket')" :class="optionClass('basket')">
          <router-link to="/basket"></router-link>
        </li>
        <li v-show=this.qty @click="crumbClick('information')" :class="optionClass('information')">
          <router-link to="/information"></router-link>
        </li>
        <div v-if="this.infoComplete">
          <li v-show=this.qty @click="crumbClick('summary')" :class="optionClass('summary')">
            <router-link to="/summary"></router-link>
          </li>
          <li v-show=this.qty @click="crumbClick('payment')" :class="optionClass('payment')">
            <router-link to="/payment"></router-link>
          </li>
        </div>
      </ul>
    </nav>
    <nav class="crumb-text" aria-label="breadcrumbs">
      <ul>
        <li @click="crumbClick('menu')" :class="optionClass('menu')" >
          <router-link to="/menu/list">
            Menu
          </router-link>
        </li>
        <li v-show=this.qty @click="crumbClick('basket')" :class="optionClass('basket')">
          <router-link to="/basket">
            Basket
          </router-link>
        </li>
        <li v-show=this.qty @click="crumbClick('information')" :class="optionClass('information')">
          <router-link to="/information">
            Info
          </router-link>
        </li>
        <div v-if="this.infoComplete">
          <li v-show=this.qty @click="crumbClick('summary')" :class="optionClass('summary')">
            <router-link to="/summary">
              Summary
            </router-link>
          </li>
          <li v-show=this.qty @click="crumbClick('payment')" :class="optionClass('payment')">
            <router-link to="/payment">
              Payment
            </router-link>
          </li>
        </div>
      </ul>
    </nav>
  </section>
  <section v-else-if="activeOption === 'select'">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li class="info">Click on an image to select</li>
      </ul>
    </nav>
  </section>
  </div>
</template>

<script>
import Settings from "@/settings";

export default {
	name: "NavCrumbs",
	props: {
		qty: {
			required: true,
			default: 0
		},
		activeOption: {
			required: true,
      default: null
		},
    infoComplete: {
      required: true,
      default: false
    },
    settings: {
      type: Object, default: () => ( new Settings() ),
    }
	},
	methods: {
		crumbClick(optionName) {
			this.$emit('crumb-click', optionName);
		},
		optionClass(opt) {
			return (this.activeOption === opt) ? 'disable-link' : null;
		},
    showCrumbsClass() {
      if (this.qty) return null;
      return 'is-hidden';
    }
	}
}
</script>