<template>
   <section v-if="navRouting.nextLabel || navRouting.prevLabel">
      <div class="clear-footer"></div>
      <footer>
         <button v-if="isPrev" class="button is-outlined sub-nav" @click.stop="clickPrevButton()">
            {{ navRouting.prevLabel }}
         </button>
         <button v-if="isNextDisabled" disabled class="button is-primary main-nav" @click.stop="clickNextButton()">
            {{ navRouting.nextLabel }}
         </button>
         <button v-else class="button is-submit main-nav" :class="isSubmit" @click.stop="clickNextButton()">
            {{ navRouting.nextLabel }}
         </button>
      </footer>
   </section>

</template>
<script>

export default {
   name: "NavFooter",
   props: {
      navRouting: Object
   },
   computed: {
      isPrev() {
         return this.navRouting.prevEvent || this.navRouting.prevRoute;
      },
      isNextDisabled() {
         if ('nextDisabled' in this.navRouting && this.navRouting.nextDisabled)
            return 'disabled';
         return null;
      },
      isSubmit() {
         return this.navRouting.submitButton ? "is-submit" : "";
      }
   },
   watch: {
      navRouting: {
         immediate:true,
         deep:true,
         handler(newVal, oldVal) {
           // console.warn('NEW:' + JSON.stringify(newVal));
         }
      }
   },
   methods: {
      clickPrevButton() {
         if (this.navRouting.prevEvent)
            this.$emit(this.navRouting.prevEvent.handler);
         else if (this.navRouting.prevRoute)
            this.$router.push(this.navRouting.prevRoute);
      },
      clickNextButton() {
         if (this.navRouting.nextEvent)
            this.$emit(this.navRouting.nextEvent);
         else if (this.navRouting.nextRoute) {
            this.$router.push(this.navRouting.nextRoute);
         }

      }
   }
}
</script>