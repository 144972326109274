<template>
   <div class="container summary is-fluid">
      <h1 class="subtitle summary is-3">Summary</h1>
      <div v-if="total_qty <= 0">
            <span class="notice info large">No items selected</span>
      </div>
      <div v-else class="columns is-tablet summary">

         <div v-if="this.user.email" class="column is-half">
            <article>
               <h2>Your details</h2>
               <div>
                  <span v-if="user.phone" class="alt-label">Phone:</span>
                  <span class="data">{{ user.phone }}</span>
               </div>

               <div>
                  <span v-if="user.addressList.length" class="alt-label">Deliver to:</span>
                  <span class="data">
                  {{ addressString(user.address) }}
                </span>
               </div>

               <div>
                  <span v-if="user.notes" class="alt-label">Notes:</span>
                  <span class="data">{{ user.notes }}</span>
               </div>

               <section class="">
                  <router-link to="/information">
                     <button class="button is-outlined mt-5">Change</button>
                  </router-link>
               </section>
            </article>

            <article class="pr-section is-2">
               <h2 class="">Payment <span class="notice info">{{ formattedAmount(total_cost)}} to pay</span></h2>
               <div v-if="isMultipleOptions">
                  <label v-if="options.online_payment" class="label radio" type="radio">
                     <span v-if="options.default_pay_online">
                        <input @change="onlinePaymentSelected(1)" type="radio" checked name="user-address">
                        Pay now
                     </span>
                     <span v-else>
                        <input @change="onlinePaymentSelected(1)" type="radio" name="user-address">
                        Pay now
                     </span>
                  </label>
                  <label v-if="options.cash_on_delivery || options.card_on_delivery" class="label radio" type="radio">
                     <span v-if="!options.default_pay_online">
                        <input @change="onlinePaymentSelected(0)" type="radio" checked name="user-address">
                        Pay on delivery
                     </span>
                     <span v-else>
                        <input @change="onlinePaymentSelected(0)" type="radio" name="user-address">
                        Pay on delivery
                     </span>

                  </label>
               </div>
            </article>
         </div>
         <div v-else class="column is-half">
            <h2>No delivery info</h2>
            <router-link to="/information">
               <button class="button is-primary ml mt">
                 Add information
               </button>
            </router-link>
         </div>

            <div v-if="total_qty > 0" class="column">
               <h2>Your items</h2>
               <order-basket
                  :selection="this.selection"
                  :total_qty="total_qty"
                  :total_cost="total_cost"
                  :image_base="image_base"
                  :navRouting="this.navRouting"
                  :from_summary=true
               ></order-basket>
            </div>
         <div v-else>
            <span class="notice info large">No items selected</span>
         </div>

         </div>

<!--      <button @click.stop="processOrder"-->
<!--              :class="isPlaceOrder()"-->
<!--              class="button is-primary is-fullwidth">-->
<!--         {{ this.primaryButtonText }}-->
<!--      </button>-->

      <nav-footer
         :navRouting="this.navRouting"
         @process-order="processOrder"
         ></nav-footer>

   </div>
</template>

<script>
import OrderBasket from "@/views/OrderBasket";
import NavFooter from "@/components/NavFooter";
import currencyMixin from "@/mixins/Currency";
import OrderService from "@/services/orderService";

export default {
   name: "OrderSummary",
   components: { OrderBasket, NavFooter },
   mixins: [ currencyMixin ],

   props: {
      user: { type: Object },
      selection: { type: Array },
      options: { type: Object },
      choices: { type: Object },
      total_qty: { type: Number },
      total_cost:{ type: Number },
      image_base: String,
      navRouting: { type: Object }
   },

   watch: {
      total_qty(newest, old) {
         console.log('OS Watch: from:' + old + ' to: ' + newest);
         // if (from.name === 'Summary' && to.name === 'OrderInfoManager')
         //    this.infoComplete = true;
      }
   },

   data: function() {
      return {
         settings: Object
       }
   },

   created() {
      this.settings.onlinePayment = this.options.default_pay_online;
      // @TODO handle collection - for now just hard code to NO collection
      this.settings.collection = 0;
   },

   methods: {
      processOrder() {
         console.warn('process-order event');
         let self = this;
         let auth = localStorage.getItem('auth');
         let service = new OrderService({'authorization':auth});
         let data = new FormData();
         let uzer = Object.assign({}, this.user);
         // don't need addressList for the submission
         if ('addressList' in uzer)
            delete uzer.addressList;
         console.warn('OPTIONS:' + JSON.stringify(this.settings));
         data.append('items', JSON.stringify(this.selection));
         data.append('key', localStorage.getItem('key'));
         data.append('user', JSON.stringify(uzer));
         data.append('options',  JSON.stringify(this.choices));
         service.order(this, data, function(response) {
            if (response.ok) {
               if (!self.choices.onlinePayment) {
                  console.warn('Order:' + JSON.stringify(response.data.order));
                  self.$emit('order-complete', response.data.order);
               }
               else {
                  // process payment
                  self.$emit('order-payment', response.data);

               }
            }
            else {
               if (!self.choices.onlinePayment) {
                  console.warn('Order submission failed!');
                  self.$emit('order-invalid');
               }
               else
               {
                  console.warn('Redirect to gateway');
               }
            }
         });
      },

      addressString(addr) {
         if (!addr)
            return null;

         let as = '';
         if ('premisesList' in addr && 'o' in addr.premisesList && addr.premisesList.o)
            as += addr.premisesList.o + ', ';
         else if ('org' in addr && addr.org)
            as += addr.org + ', ';
         if ('premisesList' in addr && 'p' in addr.premisesList && addr.premisesList.p)
            as += addr.premisesList.p + ', ';
         else if ('prem' in addr && addr.prem)
            as += addr.prem + ', ';
         if ('premisesList' in addr && 'n' in addr.premisesList && addr.premisesList.n)
            as += addr.premisesList.n + ' ' + addr.addr1;
         else if (addr.house)
            as += addr.house + ' ' + addr.addr1;
         else
            as += ' ' + addr.addr1;

         if (addr.addr2)
            as += ', ' + addr.addr2;
         if (addr.town)
            as += ', ' + addr.town;
         if (addr.postcode)
            as += ', ' + addr.postcode;
         return as;
      },

      /**
       * @notes click radio event to choose payment mode
       * @param option 1 or 0 - online OR payment on delivery
       */
      onlinePaymentSelected(option) {
         console.info('Payment option:' + option);
         this.$emit('online-payment', option);
      },
   },
   computed: {
      isMultipleOptions() {
         let count = 0;
         if (this.options.online_payment)
            count++;
         if (this.options.card_on_delivery)
            count++;
         if (this.options.cash_on_delivery)
            count++;
         return count > 1;
      },
   },
   mounted() {
      if (!this.total_qty)
         this.$bus.$emit('session-expired');
   }
}
</script>