<script>
const currencyMixin = {
   methods: {
      costTotal(q, c) {
         const qty = parseInt(q);
         const cost = parseFloat(c);
         return qty * cost;
      },

      formattedAmount(amt, currency) {
         const zero = 0;
         const euro = '€';
         const sterling = '£';
         const dollar = '$';
         let amountString = null;
         let amount = 0;

         if (!currency) currency = 'GBP';
         if (!Number.isNaN(amt))
            amount = (Math.round(amt * 100) / 100).toFixed(2);
         else
            amount = zero.toFixed(2);

         switch (currency.toUpperCase()) {
            case 'GBP':	amountString = sterling + amount; break;
            case 'EUR': amountString = euro + amount; break;
            case 'USD': amountString = dollar + amount; break;
         }
         return amountString;
      },

      formattedAmountPence(amtPence, currency) {
         return this.formattedAmount(amtPence / 100, currency);
      },

      getCost(qty, cost) {
         cost = parseFloat(cost);
         return Math.round((cost * qty) * 100) / 100;
      },

      formattedCurrency(amt) {
         return this.formattedAmount(amt);
      },
   }
};
export default currencyMixin;
</script>

