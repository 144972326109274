import Vue from 'vue'
import VueRouter from 'vue-router'

import SelectionGrid from '@/views/SelectionGrid';
import SelectionList from '@/views/SelectionList';
import OrderBasket from '@/views/OrderBasket';
import UserRegister from "@/views/UserRegister";
import UserLogin from "@/views/UserLogin";
import UserProfile from "@/views/UserProfile";
import OpeningPage from "@/views/OpeningPage";
import OrderInfoManager from "@/views/OrderInfoManager";
import OrderSummary from "@/views/OrderSummary";
// import OrderPayment from "@/views/OrderPayment";
import OrderPaymentResult from "@/views/OrderPaymentResult";
import Logout from "@/views/UserLogout";
import AppInstall from "@/views/AppInstall";
import OrderComplete from "@/views/OrderComplete";
import PaymentForm from "@/components/PaymentForm";

Vue.use(VueRouter);

const routes = [
	{
		path: '/menu/list/:vendorId?',
		name: 'SelectionList',
		component: SelectionList,
		props: true
	},
	{
		path: '/menu/grid/:vendorId?',
		name: 'SelectionGrid',
		component: SelectionGrid,
		props: true
	},
	{
		path: '/basket',
		name: 'OrderBasket',
		component: OrderBasket,
		props: true
	},
	{
		path: '/logout',
		name: 'Logout',
		component: Logout
	},
	{
		path: '/completed',
		name: 'OrderComplete',
		component: OrderComplete,
		props: true
	},
	{
		path: '/login',
		name: 'UserLogin',
		component: UserLogin
	},
	{
		path: '/register',
		name: 'Register',
		component: UserRegister
	},
	{
		path: '/install',
		name: 'AppInstall',
		component: AppInstall
	},
	{
		path: '/profile',
		name: 'UserProfile',
		component: UserProfile
	},
	{
		path: '/information',
		name: 'OrderInfoManager',
		component: OrderInfoManager
	},
	{
		path: '/summary',
		name: 'OrderSummary',
		key: "$route.fullPath",
		component: OrderSummary
	},
	{
		path: '/payment',
		name: 'OrderPayment',
		component: PaymentForm
	},
	{
		path: '/paymentResult',
		name: 'PaymentResult',
		component: OrderPaymentResult,
		props: true
	},
	{
		path: '/:vendorId?',
		name: 'OpeningPage',
		component: OpeningPage,
		props: false
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;
