
export class Ajax {
	constructor(base, headers)
	{
		this.base = base || null;
		this.headers = headers || {'X-Requested-With': 'XMLHttpRequest'};
		this.headers = headers || [];

		this.url = null;
		this.method = null;
		this.data = null;
		this.xhr = null;
	}

	requestObject() {
		this.xhr = new XMLHttpRequest();
		if (!this.xhr) {
			console.error('XMLHttpRequest instantiation error');
		}
		return this.xhr;
	}

	readHeader(headerName) {
		return this.xhr.getResponseHeader(headerName);
	}

	withCredentials() { this.xhr.withCredentials = true; }
	header(name, value) {
		this.xhr.setRequestHeader(name, value);
	}

	request(method, url, callback, data, that, timeout) {
		const xhr = this.requestObject();
		if (!xhr)
			return null;

		// (Warns also if not all-caps as per spec)
		if (!['GET', 'POST', 'PUT', 'DELETE', 'PATCH'].includes(method))
			console.warn(`WARNING: Unknown method: '${method}'`);

		this.data = data || null;
		this.method = method;

		if (this.base)
			this.url = this.base + url;

		//console.info('BAS: ' + this.base);
		//console.info('URL: ' + this.url);
		if (timeout)
			xhr.timeout = timeout;

		xhr.onreadystatechange = function () {
			if (xhr.readyState === XMLHttpRequest.DONE) {
				const csrfToken = xhr.getResponseHeader(process.env.VUE_APP_CSRF_HEADER);
				//const auth = xhr.getResponseHeader('authorization');
				if (csrfToken) console.info("CSRF token received: " + csrfToken);

				const status = parseInt(xhr.status, 10);
				const success = (status >= 200 && status < 300) ; // (1xx and 3xx also considered to be errors, 0 = Network timeout/error (including CORS))
				if (success)
					console.info(`[RESPONSE]:${xhr.status}:${xhr.statusText}:${xhr.responseText}`);
				else
					console.error(`[RESPONSE]:${xhr.status}:${xhr.statusText}:${xhr.responseText}`);

				if (callback) {
					callback.call(that, xhr.response, xhr.status, xhr.statusText);
				}
			}
		}.bind(this);

		xhr.onerror = function() {
			console.error(`[XHR onerror called] status = ${xhr.status} : '${xhr.statusText}'`);
		};

		xhr.open(this.method, this.url, true);

		this.xhr = xhr;
		return this.xhr;
	}

	send() {
		if (this.method === 'GET') console.info('[REQUEST] ' + this.url + ':' + this.method) ;
		else console.info('[REQUEST] ' + this.url + ':' + this.method + ' |', this.data);

		if (this.method.toUpperCase() === 'GET' && this.data !== null)
			console.warn("WARNING: 'GET' method with non-empty body (ignored by XMLHttpRequest)") ;

		// Add CSRF token (if any) to non-GET requests
		if (this.method !== 'GET' && window.csrfToken && process.env.VUE_APP_CSRF_HEADER) {
			this.xhr.setRequestHeader(process.env.VUE_APP_CSRF_HEADER, window.csrfToken);
			console.info("CSRF token transmit: " + window.csrfToken) ;
		}
		
		let hdr = 'headers';
		if (hdr in this) {
			for (let key in this.headers) {
				if (Object.prototype.hasOwnProperty.call(this.headers, key))
				{
					console.info('AJAX Header: ' + key + ':', this.headers[key]);
					this.xhr.setRequestHeader(key, this.headers[key]);
				}
			}
		}
		this.xhr.send(this.data);
	}

}
export default Ajax;
