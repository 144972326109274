/* jshint esversion: 9 */

// Utils: Ajax

const errors = {
	0:     ["XHR_SEND_ERR", "Network error"],
	400:   ["NOT_FOUND", "Not Found"],	// Postcode endpoint
	401:   ['SESSION_EXPIRED', 'Session expired'],
	404:   ["NOT_FOUND", "Not Found"],	// Standard HTTP response for resource-not-found
	410:   ["NOT_FOUND", "Not Found"]		// Order customer-info and order-confirmation details endpoints
} ;

const nonJSONIsError = true ;

export default class AjaxUtils {
	// Generic AJAX response filter to convert to an object (with error codes and display strings)
	static ajaxResponseToObj(that, xhrResponseText, xhrStatus, xhrStatusText, callback) {
		
		// Attempt to parse the response text as JSON
		let responseObj ;
		let parseSuccess = false ;
		try {
			if (null === xhrResponseText || 'null' === xhrResponseText)
				throw('No response text');
			responseObj = { data: JSON.parse(xhrResponseText) } ; // Set 'data' to object if parse succeeds
			parseSuccess = true ;
		}
		catch(err) {
			console.warn('caught responseToObj:' + err);
			responseObj = {};
			if (xhrResponseText && xhrResponseText !== 'null')
				responseObj = { data: xhrResponseText } ; // Set 'data' to text if parse fails
		}

		// - Set error code / display string -
		if (parseSuccess) { // Explicit error values take priority
			responseObj['error_code'] = responseObj.data['error_code'] ;
			responseObj['error'] = responseObj.data['error'] ;
		}
		const httpSuccess = (xhrStatus >= 200 && xhrStatus < 300) ; // (1xx and 3xx also considered to be errors)
		if (errors[xhrStatus]) { // Known HTTP error
			responseObj['error_code'] = (responseObj['error_code'] !== undefined) ? responseObj['error_code'] : errors[xhrStatus][0] ;
			responseObj['error'] = (responseObj['error'] !== undefined) ? responseObj['error'] : errors[xhrStatus][1] ;
		}
		else if (!httpSuccess) { // Unknown HTTP error
			responseObj['error_code'] = (responseObj['error_code'] !== undefined) ? responseObj['error_code'] : xhrStatus ;
			responseObj['error'] = (responseObj['error'] !== undefined) ? responseObj['error'] : `${xhrStatus} ${xhrStatusText}` ;
		}
		else if (nonJSONIsError && !parseSuccess) { // JSON parse error
			responseObj['error_code'] = 'JSON_PARSE_FAIL' ;
			responseObj['error'] = 'Invalid JSON in response' ;
		}
		else if (responseObj['error_code'] !== undefined || responseObj['error'] !== undefined) { // Fill in other error field if only one is specified
			responseObj['error_code'] = (responseObj['error_code'] !== undefined) ? responseObj['error_code'] : 'UNKNOWN_ERR' ;
			responseObj['error'] = (responseObj['error'] !== undefined) ? responseObj['error'] : 'Unknown Error' ;
		}

		// Success booleans
		responseObj['ok'] = (responseObj['error_code'] === undefined && responseObj['error'] === undefined) ;
		responseObj['isServerError'] = (xhrStatus >= 500 && xhrStatus < 600) ;

		// Remove the original error fields to avoid possible confusion
		if (parseSuccess) {
			delete responseObj.data['error_code'] ;
			delete responseObj.data['error'] ;
		}

		// Return response object (with error fields) and the HTTP status fields to the callback
		let xhrStatusData = {'xhrStatus': xhrStatus, 'xhrStatusText': xhrStatusText} ;
		if (callback)
			callback.call(that, responseObj, xhrStatusData) ;
		else
			return { ...responseObj, ...xhrStatusData};
	}
}