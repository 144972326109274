<template>
   <slot-modal
      :show=show
      :item="item"
      :closeButton=true
      :container-class="selectionClass"
      @close="close"
   >
      <template #header>
         <figure v-if="item.image" class="select-item image is-4by3">
            <img @error="imgPlaceholder" :src="image_base + item.image" alt="No image">
         </figure>
         <figure class="no-image" v-else>
         </figure>
         <h2 class="title modality is-5" :class="hasAttributesClass(item)">
            <span v-if="item.code"> {{ item.code }}</span>
            {{ item.name }}
         </h2>


      </template>

      <template #body>
         <section class="select-header">

            <aside>
               <span class="cost">{{ formattedAmountPence(item.cost) }}</span>
               <span v-if="selectedItemQty" class="info-parent control">
                  <span class="info qty">{{ selectedItemQty }}</span>
                  <span class="info amount">{{ formattedAmountPence(selectedItemCost) }}</span>
               </span>
            </aside>

            <p>{{ item.desc }}
               <!-- use !'group' in item to test if no attributes -->
               <!--          <span v-if="!'group' in item" class="hyphen" >       -->
               <!--             <span class="hyphen" >-->
               <!--               {{ formattedAmountPence(item.cost) }}-->
               <!--             </span>-->
            </p>
         </section>

         <section v-if="item.diet && item.diet.length" class="attributes">
          <span v-for="(diet, index) in item.diet" :key="index" >
            <span v-if="'V' === diet || 'VV' === diet" class='vg'>
              {{ diet }}
            </span>
            <span v-else>
              {{ diet }}
            </span>
          </span>
         </section>

         <template v-if="'ia' in item && Object.keys(item.ia).length > 0">
            <section class="attr"
                     :class="oneOfAttributeMissing(attr) ? 'error' : ''"
                     v-for="(attr, idx) in item.ia" :key="idx"
            >
               <h6 :id="'attr'  + idx" >
                  {{ $root.ma[idx] }}
                  <!-- Use oneOfAttributeMissing() to check if any attribute selection missing -->
                  <span v-if="oneOfAttributeMissing(attr)">MISSING</span>
               </h6>

               <div class="" v-for="(attr2, idx2) in item.ia[idx]" :key="idx2">
                  <!-- "Extra" attributes so use checkboxes -->
                  <template v-if="'e' === item.ia[idx][idx2].t">
                     <label class="label" :class="firstOfClass(idx2)">
                        <span class="attr-desc">{{ item.ia[idx][idx2].d }}</span>
                        {{ formattedAmountPence(item.ia[idx][idx2].c) }}

                        <span>
                  <input type="checkbox"
                         v-model="extraCheckValues"
                         :value="idx2"
                         :name="idx2"
                         @change.stop="extraAttrSelected(item.ia[idx], idx, idx2, extraCheckValues, item.ia[idx].length)">
                </span>

                     </label>
                  </template>

                  <!-- "Mandatory" attributes so use radio, and enforce selection -->
                  <template v-else>
                     <label class="label" :class="firstOfClass(idx2)">
                        <span class="attr-desc">{{ item.ia[idx][idx2].d }}</span>
                        {{ formattedAmountPence(item.ia[idx][idx2].c) }}

                        <span>
                  <input type="radio" :class="'m_type' + idx" :name="$root.ma[idx]"
                         @change="oneOfAttributeSelected(item.ia[idx], idx, idx2, +1)">
              </span>

                     </label>
                  </template>
               </div>
            </section>
         </template>

         <section class="control">
            <button class="button is-large flex minus"
                    :class="(selectedItemQty)?'active':'none'"
                    @click.prevent.stop="amendBasket(item, -1)"
            >
               <img :src="minus" alt="less"  />
            </button>

            <button class="button is-large flex plus"
                    :class="(!selectedItemQty)?'full-active':''"
                    @click.prevent.stop="amendBasket(item, 1)"
            >
               <img :src="plus" alt="more" />
            </button>

         </section>

         <section class="item-selected-list" v-for="(attr, idx) in item.si" :key="idx">
            <div v-if="hasSelectedItemsWithAttributes(item, attr, idx)" class="info-parent info-list">
               <span class="description">{{ attr.d }}</span>
               <span class="info qty">{{ attr.q }}</span>
               <!--              <span class="info">{{ formattedAmountPence(selectedItemCost) }}</span>-->
               <button class="button flex minus active control"
                       @click.prevent.stop="removeItemAttrGroup(attr)"
               >
                  <img :src="cancel" alt="cancel selection"  />
               </button>
            </div>
         </section>
      </template>

   </slot-modal>
</template>

<script>
import SlotModal from "@/components/SlotModal";
import selectionMixin from "@/mixins/Selection";
import currencyMixin from "@/mixins/Currency";
import plusSVG from "@/assets/image/plus.svg";
import minusSVG from "@/assets/image/minus.svg";
import cancelSVG from "@/assets/image/cancel.svg";

export default {
   name: "SelectItemModal",
   components: {
      SlotModal
   },
   mixins: [ selectionMixin, currencyMixin ],
   data() {
      return {
         plus: plusSVG,
         minus: minusSVG,
         cancel: cancelSVG,
         itemAttributes: Object,
         attrSelected: {},
         itemSelected: [],
         selectionClass: "item-select",
         attributeMissing: true,
         itemSelectionMade: false,
         extraCheckValues: []
      }
   },
   props: {
      show: Boolean,
      image_base: null,
      item: Object,
      selectedItemQty: Number,
      selectedItemCost: Number,
      attributes: Array
   },
   watch:{
      deep: true,
      immediate:true,
      item(val, oldVal) {
      }
   },
   mounted() {
      this.initAttributes();
      if ('si' in this.item)
         console.info('modal AI:' + JSON.stringify(this.item.si))
   },

   methods: {
      close() {
         this.$emit('modal-close');
      },

      hasSelectedItemsWithAttributes(item, si, idx) {
         if (si && (si.hasOwnProperty('ma') && si.ma.length) || (si.hasOwnProperty('ea') && si.ea.length))
         {
            // item attribute must have qty - if set to 0 then don't show
            if (item.hasOwnProperty('total') && item.total.q)
               return true;
         }
         console.info('HAS_SIWA:false!');
         return false;
      },

      /**
       * Si is short for Selected Information - yuk!
       * @param item
       * @param desc
       * @returns {null}
       */
      getItemAttributeSi(item, desc) {
         if (item && item.hasOwnProperty('si') && item.si.length > 0) {
            for (let n=0; n < item.si.length; n++) {
               const i = item.si[n];
               if (i.d === desc)
                  return i;
            }
         }
         return null;
      },

      hasAttributesClass(item) {
         if ('ia' in item) return 'attr stats';
         return 'stats';
      },

      firstOfClass(idx) {
         if (!idx) return 'first';
         return '';
      },

      amendBasket(item, qty) {
         console.info('amendBasket: ' + item.rid + ' qty:' + qty);
         this.itemSelectionMade = true;
         // check that any mandatory (type m) aka "oneOf" attributes have been selected.
         if (this.checkAllOneOfAttrSelected()) {
            this.$bus.$emit('amend-item-select', item, qty, null);
         }
         else
            console.log('no attributes found');
      },

      removeItemAttrGroup(itemAttr) {
         this.itemSelectionMade = false;
         this.$bus.$emit('item-attr-remove', itemAttr);
      },

      /**
       * @notes checkboxesSet will contain the indexes of all checkboxes set.
       * e.g. checkboxesSet => [1] indicates that the 2nd checkbox only is set.
       */
      extraAttrSelected(i, idx, idx2, checkboxesSet, checkboxLen) {
         let attr = i[idx2];
         this.itemAttributes[attr.id] = {
            id: attr.id,
            idx: idx2,
            g: idx,
            a: attr.a,
            c: attr.c,
            d: attr.d,
            t: attr.t
         };

         console.info('checkBox:' + idx + ':' + idx2 + ':' + attr.id + ':len:' +
            checkboxLen + ':' + JSON.stringify(checkboxesSet));
         this.$bus.$emit('attr-e-select', this.item, attr, idx, checkboxLen, checkboxesSet);
      },

      /**
       * iterate all attributes in 'ia' and if 'one of'
       * attribute, initialise the attrSelected property.
       * @return object with attribute no as key, attribute as value.
       * @item {"cid":"7","ga":null,"name":"Espresso Glace","desc":"Espresso coffee crowned with Ice Cream","cost":"440","image":"","diet":"","ia":{"1":[{"id":"5","a":"1","t":"m","d":"Small","c":"0"},{"id":"6","a":"1","t":"m","d":"Medium","c":"40"},{"id":"7","a":"1","t":"m","d":"Large","c":"80"}],"4":[{"id":"8","a":"4","t":"m","d":"Vanilla","c":"0"},{"id":"9","a":"4","t":"m","d":"Strawberry","c":"0"},{"id":"10","a":"4","t":"m","d":"Pistachio","c":"0"}]},"rid":"5da19f29","ap":true,"na":false}
       */
      initAttributes() {
         if (this.item && 'ia' in this.item)
         {
            for (let [key, attr] of Object.entries(this.item.ia)) {
               for (let ag=0; ag < this.item.ia[key].length; ag++)
               {
                  let ac = this.item.ia[key][ag];
                  if (ac.t === 'm')
                     this.$set(this.attrSelected, key, false);
               }
            }
            return this.item.ia;
         }
         return null;
      },

      oneOfAttributeSelected(i, idx, idx2, qty) {
         let attr = i[idx2];
         this.itemAttributes[attr.id] = {
            id: attr.id,
            idx: idx2,
            g: idx,
            a: attr.a,
            c: attr.c,
            d: attr.d,
            t: attr.t,
            s: 1
         };
         this.attrSelected[idx] = true;
         this.$set(this.attrSelected, idx, true);
         // this.$bus.$emit('attr-m-select', this.item, this.itemAttributes[attr.id], idx, idx2, qty);
         this.$bus.$emit('attr-m-select', this.item, idx, idx2, qty);
         console.info('ATTR:' + idx + ' selected' + JSON.stringify(this.attrSelected));

         if (this.checkAllOneOfAttrSelected()) {
            console.info('Ok to proceed: group:' + idx + ' ' + idx2 + ' ' + attr.id);
            console.info('Attr selection:' + JSON.stringify(this.itemAttributes[attr.id]));
            this.$bus.$emit('attr-complete', this.item, this.itemAttributes[attr.id]);
         }
      },

      oneOfAttributeMissing(attr) {
         // attribute missing check only done once an item has a plus click
         if (!this.itemSelectionMade)
            return false;

         let mandatory = false;
         // only 'm' type attributes are mandatory (hence 'm')
         for (let n = 0; n < attr.length; n++)
         {
            const a = attr[n];
            // console.info('Attr missing?:' + JSON.stringify(attr[n]));
            if (a.t === 'm') {
               mandatory = true;
               if (a.s) return false;
            }
         }
         // missing if mandatory attributes AND no quantity found
         return mandatory;
      },

      checkAllOneOfAttrSelected() {
         for (let [key, attr] of Object.entries(this.attrSelected)) {
            if (!this.attrSelected[key]) {
               this.attributeMissing = true;
               return false;
            }
         }
         return true;
      },

      imgPlaceholder(e) {
         e.target.className = "error";
         e.target.src = "https://via.placeholder.com/120";
      },
   }
}
</script>
