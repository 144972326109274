<template>
  <section class="pr-section is-4">
      <div class="message">
        <div class="logout message-header is-info py-5 mx-3">
          <router-link to="/" class="vertical-align">
            <img :src="logoImage" class="logo-text" alt="Providr logo" />
          </router-link>

          <h1 class="subtitle is-2">Providr</h1>
        </div>

        <div class="message-body is-info centre logout">
          You are now logged out
        </div>
      </div>

        <router-link class="main grid" to="/menu/list">
           <button class="button is-primary">
              <img :src="require('@/assets/image/menu.svg')"
                   alt="choose from menu"
              />
              Choose from menu
           </button>
        </router-link>
  </section>
</template>

<script>
import LoginService from "@/services/loginService";

export default {
  name: "UserLogout",
  data: function() {
    return {
      logoImage: process.env.VUE_APP_SERVER + '/images/logo_b.png'
    }
  }
}
</script>