// Service: PostcodeService
import BaseService from "./baseService";
import AjaxUtils from "../utils/ajaxUtils";

export default class PostcodeService extends BaseService {

	// #postcodeEndpoint;

  constructor() {
	super();
    this.postcodeEndpoint = '/postcode';
  }

  postcodeRequestNew(that, postcode, callback) {
    this.server.request('GET', this.postcodeEndpoint + '/' + postcode, callback);
    this.server.withCredentials();
    this.server.send();
  }
  postcodeRequest(that, postcode, callback) {
    const filterFunc = (xhrResponseText, xhrStatus, xhrStatusText) => AjaxUtils.ajaxResponseToObj(that, xhrResponseText, xhrStatus, xhrStatusText, callback);
    this.server.request('GET', this.postcodeEndpoint + '/' + postcode, filterFunc);
    this.server.withCredentials();
    this.server.send();
  }
}
