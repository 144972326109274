<template>
   <section class="container">
      <nav v-show="this.active" class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
         <a v-if="this.settings.logo" href="/" class="logo">
            <img :src="this.imageBase + this.settings.logo" v-if="this.showLogo" class="logo-text" :alt="this.settings.name + ' logo'" />
         </a>
         <a v-else-if="this.showLogo" href="/" class="logo">
            <img :src="logoImage" v-if="this.showLogo" class="logo-text" alt="Providr logo" />
         </a>

         <div v-if="this.$route.name=='SelectionList' || this.$route.name=='SelectionGrid'" class="navbar-mobile">
            <div v-if="categories && categories.length" class="select is-control">
               <select class="is-secondary" @change="categorySelected($event)" >
                  <option v-for="cat in categories"
                          :key="cat.id"
                          :value="cat.id"
                  >
                     {{ cat.name }}
                  </option>
               </select>
            </div>
         </div>

         <div class="navbar-content stats">
               <div v-if="this.qty" class="control core">
                  <span class="info">{{ selectedQuantity(qty) }}</span>
                  <span class="info qty">{{ formattedCurrency(cost) }}</span>
               </div>
         </div>

         <div class="navbar-content">
               <prime-button
                  :is_active="true"
                  :is_grid="this.isGrid"
                  :option="this.primeButton"
               >
               </prime-button>
         </div>

         <div class="navbar-content">
            <router-link v-if="profileName" to="/profile" class="profile button">
               <img class="profile" :src="require('@/assets/image/profile_ok.svg')" alt="user" />
               <span class="profileName">{{ profileName }}</span>
            </router-link>

            <div class="buttons login">
               <div v-if="!profileName">
                  <router-link :from="returnPage" to="/login" class="button is-small">
                     <img
                        :src="require('@/assets/image/login.svg')" alt="sign in" />
                     Sign in
                  </router-link>
               </div>
            </div>
         </div>

         <div class="navbar-menu" @click="navbarMenuClick($event, 'none')">
            <div class="menu">
               <div class="menu-bar"></div>
               <div class="menu-bar"></div>
               <div class="menu-bar"></div>
            </div>
         </div>
      </nav>

      <section id="menu-click-section" class="menuOptions none">
         <div v-if="profileName" class="menu-click-items">
            <router-link to="/profile" class="button is-primary is-fullwidth">
               <img :src="require('@/assets/image/profile.svg')" alt="user profile" />
               Your Profile
            </router-link>

         </div>
         <div v-else class="menu-click-items">
            <router-link v-if="isGrid" to="/menu/grid" class="button is-primary is-fullwidth">
               Menu
            </router-link>
            <router-link v-else to="/menu/list" class="button is-primary is-fullwidth">
               Menu
            </router-link>
            <router-link to="/login" class="button is-primary is-fullwidth">
               Login
            </router-link>
            <router-link to="/register" class="button is-primary is-fullwidth">
               Register
            </router-link>
         </div>
      </section>
   </section>
</template>
<script>

import currencyMixin from '@/mixins/Currency';
import selectionMixin from "@/mixins/Selection";
import PrimeButton from "@/components/PrimeButton";

export default {
   name: "Navbar",
   components: {PrimeButton},
   mixins: [ currencyMixin, selectionMixin ],
   props: {
      qty: {
         'required': true,
         'default': 0
      },
      cost: {
         'required': true,
         'default': 0
      },
      isGrid:        { type: Boolean, default: false },
      isProduction:  { type:Boolean, default:false },
      active:        { type:Boolean, default:false },
      categories:    { type: Array, default: null },
      profileName:   { type:String, default:null },
      imageBase:     { type:String, default: null },
      settings:      { type:Object, default: null },
      primeButton:   { type:String, default: null },
      option:        { type:String, default: null }
   },
   data: function() {
      return {
         logoImage: process.env.VUE_APP_SERVER + '/images/logo_b.png',
         userIcon:  process.env.VUE_APP_SERVER + '/images/system_user.png',
      }
   },
   computed: {
      showLogo() { return this.option !== 'logout'; },
      returnPage() {
         if (this.$route.path !== '/logout')
            return this.$route.path;
         return '/menu/list';
      }
   },
   methods: {
      navbarMenuClick(event, menuClass) {
         let menu = event.target;
         if (menu.classList.contains('menu-bar'))
            menu = menu.parentNode;

         let menuOptions = document.getElementById('menu-click-section');
         if (menu && !menu.classList.contains('is-active')) {
            menu.classList.add('is-active');
            if (menuOptions)
               menuOptions.classList.remove(menuClass);
         } else {
            if (menu)
               menu.classList.remove('is-active');
            if (menuOptions)
               menuOptions.classList.add(menuClass);
         }
      },

      categorySelected(e) {
         this.$emit('category-selected', e.target.value);
      },

      logout() {
         this.$emit('user-logout');
      }
   },


   mounted() { // From https://bulma.io/documentation/components/navbar/
      const navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
      if (navbarBurgers.length > 0) {
         navbarBurgers.forEach( el => {
            el.addEventListener('click', () => {
               const target = el.dataset.target;
               const targetElem = document.getElementById(target);
               el.classList.toggle('is-active');
               targetElem.classList.toggle('is-active');
            });
         });
      }
   },

}
</script>
