<template>
   <div class="page-user-profile">
      <UserProfileManager v-if="user !== undefined"
                          title="Edit profile"
                          submitButtonName="Update"
                          :user="user"
                          :addressList="user.addressList"
                          @remove="remove"
                          @setDefault="setDefault"
                          @user-logout="$emit('user-logout')"
                          @submit="handleFormSubmission">
      </UserProfileManager>

      <nav-footer
         :nav-routing="this.navRouting"
         >

      </nav-footer>
   </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import User from '@/user.js';
import UserProfileManager from "@/components/UserProfileManager";
import ProfileService from '@/services/profileService' ;
import FormUtils from '@/utils/formUtils' ;
import Settings from "@/settings";
import NavFooter from "@/components/NavFooter";

export default {
   name: 'UserProfile',
   components: {
      NavFooter,
      UserProfileManager
   },
   props: {
      user: { type: User, required: true },
      settings: { type: Settings, default: () => ( new Settings() ) },
      navRouting: Object
   },
   data: function() {
      let fieldsConfig = { forename: false, surname: true, phone: false, saveDetailsByDefault: true } ;
      if (this.settings.phone) fieldsConfig['phone'] = (this.settings.phone === true) ;

      return {
         mainStatus: undefined,
         emailError: undefined,
         fieldsConfig: fieldsConfig,
         lockedFields: [],
         saveConfig:   { showSaveDetailsToggle: false, showSaveAddressToggle: false },
         saveOptions: { }, // Not used
         svc: new ProfileService()
      }
   },
   beforeMount() {
      console.info('UP userProfile:' + JSON.stringify(this.user));

      // Not logged in - redirect to login page
      if (!this.user.loggedIn)
         this.$router.push({path:'/login'}) ;

      else if (!this.user.email) {
         // Retrieve user profile data if logged in but no user record
         this.$emit('user-login');
      }
   },
   methods: {
      remove(idx) {
         console.info('Removing index:' + idx);
         this.addressList.splice(idx, 1);
      },
      setDefault(idx) {
         this.$emit('setDefault', idx);
      },
      handleFormSubmission: function(formDataObj, addressList) {
         // Create FormData
         delete formDataObj.address ; // All addresses are in addressList
         const data = FormUtils.objToFormFields(formDataObj) ;
         data.append('addressList', JSON.stringify(addressList)) ;

         let svc = new ProfileService();
         const func = function() {
            svc.editProfileUpdate(this, data, function(response) {
               if (response.ok) {
                  this.mainStatus = { category: 'success_banner', msg: 'Profile Updated'} ;
               }
               else { // Error
                  if (response.error_code === 'XHR_SEND_ERR' || response.isServerError) {
                     let errorType = (response.error_code === 'XHR_SEND_ERR') ? 'NET_ERR' : 'SERVER_ERR' ;
                     let error = (response.error_code === 'XHR_SEND_ERR') ? '' : response.error ;
                     this.$emit('displayNetworkError', this, func, errorType, error) ;
                  }
                  else this.mainStatus = { category: 'error', msg: response.error }
               }
            });
         }
         func.call(this) ;
      }
   }
}
</script>