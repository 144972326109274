<template>
   <div class="sidebar-and-content">
      <sidebar
         :categories="categories"
         :category="category"
         @category-select="categorySelected"
      ></sidebar>
      <section class="menu-list">
<!--         <div v-if="categories && categories.length" class="select is-control">-->
<!--            <select class="select is-medium"-->
<!--                    @change="categorySelected($event)"-->
<!--            >-->
<!--               <option value="0">All categories</option>-->
<!--               <option v-for="cat in categories"-->
<!--                       :key="cat.id"-->
<!--                       :value="cat.id"-->
<!--               >-->
<!--                  {{ cat.name }}-->
<!--               </option>-->

<!--            </select>-->
<!--         </div>-->

         <section>
            <div v-for="item in items" :key="item.id" @click="modalOpen(item)">

<!--               <div v-if="(category===0) && isNewCategory(item.cid) && typeof categories[item.cid-1] !== 'undefined'">-->
<!--                  <h2 :id="'cat'+item.cid" class="cat-title title is-3">{{ categories[item.cid-1].name }}</h2>-->
<!--               </div>-->
               <article v-if="category===0 || (item.cid == category)">
                  <section class="item">
                     <section class="item-info">
                        <aside>
                           <h1 class="subtitle stats is-4">{{ item.name }}
                              <span v-if="item.qty" class="info">{{ item.qty }}</span>
                           </h1>
                           <p class="item-description">{{ item.desc }}</p>
                        </aside>

                        <div v-if="item.diet && item.diet.length" class="attributes">
                         <span v-for="(diet, index) in item.diet" :key="index" >
                           <span v-if="diet === 'V' || diet === 'VV'" class='vg'>
                             {{ getDietaryAdvice(item) }}
                           </span>
                           <span v-else>
                              {{ getDietaryAdvice(item) }}
                           </span>
                         </span>
                        </div>

                        <p class="cost">{{ formattedAmountPence(item.cost) }}</p>
                     </section>

                     <section class="item-image" v-if="item.image">
                        <img @error="imgPlaceholder" class="image" :src="image_base + item.image" :alt="item.name" />
                     </section>
                  </section>
               </article>
            </div>
         </section>

         <section v-if="showModal">
            <select-item-modal
               :show="showModal"
               :image_base="image_base"
               :item="selectedItem"
               :selectedItemQty="selectedItemQty"
               :selectedItemCost="selectedItemCost"
               :attributes=attributes
               @modal-close="modalClose"
            >
            </select-item-modal>
         </section>


      </section>
      <nav-footer
         :navRouting="navRouting"
         >
      </nav-footer>
   </div>

</template>

<script>

import selectionMixin from "@/mixins/Selection";
import currencyMixin from "@/mixins/Currency"

import SelectItemModal from "@/views/SelectItemModal";
import Sidebar from "@/components/Sidebar"
import NavFooter from "@/components/NavFooter";

// outside of component state in v-if loop, otherwise
// get "infinite update loop" warning
let gCategory = -1;

export default {
  name: "SelectionList",
  components: { SelectItemModal, Sidebar, NavFooter },
  mixins: [ selectionMixin, currencyMixin ],
  props: {
    selection: [],
    items: [],
    selectedItem: {},
    selectedItemQty: Number,
    selectedItemCost: Number,
    total_qty: {
      default:0
    },
    attributes: [],
    categories: Array,
     category: Number,
    image_base: null,
     navRouting: Object
  },
  data() {
    return {
      imageMissing:false,
      showModal:false,
      nextRoute: '/summary'
    }
  },
  created() {
    let self = this;
    gCategory = -1;
    this.$bus.$on('modal-close', function() {
      console.warn('Received close modal event!');
      self.modalClose();
    });
  },
   beforeMount() {
     const state = JSON.parse(localStorage.getItem('state'));
     this.nextRoute = '/summary';
     if (!localStorage.getItem('auth'))
     {
        // only direct to /info if no user details
        // already submitted.
        if (!(state && 'cst' in state && state.cst))
           this.nextRoute = '/information';
     }
   },

  methods: {
    // isNewCategory(cat) {
    //   if (gCategory !== cat) {
    //     gCategory = cat;
    //     return true;
    //   }
    //   return false;
    // },
    modalOpen(item) {
      this.showModal = true;
      // now used for both grid and list modes
      this.$emit('select-modal-open', item);
    },
    modalClose() {
      this.showModal = false;
      this.$emit('select-modal-close');
    },
    categorySelected(id) {
      this.$emit('category-select', id);
    },
  },

  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  }
}
</script>
