// Service: AddressFinderService
import PostcodeService from "../services/postcodeService";
import PostalAddress from '../utils/postalAddress'

export default class AddressFinderService {
	request(postcode, premisesNameOrNum, callback) {
		this.callback = callback;
		let svc = new PostcodeService();
		svc.postcodeRequest(this, PostalAddress.normalisePostcode(postcode), function(responseObj) {
			let addrMeta = {
				errors: { premises: null, postcode: null },
				idx: undefined,
				pd: undefined,
				CpaUsed: true  // Combined Premises Addr used, which is default (e.g. 1 Bridge St)
			}
			let addrRecord = {};
			let premisesData = [] ;

			if (responseObj.ok) {
				const responseData = responseObj.data ;
				// street is static, addr1 may be combined with house number
				// Ignore response if the postcode was changed before the request completed
				if (!PostalAddress.postcodesMatch(postcode, responseData.postcode)) return ;

				[addrRecord.addr1, addrRecord.addr2, addrRecord.town,
					addrRecord.postcode, addrRecord.ad1, addrRecord.pd] =
					[
						responseData.addr1,
						responseData.addr2,
						responseData.town,
						responseData.postcode,
						null,
						null
					];

				// Build premises identifier (name, premises, house) array
				let premisesList = responseData.premisesList.map(obj => {
					let str = "" ;
					if (obj.o !== undefined) str += obj.o ;
					if (obj.p !== undefined) str += ((str === "") ? "" : ", ") + obj.p ;
					if (obj.n !== undefined) str += ((str === "") ? "" : ", ") + obj.n ;
					return str ;
				}) ;

				for (const [n, obj] of responseData.premisesList.entries()) {
					let premisesID = premisesList[n] ;
					// noCPA == no Combined Premises Addr1 flag, combine if there is a premises num
					const noCPA = !(obj.n !== undefined && PostalAddress.isPremisesNumber(obj.n));
					if (noCPA)
						premisesData[n] = { pd: obj, noCPA: true, ad1: `${premisesID}` };
					else {
						premisesData[n] = { pd: obj, ad1: `${premisesID} ${addrRecord.addr1}` };
						addrMeta.CpaUsed = true;
					}
					premisesData[n].pd.ord = n;
				}
				addrMeta.pd = premisesData;

				// Perform a fuzzy search through the premises list if premises name/number was given
				if (premisesNameOrNum) {
					addrMeta.idx = PostalAddress.findFuzzyUniqueMatch(premisesData, premisesNameOrNum) ;
					if (addrMeta.idx < 0)
						// Otherwise, set premises error message
						addrMeta.errors.premises =
							"\"" + premisesNameOrNum + ' ' + addrRecord.addr1 + "\" not found" ;
				}

				// Select premises if it's the only one in this postcode
				// OR no precise match found, in which case assume PD is first in list.
				if (premisesList.length === 1 || 0 > addrRecord.idx) {
					addrRecord.idx = 0;
					addrRecord.pd = premisesData[0].pd;
					addrRecord.o = premisesData[0].pd.o;
					addrRecord.ad1 = premisesData[0].pd.ad1;
				}
			}
			else
				addrMeta.errors.postcode = responseObj.error ; // Error (most likely postcode not found)
			this.callback(addrRecord, addrMeta);
		});
	}
}