<template>
   <div>
      <article v-if="'ref' in orderInfo" class="completed container is-fluid">
         <h2>Your Order has been placed</h2>
         <h3>Order reference: {{ orderInfo.ref }}</h3>
         <p>
            Time: {{ orderInfo.order_time }}
         </p>
         <p>
            Date: {{ orderInfo.order_date }}
         </p>
         <p class="final">
            Delivery time: <b>{{ orderInfo.delivery_time }} minutes approx.</b>
         </p>

         <aside v-if="orderInfo.paid">
            <h3>Payment received</h3>
            <p class="final">
               Payment of {{ orderCurrency }}{{ orderInfo.total.toFixed(2) }}
               has been made securely with card ending
               {{ orderInfo.last4 }}
            </p>
         </aside>
         <aside v-else>
            <h3>Payment</h3>
            <p>
               Payment is due - please be ready to pay our courier <b class="larger">{{ orderCurrency }}{{ orderInfo.total.toFixed(2) }}</b>.
            </p>
         </aside>
         <p>
         <p class="final">Order confirmation has also been sent to your email address.</p>
         <p class="final thanks">
            Thank you for ordering with <b>{{ orderInfo.vendor }}</b>!
         </p>
      </article>
      <article v-else class="completed container is-fluid">
         <h2>No order placed yet</h2>
         <button class="button is-primary" @click="menuRoute()">
            Order from menu
         </button>
      </article>

      <nav-footer v-if="this.navRouting"
                  :navRouting="this.navRouting"
                  @reload-menu-page="menuRoute"
      ></nav-footer>
   </div>
</template>

<script>

import NavFooter from "@/components/NavFooter";
export default {
   name: "OrderComplete",
   components: {NavFooter},
   props: {
      navRouting: { type: Object }
   },
   data: function() {
      return {
         orderInfo: Object
      }
   },

   created() {
      let o = localStorage.getItem('order');
      this.orderInfo = {};
      if (typeof o !== 'undefined' && o) {
         console.info('IO:' + JSON.stringify(o));
        this.orderInfo = JSON.parse(o);
      }
   },

   computed: {
      orderCurrency() {
         if (!this.orderInfo.currency || this.orderInfo.currency === 'GPB')
            return '£';
         return '';
      }
   },
   methods: {
      menuRoute() {
         console.info('Reload event received');
         this.$emit('reload-menu-page');
      }
   }
}
</script>