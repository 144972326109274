/**
 * @notes Core user details
 * @property name - user name
 * @property loggedIn
 */
class User
{
	constructor(name, loggedIn)
	{
		this.name = name;
		this.loggedIn = loggedIn || false;

		this.forename = null;
		this.surname = null;
		this.email = null;
		this.phone = null;
		this.notes = "";
		this.password = null;

		this.profileName = null;
		this.auth = null;

		// address
		this.address = {};
		this.addressList = [];
	}
}

export default User;