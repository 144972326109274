// Utils: PostalAddress

export default class PostalAddress {

    // Simple UK postcode validation regexp constant (allows spacing either side)
    // Case-insensitive match on "X(X)D(?)( )DXX" (where X is [A-Z], D is any digit, and ? is either)
    static ukPostcodeRegEx() {
        // Adapted from https://stackoverflow.com/questions/164979/regex-for-matching-uk-postcodes
        return "^\\s*[a-zA-Z]{1,2}\\d[a-zA-Z\\d]? ?\\d[a-zA-Z]{2}\\s*$" ;
    }

    static validPostcode(postcode) {
        const regex = new RegExp(PostalAddress.ukPostcodeRegEx());
        return regex.test(postcode);
    }

    // Check if identifier looks like a premises # such as '1', '11, 'A1' or '11A'
    static isPremisesNumber(str) {
        return /^[a-z]?[0-9]{1,3}[a-z]?$/i.test(str) ;
    }

    // Attempt to find unique match in a property list given a number/name identifier (if none found or not unique, then null is returned)
    // If no exact (case-insensitive) match, then do a fuzzy comparison searching for the identifier if it appears to be a premises 'number'
    static findFuzzyUniqueMatch(propertyList, id) {
        // First check for direct matches
        let n = 0;
        for (const property of propertyList) {
            if ('n' in property.pd && property.pd.n.toUpperCase() === id.toUpperCase())
                return n; // Exact (case-insensitive) match
            ++n;
        }
        n = -1;
        // Check for fuzzy matches if ID looks like a # identifier
        if (PostalAddress.isPremisesNumber(id))
            n = PostalAddress.findPropertyListIndex(id, propertyList);
        return n;
    }

    static findPropertyListIndex(house, propertyList) {
        let matched = -1;
        let n = 0;
        const regexp = `(^|[^A-Z0-9]+)${house}([^A-Z0-9]+|$)` ;
        const regexpC = new RegExp(regexp, "i") ;
        for (const property of propertyList) {
            if (regexpC.test(property.pd.n))
            {
                // console.warn('MATCH:'+ n + ' ' + property.pd.n + ':' + JSON.stringify(property));
                if (matched < 0)
                    matched = n;
                else
                    return -1; // Non-unique, has been matched already
            }
            n++;
        }
        return matched;
    }

    // Remove spaces in a postcode and convert to uppercase
    static normalisePostcode(postcode) {
        return postcode.replace(/\s+/g, '').toUpperCase() ;
    }

    // Check if postcodes match after normalisation
    static postcodesMatch(postcode1, postcode2) {
        return PostalAddress.normalisePostcode(postcode1) === PostalAddress.normalisePostcode(postcode2) ;
    }

    // Create a premises ID (display) string
    // Uses premisesListMatch data if available, otherwise determines from 'house' heuristically
    static makePremisesIdString(address) {
        let isPropertyNumber = false ;
        if (address.premisesListMatch !== undefined) {
            if (address.premisesListMatch.n !== undefined && PostalAddress.isPremisesNumber(address.premisesListMatch.n)) isPropertyNumber = true ;
        }
        else {
            // Premises # identifier (as per isPremisesNumber()) at end of string
            isPropertyNumber = /(^|[^a-z0-9])+([a-z]?[0-9]{1,3}[a-z]?)$/i.test(address.house) ;
        }

        let premiseID = address.house ;
        if (isPropertyNumber) premiseID += ' ' + address.addr1 ; // Combine if there's a # premises number
        return { displayName : premiseID, combinedPremisesAddr1Flag: isPropertyNumber } ;
    }
}