// Service: OrderingService
import OrderService from "@/services/infoService";

export default class OrderingService {
	constructor() {
		this.orderSvc = new OrderService;
	}

	getOrderConfirmationData(that, callback) {
		this.orderSvc.getOrderConfirmationData(that, callback);
	}
}