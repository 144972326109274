<template>
	<div class="">
		<section class="es-cards is-justify-content-center">
			<div v-for="item in items" :key="item.id" class="es-card m-1" @click.stop="selector(item, +1)">
        <section class="content">
          <div class="is-size-6">
            <span v-if="item.qty && settings.tonyTheme" class="sel">
              {{item.qty}}
            </span>
            {{item.name}}
            <span class="cost" v-if="settings.tonyTheme">{{ formattedAmountPence(item.cost) }}</span>
          </div>

        </section>

        <section>
					<figure v-if="item.image" class="image is-4by3">
            <img @error="imgPlaceholder" :src="image_base + item.image" alt="No image">
						<div v-if="item.qty && !settings.tonyTheme" class="es-card-control">
							<button size="is-medium" @click.stop="selector(item, -1)" class="button minus">
								<img :src="minus" alt="-" />
							</button>
							<button size="is-medium" @click.stop="selector(item, +1)" class="button plus">
								<img :src="plus" alt="+" />
							</button>
            </div>
					</figure>

				</section>

            <footer v-if="!settings.tonyTheme" class="es-card-footer control">
               <div v-if="item.diet && item.diet.length" class="attributes">
            <span v-for="(diet, index) in item.diet" :key="index" >
              <span v-if="diet === 'V' || diet === 'VV'" class='vg'>
                {{ diet }}
              </span>
              <span v-else>
                {{ diet }}
              </span>
            </span>
               </div>

               <p class="item-description" v-if="item.desc">
                  {{item.desc}}
               </p>

               <p class="cost">
                  <span>{{formattedAmountPence(item.cost)}}</span>
                  <span class="info" v-if="item.qty">{{item.qty}}</span>
               </p>

            </footer>
			</div>
		</section>
	</div>
</template>

<script>

// Mixins
import selectionMixin from "@/mixins/Selection";
import currencyMixin from "@/mixins/Currency"
import LoadCSS from "@/utils/loadCSS";
import minusSVG from '@/assets/image/minus.svg';
import plusSVG from '@/assets/image/plus.svg';

export default {
	name: 'SelectionGrid',
	mixins: [ selectionMixin, currencyMixin ],
	props: {
		selection: [],
		items: [],
    settings: {},
    image_base: null
	},

  data() {
		return {
      imageMissing:false,
      plus: plusSVG,
      minus: minusSVG,
    }
  },

	head() {
		return {
			meta: [
				{ content: 'asc' }
			]
		}
	},

	metaInfo: {
		meta: [
			{ charset: 'utf-8' },
			{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
		]
	},
}
</script>