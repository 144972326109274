import BaseService from "./baseService";
import AjaxUtils from "../utils/ajaxUtils";
import Settings from "@/settings";

export default class InitialDataService extends BaseService {

  constructor(key) {
	// super({'X-Requested-With':'XMLHttpRequest'});
    super();
    this.settings = new Settings();
    this.endpoint = '/init';
    this.installEndpoint = '/init/install';
    this.key = key;
  }

  getInitialData(that, vendorId, key, auth, callback) {
	const filterFunc = (xhrResponseText, xhrStatus, xhrStatusText) => AjaxUtils.ajaxResponseToObj(that, xhrResponseText, xhrStatus, xhrStatusText, callback);
    this.server.request('GET',this.endpoint + '/' + this.settings.vendorId + '/' + key, filterFunc);
    this.server.withCredentials();
    // Auth sent!!
    this.server.header('Authorization', auth);
    this.server.send();
  }

  installApp(that, setupId, callback) {
    const filterFunc = (xhrResponseText, xhrStatus, xhrStatusText) => AjaxUtils.ajaxResponseToObj(that, xhrResponseText, xhrStatus, xhrStatusText, callback);
    this.server.request('GET',this.installEndpoint + '/' + setupId, filterFunc);
    this.server.withCredentials();
    this.server.send();
  }
}