<template>
   <section>
      <div class="container is-fluid">
         <header class="pr-flex">
            <h1 class="subtitle is-3">
               Information
            </h1>
            <span v-if="this.profileName" class="user-info ml-2">
            {{ accountInfo }}
          </span>
         </header>

         <div v-if="!this.profileName">
            <p class="mb-1">Have an account?
               <router-link @click.native="loginClicked" to="/login">
                  <strong>Sign in</strong>
               </router-link>
            </p>
         </div>

         <CustomerInfoForm
            :addressList=user.addressList
            :user=user
            formId="custForm"
            :fieldsUsed="fieldsUsed"
            :fieldsRequired=fieldsRequired
            :errors=errors
            :addAddress=addAddress
            :userProfile="!!profileName"
            :infoComplete="infoComplete"
            @update:user="userFormUpdate"
            @update:addressList="addressListUpdate"
            @update:address="addressFormUpdate"
            @update:address-selected="addressSelected"
            @anotherAddress="anotherAddress"
            @clear-error="clearError"
            @reset="reset"
         ></CustomerInfoForm>

<!--         <button class="button is-primary is-fullwidth submit" @click="completeInfo">-->
<!--            Submit form-->
<!--         </button>-->

         <nav-footer
            :disabled="isFormComplete"
            nextEvent="complete-info"
            :navRouting="navRouting"
            @complete-info="completeInfo"
            >

         </nav-footer>
      </div>

      <!-- Email exists modal -->
      <slot-modal
         :show="this.showModal"
      >
         <template v-slot:header>
            <h2 class="subtitle centre is-3">Server error</h2>
         </template>
         <template v-slot:body>
            <p class="pr-section centre">
               Sorry, there has been a problem with the system.
               We will try to fix the issue as soon as possible.
            </p>

         </template>
         <template v-slot:footer>

            <button class="button is-fullwidth is-outlined is-primary is-modal centre"
                    @click="closeModal()"
            >
               Continue
            </button>
         </template>
      </slot-modal>
   </section>
</template>

<script>

import CustomerInfoForm from "@/views/CustomerInfoForm";
import SlotModal from "@/components/SlotModal";
import FormUtils from "@/utils/formUtils";
import OrderService from "@/services/infoService";
import NavFooter from "@/components/NavFooter";

export default {
   name: "OrderInfoManager",
   components: {NavFooter, CustomerInfoForm, SlotModal },
   props: {
      user: { type:Object, default: null },
      addressList: { type:Array, default: null },
      settings: { type:Object, default: null },
      navRouting: { type:Object },
      profileName: { type:String, default: null },
      infoComplete: { type:Boolean, default: false },
      formComplete: { type:Boolean, default: false },
      total_cost:   { type: Number, default: 0 }
   },
   data: function() {
      // full list of fields appearing on form
      const fieldsUsed =  {
         custname: true,
         email: true,
         phone: true,
         notes: true,
         //  save: null,
         address: true
      };

      const fieldsRequired = {
         custname:true,
         email:true,
         phone:true,
         address:true
      };

      const fieldsEditableIfProfile = {
         phone: null,
         notes: null,
         adi: 0
      };

      const requireAddAddress = this.addAnAddress();

      // @TODO use locale for internationalisation
      const ukCorePhoneLength = 10;

      return {
         fieldsUsed,
         fieldsRequired,
         showModal: false,
         prevRoute: '/menu/list',

         // required for CustomerInfoForm component
         errors: {},
         fieldsEditableIfProfile,
         ukCorePhoneLength,
         addAddress: requireAddAddress,
         emailRegex: new RegExp("^\\s*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+\\s*$"),
      }
   },

   created() {
      // if user object has email, user is logged in
      if (this.profileName || this.user.loggedIn)
      {
         // these fields unused if logged in
         delete this.fieldsUsed.email;
         delete this.fieldsUsed.forename;
         delete this.fieldsUsed.surname;
      }
      // remember these fields if a profile logged-in user,
      // to see if any have changed when leaving the page
      this.fieldsEditableIfProfile.phone = this.user.phone;
      this.fieldsEditableIfProfile.notes = this.user.notes;
      this.fieldsEditableIfProfile.adi = this.user.adi;
      // console.log('fieldsEditable1:' + JSON.stringify(this.fieldsEditableIfProfile));

      // If not logged in, there is no address known, so show
      // address form rather than have user click "add address" button.
      // if (!this.profileName || this.isEmpty(this.fieldsUsed.address))
         this.addAddress = true;

      console.info('USER:' + JSON.stringify(this.user));
      // check if user is logged in, if page is reloaded, user info is
      // lost because user data is never stored in cache.
      // So reload user data, IF user is logged in.

      if (this.settings.grid) {
         this.prevRoute = '/menu/grid';
      }

      if (this.fieldsUsed.address) {
         this.errors['postcode'] = true;
         this.errors['house'] = true;
      }
   },
   computed: {
      accountInfo: function() {
         let info = this.user.email + ' - ';
         if (this.user.forename)
            info += ' ' + this.user.forename;
         if (this.user.surname)
            info += ' ' + this.user.surname;
         else if (this.user.custname)
            info += ' ' + this.user.custname;
         return info;
      },
      isFormComplete() {
         return this.formComplete && !FormUtils.isEmpty(this.errors);
      }
   },

   methods: {
      openModal() {
         this.showModal = true;
      },

      closeModal() {
         this.showModal = false;
      },

      addAnAddress() {
         return !!this.profileName;
      },

      // addressList radio has been clicked
      addressListUpdate(addr, idx) {
         // console.log('ALS(2):' + idx + JSON.stringify(addr));
         this.$set(this.errors, 'address', '');
         this.$emit('update:address-list', addr, idx);
      },

      userFormUpdate(field, val) {
         switch (field) {
            case 'forename':
               if ('forename' in this.errors)
                  this.errors.forename = null;
               break;
            case 'surname':
               if ('surname' in this.errors)
                  this.errors.surname = null;
               break;
            case 'custname':
               if ('custname' in this.errors)
                  this.errors.name = null;
               break;
            case 'email':
               if ('email' in this.errors)
                  this.errors.email = null;
               break;
            case 'phone':
               if ('phone' in this.errors)
                  this.errors.phone = null;
               break;
            case 'password':
               if ('password' in this.errors)
                  this.errors.password = null;
               break;
            case 'notes':
               if ('notes' in this.errors)
                  this.errors.notes = null;
               break;
            case 'save':
               break;
            default:
               console.warn('UFU(1):case: ' + field + ' not found');
               break;
         }
         this.$emit('update:user-form', field, val);
      },

      addressFormUpdate(field, val) {
         switch (field) {
            case 'house':
               if ('house' in this.errors)
                  this.errors['house'] = null;
               if ('address' in this.errors)
                  this.errors.address = null;
               break;
            case 'addr1':
               if ('addr1' in this.errors)
                  this.errors['addr1'] = null;
               break;
            case 'addr2':
               if ('addr2' in this.errors)
                  this.errors['addr2'] = null;
               break;
            case 'town':
               if ('town' in this.errors)
                  this.errors['town'] = null;
               break;
            case 'postcode':
               if ('postcode' in this.errors)
                  this.errors.postcode = null;
               if ('address' in this.errors)
                  this.errors.address = null;
               break;
            case 'premises':
               console.info('AFU:address:premises:' + val);
               break;
            default:
               console.warn('AFU:switch case: ' + field + ' not found');
               break;
         }
         this.$emit('update:address-form', field, val);
      },

      // == address handling methods ==
      addressSelected(addr) {
         this.errors.address = null;
         this.$emit('update:address-selected', addr);
      },

      anotherAddress() {
         this.addAddress = true;
         this.$emit('another-address');
      },

      clearError(field) {
         this.errors[field] = false;
      },

      // Address handling methods
      reset(keepHouseAndPostcode) {
         this.addAddress = true;
         this.clearError('postcode');
         this.$emit('reset', keepHouseAndPostcode);
      },

      completeInfo() {
         let cust = {};
         this.errors = {};
         for (let prop in this.fieldsRequired) {
            if (this.fieldsRequired[prop] &&
               (prop in this.user && !this.user[prop]) ||
               (!(prop in this.user)))
            {
               this.errors[prop] = 'missing';
            }
            else if (!prop in this.user)
               console.error('Prop:', prop, ' is required but not in field list!');
         }

         if (!this.profileName && this.fieldsUsed['email']) {
            if (!this.emailRegex.test(this.user['email'])) { // Email check
               this.errors['email'] = 'invalid'
            }
         }

         if (this.fieldsUsed['phone']) {
            let phoneVal = this.user['phone'];
            // @TODO replace crude assumption that phone number is from UK
            if (phoneVal) {
               const phoneNational = phoneVal.replace('+44', '');
               let phone = phoneNational.replace(/^0/g, '');
               phone = phone.replace(/ /g, '');
               if (phone.length !== 10) {
                  this.errors['phone'] = 'invalid length: should be ' + phone.length;
               }
            }
         }

         if (!this.isEmpty(this.errors)) {
            let str = '';
            for (let prop in this.errors) {
               if (Object.prototype.hasOwnProperty.call(this.user, prop)) {
                  str += prop + ': ' + this.errors[prop] + '\r\n';
               }
            }
         }

         // ONLY check for address if this is a guest user.
         // IF however logged in, then no need to check address as
         // there is always address radio button default selected now.
         if (this.user.loggedIn && !this.user.address || this.isEmpty(this.user.address) ||
            !(this.user.address.postcode || this.user.address.town))
            this.$set(this.errors, 'address', 'No address selected');

         if (this.isEmpty(this.errors))
         {
            let noChanges = false;

            // populate cust record.
            // for logged-in user copy the defaults, plus any form fields added.
            cust.loggedIn = this.user.loggedIn;
            if (this.user.loggedIn) {
               console.log('fieldsEditable2:' + JSON.stringify(this.fieldsEditableIfProfile));
               // case 1 - no fields changed
               if (this.phoneComparision(this.fieldsEditableIfProfile.phone, this.user.phone) &&
                  this.fieldsEditableIfProfile.notes === this.user.notes &&
                  this.fieldsEditableIfProfile.adi === this.user.address.adi &&
                  !this.user.new_address)
               {
                  console.info('OIM case ONE: move to summary');
                  noChanges = true;
                  this.$emit('info-complete', this.user);
               }
               else {
                  // case 2 - fields have been changed
                  console.info('OIM case TWO: notify server of user changes');
                  cust = {};
                  cust.id = this.user.id;
                  cust.phone = this.user.phone;
                  cust.guest = this.user.guest;
                  cust.new_address = this.user.new_address;
                  cust = Object.assign(cust, this.user.address);
                  cust.notes = this.user.notes;
                  delete cust.addressList;
               }
            }
            else {
               // guest user - no login
               // just copy the whole form contents into cust record
               cust = Object.assign({}, this.user);
            }

            if (noChanges) {
               // no further processing required, just go to summary page
               this.$router.push('/summary');
            }
            else {
               if (!('save' in cust) || typeof cust.save === 'undefined') {
                  cust.save = 0;
               }

               // required for delivery cost / charges calc
               cust.order_total = this.total_cost;

               console.info('CUSTz:' + JSON.stringify(cust));
               // this.report(cust);
               this.$emit('info-submit', cust);
               if (this.user.loggedIn)
                  this.profileUserUpdate(cust);
               else
                  this.guestUserUpdate(cust);
            }
            return true;
         }
         else
            return false;
      },

      // compare numbers optionally with or without
      // international dialing code prefix.
      phoneComparision(p1, p2) {
         if (p1 && p2) {
            // @TODO remove this two line hack for UK phones
            p1 = p1.replace(/\+44/g,'');
            p2 = p2.replace(/\+44/g,'');

            const pcore1 = p1.replace(/[^0-9]/g, '');
            const pcore2 = p2.replace(/[^0-9]/g, '');
            // console.log('p1:' + pcore1 + ' p2:' + pcore2);
            if (pcore1 === pcore2 && (pcore1.length === this.ukCorePhoneLength))
               return true;
         }
         return false;
      },

      isEmpty(obj) {
         if (!obj) return true;
         return Object.keys(obj).length === 0;
      },

      profileUserUpdate: function(customer)
      {
         const func = function() {
            const auth = localStorage.getItem('auth');
            const service = new OrderService();
            const data = FormUtils.objToFormFields(customer);
            service.profileUpdate(this, data, auth, function(response) {
               if (response.ok) {
                  this.$emit('info-complete', response.data);
                  this.$router.push('/summary');
               }
               else {
                     if (response.error_code === 'XHR_SEND_ERR' || response.isServerError)
                        this.$emit('displayNetworkError', response, this, func);
                     else {
                        if (response.error_code === 'AUTH_FAILED') {
                           this.showModal = true;
                        }
                     }
                  }
            });
         };

         func.call(this);
      },

      guestUserFormFields(cust) {
         const fd = new FormData();
         fd.append('email', cust.email);
         fd.append('phone', cust.phone);
         fd.append('custname', cust.custname);
         fd.append('notes', cust.notes);
         fd.append('house', cust.address.house);
         fd.append('addr1', cust.address.addr1);
         fd.append('addr2', cust.address.addr2);
         fd.append('postcode', cust.address.postcode);
         fd.append('town', cust.address.town);
         fd.append('pd', JSON.stringify(cust.address.pd));
         return fd;
      },

      guestUserUpdate: function(customer) {
         const func = function() {
            let service = new OrderService();
            const data = this.guestUserFormFields(customer);
            service.guestUpdate(this, data, function(response) {
               console.info('RESP:' + JSON.stringify(response));
               if (response.ok) {
                  this.$emit('info-complete', response.data);
                  this.$router.push('/summary') ;
               }
               else { // Error
                  if (response.error_code === 'XHR_SEND_ERR' || response.isServerError) {
                     this.$emit('modal-network-error', response, this, func) ;
                  }
                  else {
                     if (response.error_code === 'SESSION_EXPIRED')
                        this.$bus.$emit('session-expired');
                     else if (response.error_code === 'MISSING_PARAMETERS')
                        this.$emit('modal-invalid-data');
                     else if (response.error_code === 'NOT_FOUND')
                        this.serverError = 'Error: Order has either timed out or has already been completed' ;
                     else {
                        this.mainStatus = { category: 'error', msg: response.error } ;
                        if (response.error_code === 'DUPLICATE_EMAIL') this.emailError = {category: 'invalid', msg: 'account already exists'} ;
                     }
                  }
               }
            });
         };
         func.call(this) ;
      },

      loginClicked() {
         this.$emit('login-clicked')
      },

      report(o) {
         alert(
            '+Submit:' + '\r\n\r\n' +
            'Name:' + o.forename + ' ' + o.surname + '\r\n' +
            'Email:' + o.email + '\r\n' +
            'Phone:' + o.phone + '\r\n' +
            'Save:' + o.save + '\r\n' +
            'Org:' + o.address.org + '\r\n' +
            'Prem:' + o.address.prem + '\r\n' +
            'House:' + o.address.house + '\r\n' +
            'House:' + o.address.street + '\r\n' +
            'Addr1:' + o.address.addr1 + '\r\n' +
            'Addr2:' + o.address.addr2 + '\r\n' +
            'Town:' + o.address.town + '\r\n' +
            'Postcode:' + o.address.postcode + '\r\n' +
            'Order cost:' + o.order_total
         );
      },
   }
}

</script>