<template>
  <section>
    <div class="container is-fluid">
       <div>
          <h1 class="subtitle profile ml-2 is-3">Edit Profile</h1>
          <h2 class="subtitle is-6 my-2">{{ user.custname }}
             <span class="trailing-email">{{ user.email }}</span>
          </h2>
          <button class="button is-control is-small is-outlined"
                  @click="$emit('user-logout')"
          >
             Logout
          </button>
       </div>

      <CustomerInfoForm
          :addressList=addressList
          :user=this.userInfo
          :fieldsUsed=this.userFields
          :errors=errors
          :addAddress=false
          :userProfile=true
          @remove="remove"
          @setDefault="setDefault"
          @submit="submit"
      ></CustomerInfoForm>
<!--      <button class="button is-primary is-2 my-6 is-fullwidth" @click="submit">Submit</button>-->
    </div>
  </section>

</template>
<script>

import CustomerInfoForm from "@/views/CustomerInfoForm";

export default {
  name: "UserProfileManager",
  components: { CustomerInfoForm },
  props: {
    user: { type:Object, default: null },
    addressList: { type:Array, default: null }
  },
  data: function() {
    // contains fields displayed on form
    const userFields =  {
      forename: null,
      surname: null,
      email: null,
      phone: null,
      address: {}
    };

    return {
      userFields:userFields,
      userInfo: userFields,
      errors: {},
      emailRegex: new RegExp("^\\s*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+\\s*$")
    }
  },

  computed: {
    fullName() {
      if (this.user) {
        return this.user.forename + ' ' + this.user.surname;
      }
      return "";
    }
  },

  created() {
    this.userInfo = Object.assign(this.userInfo, this.user);

    // @TODO this data structure to define required fields smells
    // @TODO improve handling of required properties
    if (!('required' in this.userInfo))
      this.userInfo.required = {'surname':true, 'email':true, 'phone':true}

    console.info('UPM: Created:' + JSON.stringify(this.user));
  },
  beforeMount() {
    console.info('UPM: BeforeMount:' + JSON.stringify(this.user));
  },
  methods: {
    // == address handling methods ==
    remove(idx) {
      this.$emit('remove', idx);
    },
    setDefault(idx) {
      this.$emit('setDefault', idx);
    },

    submit() {
      this.errors = {};
      for (let prop in this.userInfo) {
        if (Object.prototype.hasOwnProperty.call(this.userInfo, prop)) {
          if (prop !== 'required' && prop !== 'errors') {
            console.info(this.userInfo.required[prop] + ':a' + prop);
            if (this.userInfo.required[prop] && !this.userInfo[prop]) {
              this.errors[prop] = 'missing';
            }
          }
        }
      }

      if (this.userInfo['email']) {
        if (!this.emailRegex.test(this.userInfo['email'])) { // Email check
          this.errors['email'] = 'invalid'
        }
      }
      this.$emit('submit', this.userInfo, this.addressList);
    },

    report() {
      alert(
          'Submit:' + '\r\n\r\n' +
          'Name:' + this.userInfo.forename + ' ' + this.userInfo.surname + '\r\n' +
          'Email:' + this.userInfo.email + '\r\n' +
          'Phone:' + this.userInfo.phone + '\r\n' +
          'Save:' + this.userInfo.save + '\r\n' +
          'Org:' + this.userInfo.address.org + '\r\n' +
          'Prem:' + this.userInfo.address.prem + '\r\n' +
          'House:' + this.userInfo.address.house + '\r\n' +
          'Addr1:' + this.userInfo.address.addr1 + '\r\n' +
          'Addr2:' + this.userInfo.address.addr2 + '\r\n' +
          'Town:' + this.userInfo.address.town + '\r\n' +
          'Postcode:' + this.userInfo.address.postcode
      );
    }
  }

}
</script>