<template>
<aside class="sidebar">
   <h2>Categories</h2>

   <div v-if="this.categories && this.categories.length">
      <button @click="clickButton(0)"
              :value="0"
              :class="activeCategory(0)"
              class="button is-secondary"
      >
         All categories
      </button>

      <article class="category-buttons" v-for="cat in this.categories"
               :key="cat.id">
         <button @click="clickButton(cat.id)"
                 :value="cat.id"
                 :class="activeCategory(cat.id)"
                 class="button is-secondary"
         >
            {{ cat.name }}
         </button>
   </article>
   </div>

</aside>
</template>

<script>
export default {
   name: "Sidebar",
   props: {
      categories: Array,
      category: Number
   },
   methods: {
      activeCategory(id) {
         if (id == this.category) return 'active';
         return '';
      },
      clickButton(id) {
         this.$emit('category-select', id);
      }
   }
}
</script>

<style scoped>

</style>