<template>
   <section class="page-install container is-fluid">
      <h1 class="subtitle is-3">Install</h1>
      <div class="control">
         <input class="input is-info" :class="errorClass" type="text"
                placeholder="Install ID"
                name="install"
                id="install_key"
                @input="installKeyEntry($event.target.value)"
         />
      </div>
      <button class="button is-primary is-fullwidth" @click.stop="formSubmission()">
         Install
      </button>
   </section>
</template>

<script>
export default {
   name: "AppInstall",
   props: {
     error: Boolean
   },
   data: function() {
      return {
         key: { type: Number, default: 0 }
      }
   },
   methods: {
      installKeyEntry(val) {
         // do not use parseInt - it cannot cope with integers >11 digits
         this.key = val;
      },
      formSubmission() {
         if (this.key)
            this.$emit('install-submit', this.key);
         else
            alert('Please enter your install ID');
      }
   },
   computed: {
      errorClass() {
         return this.error ? 'error' : '';
      }
   }
}
</script>